@import url('../../../node_modules/bootstrap-icons/font/bootstrap-icons.css');

.canvas-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.btn{
  position: absolute;
  top: 15px;
  left: 45px;
  height: 45px;
  width: 45px;
  text-align: center;
  background: #1b1b1b;
  border-radius: 3px;
  cursor: pointer;
  transition: left 0.4s ease;
}
html{
  scroll-behavior:smooth;
}
input[type="checkbox"]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: white;
  width: 16px;
  height: 16px;
  border: 2px solid #406fab;
}
.btn.click{
  left: 260px;
}
.btn span{
  color: white;
  font-size: 28px;
  line-height: 45px;
}
.btn.click span:before{
  content: '\f00d';
}
body{
  background-color: #f1f3f7;
  overflow-x: hidden;

}
nav ul{
  background: #1b1b1b;
  height: 100%;
  width: 100%;
  list-style: none;
}
nav ul li{
  line-height: 60px;
  border-top: 1px solid rgba(255,255,255,0.1);
}
nav ul li:last-child{
  border-bottom: 1px solid rgba(255,255,255,0.05);
}
nav ul li a{
  position: relative;
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding-left: 40px;
  font-weight: 500;
  display: block;
  width: 100%;
  border-left: 3px solid transparent;
}
button {
  border: none;
  background-color: white;
 
}

button:focus {
  border: none;
  outline: none; 
}

button:focus{
  outline:none !important;
}

nav ul li.active a{
  color: cyan;
  background: #1e1e1e;
  border-left-color: cyan;
}
nav ul li a:hover{
  background: #1e1e1e;
}
nav ul ul{
  position: static;
  display: none;
}
nav ul .feat-show.show{
  display: block;
}
nav ul .serv-show.show1{
  display: block;
}
nav ul ul li{
  line-height: 42px;
  border-top: none;
}
nav ul ul li a{
  font-size: 17px;
  color: #e6e6e6;
  padding-left: 80px;
}
nav ul li.active ul li a{
  color: #e6e6e6;
  background: #1b1b1b;
  border-left-color: transparent;
}
nav ul ul li a:hover{
  background: #1e1e1e!important;
}
nav ul li a span{
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 22px;
  transition: transform 0.4s;
}
nav ul li a span.rotate{
  transform: translateY(-50%) rotate(-180deg);
}
.content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #202020;
  z-index: -1;
  text-align: center;
}
.content .header{
  font-size: 45px;
  font-weight: 600;
}
.content p{
  font-size: 30px;
  font-weight: 500;
}

.container1 p{
  text-align: center;
  font-size: 17px;
}
.main{
  display: flex;
  height: 100vh;
  background-color: #f3f6f9;
}
.sidebar{
  width: 260px;
  flex-shrink: 0;
  background-color: #fff;
  height: 100%;
  overflow: auto;
  color:black;
  box-shadow: 7px 0 15px 0 rgb(69 90 100 / 9%);
}

.sidebar-item{
  padding: .6em 0.2em;
  text-decoration: none;
  display: block;
  transition: background-color .15s;
}
.sidebar-item:hover{
  background-color: #f1f3f7;
  cursor: pointer;
}
.sidebar-item.open .sidebar-title{
  background-color: #f1f3f7;
  padding: 7px;
}
.sidebar-item.open:hover{
  background-color: #fff;
  color: #000;
}

.sidebar-title{
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  align-items: center;
}
.sidebar-title span{
  display: inline-block;

}
.side{
  margin-top:30px;
}
i{
  margin-right: 15px;
}

.sidebar-title .toggle-btn{
  cursor: pointer;
  transition: transform .3s;
}
.sidebar-item.open .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}
.sidebar-content{
  padding-top: 1em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open .sidebar-content{
  height: auto;
}
.sidebar-item1.open .sidebar-content{
  height: auto;
}
.sidebar-item1.open .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}
.sidebar-item2.open .sidebar-content{
  height: auto;
}
.sidebar-item2.open .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}
.bi {
  color: #04a9f5;
}
td{
  height: 30px;
}
.sidebar::-webkit-scrollbar{
  width: 5px;
}
.sidebar::-webkit-scrollbar-thumb{
  width: 5px;
 background-color: #c6c9d0;
}
input{
  margin-right: 10px;
}
.container-fluid{
  width: 90%!important;
}
label{
  margin-bottom: 1rem!important;
  align-items: center;
  width: 240px;
  height: auto!important;
}
.crm-section {
  max-width: 75%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1% 0;
  margin-left: 50px;
  flex-wrap: wrap;
}
.cc:first-child{
  margin-top: 20px;
}
.cc:nth-child(1) .sidebar-title span:before{
  font-family: "bootstrap-icons"; 
	content: "\F392"!important;
  margin-right: 15px;
  color: #406fab;
  font-weight: 500;
}
.cc:nth-child(1) .sidebar-content input[type=text]{
display: none!important;
}
.cc:nth-child(4) .sidebar-content input[type=text]{
  display: none!important;
  }
  

.cc:nth-child(2) .sidebar-title span:before{
  font-family: "bootstrap-icons"; 
	content: "\F3E8"!important;
  margin-right: 15px;
  color: #406fab;
  font-weight: 500;
}
.cc:nth-child(3) .sidebar-title span:before{
  font-family: "bootstrap-icons"; 
	font-weight: 400;
	content: "\F3CC"!important;
  margin-right: 15px;
  color: #406fab;
  font-weight: 500;
}
.cc:nth-child(5) .sidebar-title span:before{
  font-family: "bootstrap-icons"; 
	font-weight: 400;
	content: "\F28B"!important;
  margin-right: 15px;
  color: #406fab;
  font-weight: 500;
}
.cc:nth-child(6) .sidebar-title span:before{
  font-family: "bootstrap-icons"; 
	font-weight: 400;
	content: "\F1C8"!important;
  margin-right: 15px;
  color: #406fab;
  font-weight: 500;
}
.cc:nth-child(4) .sidebar-title span:before{
  font-family: "bootstrap-icons"; 
	font-weight: 400;
	content: "\F3EE"!important;
  margin-right: 15px;
  color: #406fab;
  font-weight: 500;
}
.cal{
  color: #406fab;
  font-weight: 500;
}
.cit{
  color: #406fab;
  font-weight: 500;
}
.crm-section .crm-card {
  height:150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display:flex;
  align-items:center;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-evenly;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  background: white;
  border-right:5px solid #f5f7fa;
}

.crm-section .crm-card .crm-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 150px;
}

.crm-section .crm-card .crm-text h1 {
  font-size: 20px;
  font-weight: 400;
  color: #4a4f5a;
}
.timer{
  font-size: 35px;
  font-weight: 400!important;
  color: #406fab;
}
.c{
  margin-top: 30px;
}
.crm-section .crm-card .crm-icon i {
  font-size: 80px!important;
  color: #366196!important;
}

.crm-text.card-header {
  background-color: white!important;
  border-bottom:0!important;
  text-align:center;
}
.fa-building{
     font-size:80px!important;
  color: white!important;
  opacity:0.5!important;
}

.crm-section {
  background: transparent;
}
.crm-progress {
  margin-left: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 80%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -5%;
  padding: 0;
}

.crm-progress .crm-card {
  background-color: #fff;
  width: 400px;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 30px 30px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0 1%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; 
}

.crm-progress .crm-card .crm-up {
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
 -webkit-box-pack: center;
 -ms-flex-pack: center;
 justify-content: center;
 -webkit-box-align: center;
 -ms-flex-align: center;
  align-items: center;
}

.crm-progress .crm-card .crm-up h1 {
  font-size: 25px;
  font-weight: 100;
}

.crm-progress .crm-card .crm-middle p {
  font-size: 19px;
  color: gray;
  font-weight: 500;
}

.publi{
  list-style: none;
}

.bi-file-earmark-spreadsheet{
  color: rgb(76, 177, 76);
  font-size: 25px;
  margin-right: 0!important;
}

.sp{
  color:#3f4d67;
  font-weight: 400;
}
input[type=date]{
  min-width: 150px!important;
  margin-right: 0!important;
  height: 35px;
  border-radius: 10px!important;
  border: 2px solid #aaacb4;
}
input[type="date" i ]{
color: grey!important;
}
.table-bordered{
  background: white!important;
}
.pading{
  padding-bottom: 50px;
}
.head{
  height: 50px;
}
.text-left{
  padding: 2px;
  font-size: 14.5px;
}
.head .text-left{
  font-size: 15px;
  font-weight: 700;
}

@media (min-width: 600px) {
  table,
  thead,
  tbody,
  tr,
  td,
  th {
    display: revert;
    position: revert;
  }
  .sp{
    font-size: 18px;
  }
  td:before {
    content: "";
  }
}
 tbody .nodata{
  font-size: 18px;
}
a{
  color: #000;
}
.resu{
  font-size: 25px!important;
  color: #000;
  margin-bottom: 20px;
  text-align: left!important;
  font-weight: 600;
}
.table-bordered{
  width: 1200px!important;
}

.ch{
  width: 50%;
  min-width: 400px;
  border: 1px solid #e4e6ef;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  background-color: white;
  height: 40px;
  margin-right: 8px!important;
}
.ch::placeholder{
  font-size: 16px!important;
  font-family: "Roboto" , "bootstrap-icons";
}

.kerk{
  position: absolute;
  color:#697382;
  margin-left: 10px;
}
.exc{
  margin-top: 10px;
  font-size: 18px;
  display: inline-block;
  text-align: center;
  user-select: none;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: white;
}
.ser{
  display:flex;
  align-items:center;
  float:right;
}
.city{
  min-width:150px!important;
  margin-right:0!important;
  height:35px;
  border-color:#97979b!important;
}
.sifil{
  min-width: 180px;
  height: 30px;
  border: 1px solid #e4e6ef;
  border-radius: 5px;
  margin-bottom: 20px;
}
.fa-search{
  border: none;
  background: transparent;
  color: #345c8e;
}

.publi{
  padding-left:0!important;
}
.nam{
  font-weight:500;
  font-size:15px;
}
.paraqitja{
  display: flex;
  background: white;
  padding: 10px;
  border: 2px dashed #cbc9c9;
}
.filter{
  margin-right: 20px;
  background: #f3f6f9;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
@media (max-width: 700px) {
  .pu{
    font-size: 12px!important;
  }
  .sifil{
    min-width:115px;
    width:120px;
    height:25px;
  }
  .paraqitja{
    display: block;
  }
  .filter{
    margin-right: 0;
    font-size: 12px;
  }
  .sid td{
    font-size: 12px;
  }
.dateof{
  display: none;
}
.ser{
  float: left!important;
}
  .charts{
    display: block!important;
  }
  .tit{
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .cc:nth-child(1) .sidebar-title span:before{
    margin-right: 5px;
  }
  .cc:nth-child(2) .sidebar-title span:before{
    margin-right: 5px;
  }
  .cc:nth-child(3) .sidebar-title span:before{
    margin-right: 2px;
  }
  .cc:nth-child(5) .sidebar-title span:before{
    margin-right: 5px;
  }
  .cc:nth-child(6) .sidebar-title span:before{
    margin-right: 5px;
  }
  .cc:nth-child(4) .sidebar-title span:before{
    margin-right: 5px;
  }

.spi{
  margin-bottom: 50px;
}

input[type=date]{
  min-width: 80px!important;
  margin-right: 0!important;
  height: 25px;
  border-radius: 10px!important;
  margin-top: 2px;
}
.sidebar{
  width: 140px!important;
}

.sidebar-item{
  padding: .2em 0.2em!important;
}
.sidebar-title{
  font-size: 1.1em!important;
}
.publi{
  padding-left: 0!important;
}
.crm-section .crm-card {
  width: 220px!important;
  height: 100px!important;
}

.crm-section .crm-card .crm-text h1{
  font-size: 15px!important;
}
label{
  width: 130px;
}
input[type="checkbox"]{
  appearance: none;
  outline: 0;
  background: white;
  width: 13px;
  height: 13px;
  border: 2px solid #406fab;
}
.sid{
  border-bottom: 0px solid!important;
}
.sid td {
  height: auto!important;
}
input{
  margin-right: 5px;
}
.timer{
  font-size: 30px!important;
}
.crm-card{
  margin-bottom: 30px!important;
}
.crm-section .crm-card .crm-text{
  height: auto!important;
}
.crm-section .crm-card .crm-icon i{
  font-size: 40px!important;
}
.resu{
font-size: 18px!important;
}
.table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th{
  border: 1px solid #ddd!important;
}
.ch{
  width: 30%!important;
  min-width: 180px!important;
  font-size: 10px!important;
  margin-right: 5px!important;
  height: 30px;
  margin-top: 2px;
}
.ch::placeholder{
  font-size: 10px!important;
}
input[type=checkbox]:checked:before{
  font-size: 16px!important;
}
.exc{
  height: 30px!important;
  font-size: 12px!important;
  width: 50px!important;
  min-width: auto!important;
  padding: 0!important;
}
.bi-file-earmark-spreadsheet{
  font-size: 12px!important;
}

.p-4{
  padding: 0.5em!important;
}
th, td {
  border: none!important;
}
.text-left{
  font-size: 12px!important;
}
.nodata{
  font-size: 14px!important;
}
.table{
  width: 100%!important;
}
.pagination{
  font-size: 12px;
}
.dt{
display: none!important;
}
thead{
  left: auto!important;
  position: relative!important;
}
.p-3{
  width: 240px!important;
}
.p-2{
  width: 100px!important;
}
.font-semibold{
  font-size: 12px!important;
}
.left{
  font-size: 10px!important;
}
}
.pu{
  color: #b5b5c3;
  font-size: 16px;
}
.charts{
  display: flex;
  margin-top: 50px;
}

ul.pagination li a {
  text-decoration: none;
  color: #406fab;
}
.page-item.active .page-link {
  text-decoration:none;
  color:white;
  background: #406fab!important;
  border-color:transparent!important;
}
ul.pagination li a:focus {
  text-decoration:none;
  color: #406fab;
  background-color:transparent;
}
.dateof{
  width: 200px;
}
input[type="checkbox"]::after {
  background: color #406fab !important;
}

input[type=checkbox]:checked:before {
  content: "\F633";
  font-family: "bootstrap-icons";
  font-size: 18px;
  display: grid;
  color: #406fab;
  align-items: center;
  margin-top: -7px;
  justify-content: center;
}

@media (max-width: 500px) {
  .crm-section{
    display: block!important;
    margin-top: 30px;
    margin-left: 5px!important;
  }
    }

.dashboard-container {
    display: flex;
    gap: 2rem;
    padding: 1rem;
}

.filters-section {
    width: 300px;
    flex-shrink: 0;
}

/* Remove old loading skeleton styles */
.table-loading-skeleton,
.table-loading-row,
.table-loading-cell {
  display: none;
}

/* Loading Animation Styles */
.loading-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
}

.loading-container {
  background: rgba(255, 255, 255, 0.95);
  padding: 1.5rem 2.5rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: containerFade 0.3s ease-in-out;
  backdrop-filter: blur(8px);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(52, 152, 219, 0.2);
  border-top: 3px solid #3498db;
  border-radius: 50%;
  margin: 0 auto 0.75rem;
  animation: spin 0.8s linear infinite;
}

.loading-text {
  color: #2c3e50;
  font-size: 0.95rem;
  font-weight: 500;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

@keyframes containerFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Table Loading Animation */
.table-loading {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

/* Additional utility classes */
.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}

.transition-opacity {
  transition: opacity 300ms ease-in-out;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

/* Keep original loading styles */
.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.hih {
  color: #666;
  font-size: 14px;
}

/* Ensure search and excel button are above loading overlay */
.ser {
  position: relative;
  z-index: 20;
}

.ch {
  position: relative;
  z-index: 20;
}

.exc {
  position: relative;
  z-index: 20;
}



.advancedSearchWrapper {
  max-width: 1100px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}


.advancedSearchContainer {
  max-width: 1100px;
  margin: 3rem auto;
  padding: 30px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
}


.headerSection {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 20px;
}

.heading {
  text-align: left;
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
}

.note {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
}

.searchForm {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.fullWidth {
  grid-column: span 3;
}

.label {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: #333;
}

.input {
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 20px;
}

/* Updated Select Style */

.select option:checked {
  background-color: #007bff;
  color: #fff;
}
/* 
.select {
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  font-size: 14px;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCwwIEw3LDggTDE0LDAgWiI+PC9wYXRoPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  padding-right: 30px;
} */

.select {
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  color: #333;
  appearance: none; /* Hide default arrow */
  background-image: url('data:image/svg+xml;base64,...'); /* Custom arrow icon */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  padding-right: 30px; /* Space for the arrow icon */
}

/* Style for dropdown arrow */
.select::-ms-expand {
  display: none; /* Hide default dropdown arrow in IE */
}

/* Option styling */
.select option {
  padding: 10px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
}

.checkboxLabel {
  font-size: 13px;
  margin-bottom: 5px;
}

.searchButton {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.searchButton:hover {
  background-color: #0056b3;
}

.resultsCount {
  text-align: left;
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
}

.smallNote {
  font-size: 11px;
  color: #666;
  margin-top: 5px;
}


.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* From Uiverse.io by satyamchaudharydev */ 
.buttonSubmit {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: rgb(0 107 179);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.buttonSubmit:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.buttonSubmit:hover .icon {
  transform: translate(4px);
}

.buttonSubmit:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.buttonSubmit::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}


@media (max-width: 1024px) {
  .searchForm {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .searchForm {
    grid-template-columns: 1fr;
  }
}



/*Styling for the companies listing of last 4 companies search*/


.resultsContainer {
  margin: 20px 20px 40px 20px; /* Increased bottom margin to 40px */
  margin-top: 5rem;
  font-family: 'Arial', sans-serif;
}

.paragraphPremium {
  margin-top: 5px;
  margin-bottom: 10px;
  
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 600;
  color: #333; /* Darker title color */
}

/* Company Card */
.companyCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 15px; /* Slightly increased margin */
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light box-shadow for a subtle depth */
  cursor: pointer;

}

.companyIcon {
  margin-right: 15px;
  color: #6c757d; /* Gray color for icon */
  font-size: 1.5rem; /* Large icon size to match image */
}

.companyInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.companyName {
  font-size: 1.1rem;
  color: #007bff; /* Blue for company name */
  margin-bottom: 5px;
}

.companyAddress {
  font-size: 0.9rem;
  color: #6c757d; /* Light gray for address */
}

/* Company Actions - Buttons */
.companyActions {
  display: flex;
  align-items: center;
}

.viewNetworkBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #ff5a5f;
  border: 1px solid #ff5a5f;
  padding: 8px 12px;
  border-radius: 20px; /* Rounded buttons */
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.viewNetworkBtn:hover {
  background-color: #ff5a5f;
  color: white;
}

.followBtn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 20px; /* Rounded buttons */
  cursor: pointer;
  transition: all 0.2s ease;
}

.followBtn:hover {
  background-color: #0069d9;
}

.iconEye {
  margin-right: 5px;
  font-size: 1.1rem;
}

/* Premium Container */
.premiumContainer {
  background-color: #243847;
  color: white;
  padding: 30px; /* Increased padding */
  border-radius: 12px; /* Slightly more rounded corners */
  margin-top: 4rem;
  margin-bottom: 4rem;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
}

.premiumContainer h3 {
  font-size: 1.6rem; /* Slightly larger font size */
  margin-bottom: 20px;
  font-weight: 700; /* Bolder heading */
  color: white;
}

.premiumContainer p {
  font-size: 1.1rem; /* Slightly larger font size */
  margin-bottom: 25px; /* Increased margin for better spacing */
  color: #d1d1d1;
  line-height: 1.8; /* Increased line height for better readability */
}

.premiumButton {
  background: linear-gradient(135deg, #ff5a5f, #ff2a2a); /* Gradient background */
  color: white;
  border: none;
  padding: 14px 28px; /* Increased padding */
  font-size: 1.1rem; /* Slightly larger font size */
  border-radius: 25px; /* More rounded corners */
  cursor: pointer;
  font-weight: 700; /* Bolder font weight */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slightly larger shadow */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.premiumButton:hover {
  background: linear-gradient(135deg, #e04a4c, #cc383a); /* Darker gradient on hover */
  transform: scale(1.05); /* Slightly larger button on hover */
}

.premiumButton:focus {
  outline: 2px solid #ff5a5f; /* Focus outline for accessibility */
  outline-offset: 2px;
}


/* Mobile Responsive */
@media (max-width: 768px) {
  .companyCard {
    flex-direction: column;
    align-items: flex-start;
  }

  .premiumContainer {
    padding: 20px;
  }

  .premiumContainer h3 {
    font-size: 1.2rem;
  }

  .premiumButton {
    padding: 10px 20px;
  }
}



/* break*/

.buttonSpinnerContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.notification-container {
  position: fixed;
  top: 20px;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset for perfect centering */
  z-index: 1000;
  max-width: 300px; /* Limit the width */
  display: flex;
  flex-direction: column; /* Stack notifications vertically */
  gap: 10px; /* Space between notifications */
}

.notification {
  padding: 15px; /* Increased padding for better spacing */
  border-radius: 8px; /* Softer corners */
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition for hover and opacity */
  opacity: 0; /* Start as invisible */
  animation: fadeIn 0.5s forwards; /* Fade-in effect */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End position */
  }
}

.notification:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.notification.info {
  background-color: #007bff; /* Use a specific shade for info */
}

.notification.success {
  background-color: #28a745; /* Use a specific shade for success */
}

.notification.error {
  background-color: #dc3545; /* Use a specific shade for error */
}

.notification-icon {
  margin-right: 10px; /* Space between icon and text */
}

.notification-close {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  position: absolute; /* Positioning for close button */
  top: 5px;
  right: 10px;
  opacity: 0.7; /* Slightly transparent */
}

.notification-close:hover {
  opacity: 1; /* Full opacity on hover */
}


.blur-effect {
  filter: blur(3px);
  opacity: 0.7;
}

.blur-effect-transition {
  transition: filter 0.3s, opacity 0.3s;
}

/* Add transitions for smoothness */
.blur-effect-transition {
  transition: filter 0.3s ease;
}

/* Style for the checkbox container */
.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Style for the checkbox input */
.checkbox-label input {
  margin-right: 8px;
}

/* Style for the custom checkbox */
.checkbox-label input[type="checkbox"]:checked + span {
  background-color: #4CAF50; /* Background color when checked */
  color: white; /* Text color when checked */
  border: 1px solid #4CAF50; /* Border color when checked */
  padding: 5px;
  border-radius: 4px;
}


.pb-70 {
  background: white;
}
ul {
  margin-bottom: 0 !important;
}
.flipbox_title {
  padding-top: 20px;
}
.techno_flipbox_back {
  background-image: url(./images/feature3.jpg);
}
.team_area {
  background-image: url(./images/feature1.jpg);
}


.call_do_action {
  background-image: url(./images/mapi.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.single-video {
  margin-left: -190px;
  margin-top: 100px;
}
.foot {
  background-color: #19314f !important;
}
.contact_area {
  background-image: url(./images/bg-cnt.jpg);
}

.techno_nav_manu {
  background: #fff;
  transition: 0.5s;
  margin-bottom: 0;
  z-index: 999;
  position: relative;
}
.techno_nav_manu.transparent_menu {
  background: transparent;
  margin-bottom: -87px;
  position: relative;
}
.sticky {
  left: 0;
  margin: auto;
  position: fixed !important;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  background: #00247e !important;
  transition: 0.5s;
}

.style-two.techno_nav_manu {
  background: transparent;
  transition: 0.5s;
  margin-bottom: -87px;
  z-index: 999;
  position: relative;
}
.style-two .techno_menu > ul > li > a {
  color: #fff;
  font-size: 16px;
}

.style-two .donate-btn-header {
  display: inline-block;
  margin-left: 60px;
}
.style-two a.dtbtn {
  user-select: none;
  -moz-user-select: none;
  background: #fff;
  color: #232323;
  font-size: 16px;
}

.style-three .techno_menu > ul > li > a {
  color: #616161;
  font-size: 16px;
  font-weight: 400;
}
a.seo-button {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  background: #0c5adb;
  color: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
a.seo-button:before {
  position: absolute;
  content: "";
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  background: #00247e;
  transform: scale(0);
  transition: 0.5s;
}
a.seo-button:hover:before {
  transform: scale(1);
}
a.seo-button:hover {
  color: #fff;
}
a.dtbtn {
  user-select: none;
  background: #0c5adb;
  border: medium none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  margin-bottom: 0;
  padding: 10px 30px;
  text-align: center;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  border-radius: 5px;
  border: 2px solid #0c5adb;
  font-weight: 600;
}
a.dtbtn:hover {
  border: 2px solid #00247e;
  background: #00247e;
  color: #fff;
}

.header_top_menu_address_inner ul {
  list-style: none;
}
.header_top_menu_address_inner ul li {
  display: inline-block;
}
.header_top_menu_icon_inner ul {
  list-style: none;
  text-align: right;
}
.header_top_menu_icon_inner ul li {
  display: inline-block;
}
.header_top_menu_address_inner ul li a i {
  font-size: 16px;
  margin-right: 10px;
  color: #fff;
}
.header_top_menu_address_inner ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-right: 15px;
}
.header_top_menu_icon_inner ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0 5px;
}

.button a {
  background: #0c5adb;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 44;
  border-radius: 5px;
  color: #fff;
}
.button.color_two a {
  background: #00247e;
}
.button.color_two a:before {
  background: #0c5adb;
}
.button.two a {
  padding: 14px 35px;
}
.button.three a {
  background: #fff;
  padding: 14px 35px;
  color: #2e4a71;
}
.button.three a:hover {
  background: #2e4a71 !important;
}
.button.style-four a {
  background: transparent;
  padding: 10px 30px;
  border-radius: 30px;
  color: #0c5adb;
  border: 2px solid #0c5adb;
}
.button.style-four a:before {
  background: #0c5adb;
}
.button .active {
  background: #00247e;
  margin-left: 25px;
}
.button .active:before {
  background: #0c5adb;
}
.button a i {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
.button a:before {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background: #00247e;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: -1;
}
.button a:hover {
  color: #fff;
}
.button a:hover:before {
  width: 180%;
}
.slider12 .button a i {
  font-size: 14px;
}
.slider12 .button a {
  font-size: 17px;
}
.slider12 .button .active {
  background: transparent;
  border: 1px solid #fff;
}
.slider12 .button .active:hover {
  border-color: #0c5adb;
}
.slider13 .slider_text_inner h5,
.slider14 .slider_text_inner h5 {
  font-size: 20px;
  color: #0c5adb;
  margin: 0 0 16px;
}
.slider13 .slider_text_inner h1,
.slider14 .slider_text_inner h1 {
  font-size: 60px;
  color: #232323;
}

.slider13 .slider_text_inner p,
.slider14 .slider_text_inner p {
  width: 67%;
  font-size: 18px;
  padding: 23px 0 11px;
  color: #616161;
  margin: 0;
}
.slider13 .button a,
.slider14 .button a {
  padding: 13px 32px;
  font-size: 18px;
}

.slider-video {
  position: relative;
}
.slider-video .video-icon {
  position: absolute;
  right: 0;
  top: -165px;
}
.slider-video.two .video-icon {
  left: 262px;
  top: -74px;
}
.appointment .slider-video.two .video-icon {
  left: 0;
  top: 0;
}
.single-video2 .video-icon {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.video-icon a {
  width: 10px;
  height: 10px;
  line-height: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: relative;
  color: #0c5adb;
  -webkit-animation: ripple-white 1s linear infinite;
  animation: ripple-blue 1s linear infinite;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #fff;
  font-size: 28px;
  z-index: 44;
}
.slider10 .video-icon a {
  border-radius: 0;
}
@-webkit-keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
  }
}

@keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
  }
}

.data_science_video {
  position: relative;
}
.banner_area .data_science_video_inner {
  position: absolute;
  left: 40%;
  bottom: -16px;
}
.data_science_video_inner a i {
  position: absolute;
  left: 52px;
  font-size: 35px;
  color: #0c5adb;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.single_banner_thumb_inner img {
  width: 100%;
}
.banner_shape {
  position: relative;
}
.banner_shape_inner1 {
  position: absolute;
  top: 156px;
  left: 24%;
}
.section-inner-content p {
  font-size: 18px;
  margin: 0;
}
.banner_shape_inner2 {
  position: absolute;
  top: 220px;
  left: 56%;
}
.banner_shape_inner3 {
  position: absolute;
  top: 162px;
  left: 92%;
}
.banner_shape_inner4 {
  position: absolute;
  top: 371px;
  left: 190px;
}
.banner_shape_inner5 {
  position: absolute;
  right: -140px;
  top: 342px;
}
.banner_shape_inner6 {
  position: absolute;
  right: 55px;
  bottom: -77px;
}

.machine_banner_shape {
  position: relative;
}
.machine_banner_shape_inner1 {
  position: absolute;
  top: -100px;
  left: 115px;
}
.machine_banner_shape_inner2 {
  position: absolute;
  left: 6px;
  top: 60px;
}
.machine_banner_shape_inner3 {
  position: absolute;
  left: 184px;
  top: 100px;
}
.machine_banner_shape_inner4 {
  position: absolute;
  left: 265px;
  top: 70px;
}
.machine_banner_shape_inner5 {
  position: absolute;
  right: -45px;
  top: -92px;
}
.machine_banner_shape_inner6 {
  position: absolute;
  top: 100px;
  right: -20px;
}
.machine_banner_shape_inner7 {
  position: absolute;
  top: 60px;
  right: -195px;
}
.machine_banner_shape_inner8 {
  position: absolute;
  top: 210px;
  left: -10px;
}
.machine_banner_shape_inner9 {
  position: absolute;
  top: 340px;
  left: 230px;
}
.machine_banner_shape_inner10 {
  position: absolute;
  top: 225px;
  right: -165px;
}
.machine_banner_shape_inner11 {
  position: absolute;
  right: 30px;
  top: 345px;
}

.affiliate_banner_shape {
  position: relative;
}
.affiliate_banner_shape_inner1 {
  position: absolute;
  top: 170px;
  left: 0;
}
.affiliate_banner_shape_inner2 {
  position: absolute;
  top: 170px;
  left: 0;
}
.affiliate_banner_shape_inner3 {
  position: absolute;
  top: 45px;
  left: 126px;
}
.affiliate_banner_shape_inner4 {
  position: absolute;
  top: -30px;
  left: 170px;
}
.affiliate_banner_shape_inner5 {
  position: absolute;
  right: -245px;
  top: 50px;
}
.affiliate_banner_shape_inner5 img {
  width: 80%;
}
.affiliate_banner_shape_inner6 {
  position: absolute;
  right: -135px;
  top: 190px;
}
.affiliate_banner_shape_inner7 {
  position: absolute;
  right: -163px;
  top: -45px;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
}

.lines .line {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  -webkit-animation: moveLeftBounces-one 20s linear infinite;
  animation: moveLeftBounces-one 20s linear infinite;
}

.lines .line:nth-child(1) {
  margin-left: -25%;
}

.lines .line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.lines .line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.lines .line:nth-child(3) {
  margin-left: 25%;
}

.lines .line-two {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.lines .line-two::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #d80650;
  -webkit-animation: moveLeftBounces-two 20s linear infinite;
  animation: moveLeftBounces-two 20s linear infinite;
}

.lines .line-two:nth-child(1) {
  margin-left: -25%;
}

.lines .line-two:nth-child(1)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.lines .line-two:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.lines .line-two:nth-child(3) {
  margin-left: 25%;
}

.lines .line-three {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.lines .line-three::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #d80650;
  -webkit-animation: moveLeftBounces-one 20s linear infinite;
  animation: moveLeftBounces-one 20s linear infinite;
}

.lines .line-three:nth-child(1) {
  margin-left: -25%;
}

.lines .line-three:nth-child(1)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.lines .line-three:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.lines .line-three:nth-child(3) {
  margin-left: 25%;
}

@-webkit-keyframes moveLeftBounces-one {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveLeftBounces-one {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.nivo_slider_area {
  position: relative;
}
.nivo-caption {
  background: rgba(0, 0, 0, 0);
  height: 100%;
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.em_slider_inner {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999;
}

.em-slider-title {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 23px;
  text-transform: uppercase;
}
.em-slider-main-title {
  color: #fff;
  font-size: 120px;
  font-weight: 800;
}
.em-slider-sub-title {
  color: #fff;
  font-size: 33px;
  font-weight: 400;
}
.em-nav-slider-title-center {
  color: #fff;
  font-size: 55px;
  font-weight: 800;
}
.em-nav-slider-title-centerspan {
  color: #0c5adb;
}
.em-slider-up-title {
  font-size: 38px;
  color: #fff;
  font-weight: 300;
  padding-bottom: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.em-slider-sub-em-title {
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}
.em-slider-descript {
  font-size: 17px;
  margin-bottom: 20px;
  width: 59%;
  z-index: 999;
  font-weight: 400;
  color: #ddd;
  margin-top: 10px;
}
.text-left .em-slider-descript {
  margin-left: 0;
  margin-right: auto;
}

.text-center .em-slider-descript {
  margin: 11px auto 28px;
}
.text-right .em-slider-descript {
  margin-right: 0;
  margin-left: auto;
}
.text-center {
  text-align: center;
}

.em-slider-button.wow.bounceInUp.em-button-button-area.animated {
  padding-top: 19px;
}
.em-button-button-area a {
  border: 1px solid #ccc;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  margin-right: 10px;
  margin-top: 12px;
  padding: 15px 41px;
  position: relative;
  text-transform: uppercase;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  z-index: 999;
  letter-spacing: 1px;
  border-radius: 5px;
}
a.em-active-button {
  background: #0c5adb;
  border-color: #0c5adb;
}

.em-button-button-area a:hover {
  color: #fff;
  background: #2e4a71;
  border-color: #0c5adb;
}

a.em-active-button:hover {
  background: transparent;
  border-color: #fff;
}

.em-slider-half-width {
  width: 50%;
}

.em-slider-left {
  padding-right: 30px;
}

.em-slider-right {
  padding-left: 30px;
}

.em-slider-full-width {
  width: 85%;
}

.em-slider-half-width .em-slider-descript {
  width: 100%;
}

.em-nivo-slider-wrapper .nivo-directionNav a {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 75px;
  width: 50px;
  line-height: 68px;
  text-align: center;
  display: block;
  border-radius: 50%;
  color: #0c5adb;
  font-size: 40px;
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  border-radius: 0;
  background: #fff;
  margin-right: 30px;
}
.em-nivo-slider-wrapper .nivo-directionNav .nivo-prevNav {
  margin-left: 30px;
}
.em-nivo-slider-wrapper .nivo-directionNav .nivo-nextNav {
  left: auto;
  right: 0;
}
.em-nivo-slider-wrapper .nivo-directionNav a:hover {
  background: #0c5adb;
  border-color: #0c5adb;
  color: #fff;
}
.em-nivo-slider-wrapper:hover .nivo-directionNav a {
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
  left: 50px;
}

.em-nivo-slider-wrapper:hover .nivo-directionNav .nivo-nextNav {
  left: auto;
  right: 50px;
}

.em-nivo-slider-wrapper .nivo-controlNav {
  bottom: 50px;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 9;
  display: none;
}

.em-nivo-slider-wrapper .nivo-controlNav a {
  background: #000 none repeat scroll 0 0;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 25px;
  margin: 0 5px;
  width: 25px;
  color: #fff;
  line-height: 25px;
}

.em-nivo-slider-wrapper .nivo-controlNav a:hover,
.em-nivo-slider-wrapper .nivo-controlNav a.active {
  background: #ffa700 none repeat scroll 0 0;
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.cd-headline.clip span {
  display: inline-block;
  padding: 0 0 0;
}
.cd-headline.clip span b {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
}

.appointment {
  position: relative;
  z-index: 99 !important;
}
.sign-up-form-wrap {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}
.form-control {
  height: 54px;
  background-color: #fff;
  border-color: transparent;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #e6e6e6;
  padding: 6px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
}
.form-group .btn {
  background: #0c5adb;
  border: 0;
  display: inline-block;
  text-align: center;
  border-radius: 7px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #fff;
  width: 100%;
  height: 54px;
}
.appointment_title {
  font-weight: 800;
}
.slider_bottom_shape_inner {
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  z-index: -1;
}

.slider {
  position: relative;
  width: 100%;
}
.tp-caption a {
  font-size: 17px !important;
  color: #fff;
  background: #0c5adb;
  padding: 12px 32px !important;
  margin-right: 16px;
  display: inline-block;
  border-radius: 5px;
  transition: 0.5s;
}
.tp-caption a:hover {
  background: #2e4a71;
}
.normalWraping {
  white-space: normal !important;
}
.tp-bullets.vor_bullet .tp-bullet span {
  display: none;
}

.breatcome_area {
  background: url(./images/slider-10.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 500px;
  position: relative;
}
.breatcome_area:before {
  position: absolute;
  content: "";
  background-image: linear-gradient(
    to right,
    rgba(12, 90, 219, 0.85),
    rgba(12, 90, 219, 0.8),
    rgba(18, 85, 194, 0.7),
    rgba(34, 86, 172, 0.75),
    rgba(38, 85, 163, 0.75)
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.breatcome_title {
  text-align: center;
}
.breatcome_title_inner h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}
.breatcome_content ul li {
  display: inline-block;
}
.breatcome_content ul li a {
  color: #fff;
  opacity: 0.7;
  transition: 0.5s;
}
.breatcome_content ul li i {
  color: #fff;
  opacity: 0.7;
  padding: 0 5px;
}
.breatcome_content ul li span {
  color: #fff;
  opacity: 0.7;
}
.breatcome_content ul li a:hover {
  opacity: 1;
}

.bg-dark2 {
  background: #181b2d;
}
.dark1 {
  background-image: url(./images/dark1.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 900px;
}
.dark2 {
  background-image: url(./images/dark2.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 900px;
}
.dark.top_feature .techno_flipbox .techno_flipbox_font {
  background: #181b2d;
}
.dark.top_feature .techno_flipbox .flipbox_title h3 {
  color: #fff;
}
.dark .about_icon .icon {
  color: #fff;
}
.dark .singel-about-content h5 {
  color: #fff;
}
.dark .singel-about-content p {
  color: #fff;
}
.dark .single_work_content_title h4 {
  color: #fff;
}
.dark .single_it_work_content_text p {
  color: #fff;
}
.dark .single_testimonial_content_title h4 {
  color: #fff;
}
.dark .single_blog {
  border: 0;
}
.dark .cntr_bg_up {
  position: relative;
  margin-bottom: -100px;
}

.landing .slider_text_desc p {
  margin: auto;
}
.landing .button {
  margin: auto;
}
.landing .big-half-circle.big-half-circle-1 {
  width: 100%;
  margin-bottom: 0px;
  height: 80px;
  position: absolute;
  bottom: 0;
}

.section_sub_title h6 {
  letter-spacing: 5px;
  color: #0c5adb;
  font-size: 16px;
}
.section_title.text_center {
  text-align: center;
}
.section_main_title h1,
.section_main_title h2 {
  font-weight: 800;
  margin: 0;
}
.titlee h1 {
  color: white !important;
}
.text_left .section_main_title h1 {
  margin: 0;
  letter-spacing: -1px;
  color: black;
}
.section_main_title h1 span {
  color: #366094;
}
.section_content_text p {
  font-size: 18px;
}
.text_center .section_content_text p {
  width: 75%;
  margin: auto;
  font-size: 19px;
}
.section_content_text.bold p {
  font-size: 22px;
  font-weight: 600;
}

.section_sub_title h6 {
  letter-spacing: 0;
  color: #366094;
  font-size: 16px;
}

.about_area.odoo .section_content_text {
  margin: 36px 0 26px;
}
.about_area.odoo .text_left .section_main_title h1 {
  font-size: 39px;
}

.section_button {
  text-align: right;
}
.phone_number h5 {
  letter-spacing: 1px;
  font-weight: 500;
  color: white;
}

.about_area .upper.section_content_text {
  margin: 19px 0 26px;
}
.about_area .upper.section_content_text p {
  font-size: 16px;
  padding: 0 0 0 20px;
  border-left: 2px solid #0c5adb;
  width: 100%;
}
.upper.section_content_text p {
  font-size: 16px;
  padding: 0 0 0 20px;
  width: 62%;
}
.upper1.section_content_text p {
  font-size: 16px;
  padding: 18px 0 0px;
  width: 100%;
}
.upper.section_main_title h1 {
  font-size: 38px !important;
  letter-spacing: 0;
}

.section_sub_title h5 {
  letter-spacing: 0;
  color: #0c5adb;
  font-size: 16px;
  position: relative;
  z-index: 1;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0 0 18px 20px;
}
.section_sub_title h5:before {
  position: absolute;
  content: "";
  z-index: -1;
  left: 0;
  top: -12px;
  filter: drop-shadow(0px 5px 15px rgba(152, 179, 223, 0.2));
  background-color: #ffffff;
  width: 145px;
  height: 42px;
  border-radius: 4px;
}
.upper.section_sub_title h5:before {
  right: 0;
  margin: auto;
  left: 15px;
  top: -12px;
}
.feature-area .section_sub_title h5:before {
  width: 174px;
}
.service-area .section_sub_title h5:before {
  width: 174px;
}
.why-choose-us .section_sub_title h5:before {
  width: 161px;
}
.testimonial-area .section_sub_title h5 {
  color: #fff;
}
.testimonial-area .section_sub_title h5:before {
  filter: drop-shadow(0px 5px 15px rgba(152, 179, 223, 0.2));
  background-color: rgba(255, 255, 255, 0.14901960784313725);
  width: 173px;
}
.blog-area .section_sub_title h5:before {
  width: 123px;
  height: 42px;
}

.section_main_title1 h1 {
  font-size: 32px;
  color: #232323;
  line-height: 45px;
}
.section_sub_title1 h6 {
  letter-spacing: 0;
  color: #0c5adb;
  font-size: 18px;
  font-weight: 700;
}
.section_content_text1 p {
  font-size: 16px;
  padding: 17px 0 10px;
}
.section_content_text2 p {
  font-size: 16px;
  padding: 10px 0 15px;
}

.upper.section_sub_title1 h6 {
  letter-spacing: 0;
  color: #0c5adb;
  font-size: 18px;
  font-weight: 700;
  padding: 0 0 0 40px;
  position: relative;
  z-index: 1;
}
.upper.section_sub_title1 h6:before {
  position: absolute;
  content: "";
  left: -1px;
  top: 11px;
  width: 32px;
  height: 1px;
  background: #0c59db;
}

.em_bar {
  background: transparent none repeat scroll 0 0;
  height: 2px;
  margin: 0 auto 5px;
  width: 62px;
}
.text_left .em_bar {
  margin: 0;
}
.em_bar_bg {
  height: 5px;
  width: 90px;
  background: #3660947a;
  margin: 20px auto;
  position: relative;
  border-radius: 30px;
}
.em_bar_bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #366094;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}
@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

.about_area {
  position: relative;
  margin-top: 80px;
}
.single_about_thumb {
  position: relative;
  left: -92px;
}
.em-about-thmub-inner {
  position: absolute;
  bottom: -60px;
  left: -90px;
}
.em-about-thmub-inner1 {
  position: absolute;
  top: -45px;
  left: -60px;
}
.em-about-thmub-inner2 {
  position: absolute;
  top: -35px;
  left: -35px;
}

.em-about-border-box {
  border-top: 1px solid rgba(35, 35, 35, 0.10196078431372549);
  border-bottom: 1px solid rgba(35, 35, 35, 0.10196078431372549);
  padding: 20px 0 28px;
}

.em-about-icon {
  float: left;
  margin-right: 20px;
}
.em-about-title h3 {
  font-size: 20px;
  line-height: 28px;
}

.abou-button1 a {
  background: linear-gradient(to left, #2475fc, #1129b9 100%);
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 44;
  border-radius: 5px;
  color: #fff;
}
.abou-button1 a:before {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background: #00247e;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: -1;
}
.abou-button1 a:hover:before {
  width: 180%;
}
.abou-button1 a:hover {
  color: #fff;
}

.em-about-icons {
  float: left;
  margin-right: 20px;
}
.em-about-icons i {
  font-size: 30px;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px solid rgba(35, 35, 35, 0.10196078431372549);
  border-radius: 50%;
  text-align: center;
  color: #1029b9;
}
.em-about-title2 p {
  margin-bottom: 0;
}
.em-about-title2 h3 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 2px;
}
.shape-thumb {
  position: absolute;
  left: 33px;
  top: -164px;
  z-index: -1;
}

.happy-client-title p {
  font-size: 18px;
  color: #232323;
  font-weight: 700;
  position: relative;
  z-index: 1;
}
.happy-client-title p:before {
  position: absolute;
  content: "";
  right: 5px;
  top: 15px;
  width: 60px;
  height: 2px;
  background-color: rgba(12, 90, 219, 0.25098039215686274);
}
.brand-thumb {
  text-align: right;
}

.style-three.about_area {
  background: #f4f8fd;
  padding: 105px 0 170px;
}

.abou-button2 a {
  background: linear-gradient(to left, #2475fc, #1129b9 100%);
  padding: 13px 35px;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 44;
  border-radius: 5px;
  color: #fff;
}
.abou-button2 a:before {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background: #00247e;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: -1;
}
.abou-button2 a:hover:before {
  width: 180%;
}
.abou-button2 a:hover {
  color: #fff;
}
.row.brand-bg3 {
  border-radius: 5px;
  filter: drop-shadow(0px 10px 20px rgba(12, 90, 219, 0.07));
  background-color: #ffffff;
  padding: 86px 27px 84px;
  position: relative;
  z-index: 1;
  top: -103px;
}
.brand-img {
  transition: 0.5s;
}
.brand-img img:hover {
  filter: invert(15%) sepia(32%) saturate(77%) hue-rotate(6deg) brightness(11%)
    contrast(60%);
}

.nagative_margin {
  position: relative;
  margin-top: -100px;
}
.nagative_margin3 {
  position: relative;
  margin-top: -100px;
  z-index: 99;
}
.nagative_margin4 {
  position: relative;
  margin-top: -225px;
}
.nagative_margin5 {
  position: relative;
  margin-top: -70px;
}
.top_feature .techno_flipbox .techno_flipbox_font {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border: 0;
}
.top_feature .techno_flipbox .techno_flipbox_back {
  background: #22334c;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.top_feature .techno_flipbox .techno_flipbox_inner {
  padding: 0 30px;
}
.top_feature .techno_flipbox .techno_flipbox_back::before {
  display: none;
}
.top_feature .icon {
  color: #0c5adb;
  font-size: 45px;
  display: inline-block;
  line-height: 46px;
}
.top_feature .techno_flipbox .flipbox_title h3 {
  font-size: 20px;
  color: #232323;
  font-weight: 500;
}

.em-feature-single-box {
  background: #e5eeff;
  padding: 23px 40px 40px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.em-feature-single-box:before {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(to left, #2475fc, #1129b9);
  border-radius: 10px;
  transition: 0.5s;
}
.em-feature-single-box:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url(./images/box-bg.png);
  border-radius: 10px;
  transition: 0.5s;
}

.feature-single-box-inner {
  position: relative;
  z-index: 1;
}
.feature-single-box-inner:before {
  position: absolute;
  content: "";
  z-index: 1;
  right: -117px;
  top: 19px;
  background: url(./images/feature1.png);
  width: 80%;
  height: 78%;
  background-repeat: no-repeat;
  background-position: center;
}
.feature-single-box-inner1 {
  position: relative;
  z-index: 1;
}
.feature-single-box-inner1:before {
  position: absolute;
  content: "";
  z-index: 1;
  right: -117px;
  top: 19px;
  background: url(./images/feature2.png);
  width: 80%;
  height: 78%;
  background-repeat: no-repeat;
  background-position: center;
}

.em-feature-title h2 {
  font-size: 24px;
  font-weight: 700;
  transition: 0.5s;
}
.em-feature-title p {
  font-size: 16px;
  padding: 17px 0 16px;
  transition: 0.5s;
}
.em-feature-button a {
  display: inline-block;
  color: #fff;
  font-weight: 400;
  padding: 8px 30px;
  background: linear-gradient(to left, #2475fc, #1129b9);
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid transparent;
}

.em-feature-single-box:hover:before {
  left: 0;
  width: 100%;
}
.em-feature-single-box:hover .em-feature-title h2,
.em-feature-single-box:hover .em-feature-title p {
  color: #fff;
}
.em-feature-single-box:hover .em-feature-button a {
  background: transparent;
  border: 1px solid #fff;
}

.flip-box {
  background-color: transparent;
  width: 100%;
  height: 225px;
  perspective: 1000px;
  margin-bottom: 30px;
}
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #0c5adb;
  color: black;
  border-radius: 5px;
}
.flipbox-icon {
  padding-top: 22px;
}
.flip-box-content h2 {
  font-size: 23px;
  color: #fff;
}
.flip-box-content p {
  color: #fff;
  padding: 10px 15px;
}
.flipbox-icon .icon {
  font-size: 35px;
  color: #fff;
}

.flip-box-back {
  background-color: #fff;
  color: white;
  transform: rotateY(180deg);
  border-radius: 5px;
  box-shadow: 0px 0px 25px 17px rgb(0 0 0 / 15%);
  padding: 10px 0 0;
}
.flip-box-back-content h2 {
  font-size: 23px;
  color: #0c5adb;
}
.flip-box-back-content p {
  color: #232323;
  padding: 10px 15px 3px;
}
.flip-box-back-content a {
  padding: 9px 30px;
  color: #ffffff;
  background-color: #0c5adb;
  border-radius: 4px 4px 4px 4px;
  display: inline-block;
}

.flipbox_area.odoo .techno_flipbox_font .icon {
  color: #fff;
  font-size: 40px;
  background: transparent;
}
.flipbox_area.odoo .techno_flipbox .flipbox_title h3 {
  margin: 0;
}
.flipbox_area.odoo .techno_flipbox .techno_flipbox_back::before {
  background: #0c5adbeb;
}
.flipbox_area.odoo
  .techno_flipbox
  .techno_flipbox_back
  .flipbox_button
  a:hover {
  color: #fff;
  border-color: #1f1c51;
  background: #1f1c51;
}
.flipbox_area.odoo .techno_flipbox_back .flipbox_desc p {
  color: #fff;
  margin: 0 0 5px;
}
.flipbox_area.odoo .section_title.white .section_main_title h1,
.section_title.white .section_main_title h2 {
  font-size: 39px;
}

.top_feature.two .techno_flipbox .techno_flipbox_font,
.top_feature.two .techno_flipbox .techno_flipbox_back {
  min-height: 223px;
}
.top_feature.two .techno_flipbox .techno_flipbox_back {
  background: #fff;
}
.top_feature.two .techno_flipbox .techno_flipbox_inner {
  padding: 0 24px;
}
.top_feature.two .icon {
  color: #fff;
  font-size: 40px;
  line-height: 90px;
  height: 90px;
  width: 90px;
  background: #0c5adb;
  border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
}
.top_feature.two .techno_flipbox .flipbox_title h3 {
  padding-bottom: 0;
  font-size: 21px;
}
.top_feature.two .techno_flipbox .techno_flipbox_back .flipbox_title h3 {
  color: #232323;
  font-size: 21px;
}
.top_feature.two .techno_flipbox_back .flipbox_desc p {
  color: #616161;
}

.feature_style_three {
  position: relative;
  border-radius: 10px;
}
.feature_style_three:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(41, 55, 66, 0.6);
  content: "";
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 10px;
}
.feature_style_tree_thumb img {
  width: 100%;
  border-radius: 10px;
}
.feature_style_tree_content {
  position: absolute;
  left: 0;
  bottom: -67px;
  padding: 0 50px 0 38px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.feature_style_thre_icon .icon {
  color: #fff;
  font-size: 50px;
}
.feature_style_three_title h4 {
  color: #fff;
  font-weight: 700;
}
.feature_style_three_text {
  color: #fff;
  opacity: 0;
  transition: 0.3s;
}
.feature_style_three_butoon a {
  color: #fff;
  text-transform: uppercase;
  opacity: 0;
  transition: 0.3s;
  font-size: 14px;
}
.feature_style_three:hover:before {
  background: rgba(12, 90, 219, 0.85);
}
.feature_style_three:hover .feature_style_three_text,
.feature_style_three:hover .feature_style_three_butoon a {
  opacity: 1;
}
.feature_style_three:hover .feature_style_tree_content {
  bottom: 45px;
}

.feature_style_four {
  background: #fff;
  text-align: center;
  box-shadow: 6px 5px 30px 0px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  transition: 0.5s;
  padding: 50px 45px;
}
.feature_style_four.active {
  background: #0c5adb;
}
.feature_style_four_icon i {
  background: #edf4fc;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  line-height: 130px;
  font-size: 40px;
  color: #0c5adb;
  transition: 0.4s;
  display: inline-block;
}
.feature_style_four_title h4 {
  font-weight: 700;
  transition: 0.5s;
}
.feature_style_four_text p {
  transition: 0.5s;
}
.feature_style_four_button a {
  transition: 0.5s;
}
.feature_style_four.active .feature_style_four_title h4,
.feature_style_four.active .feature_style_four_text p,
.feature_style_four.active .feature_style_four_button a {
  color: #fff;
}
.feature_style_four_button a i {
  margin-left: 6px;
}
.feature_style_four:hover {
  background: #0c5adb;
  transform: translateY(-10px);
}
.feature_style_four:hover .feature_style_four_title h4,
.feature_style_four:hover .feature_style_four_text p,
.feature_style_four:hover .feature_style_four_button a {
  color: #fff;
}

.feature_style_five {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  padding: 35px 30px 10px;
  box-shadow: 0 10px 29.7px 0.3px rgba(225, 225, 225, 0.64);
  position: relative;
  border-radius: 0px 50px 50px 50px;
  background: #fff;
}
.feature_style_five::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(./images/dot.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
.feature_style_five_cion i {
  color: #0c5adb;
  background-color: #eff2f7;
  border-radius: 0 40px 40px;
  font-size: 25px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  transition: 0.5s;
}
.feature_style_five_title h4 {
  font-size: 22px;
  font-weight: 700;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.feature_style_five_text p {
  transition: 0.5s;
}
.feature_style_five:hover {
  background: #0c5adb;
  transform: translateY(-10px);
}
.feature_style_five:hover .feature_style_five_title h4,
.feature_style_five:hover .feature_style_five_text p {
  color: #fff;
}
.single_feature_six {
  background: rgba(0, 0, 0, 0.67);
  padding: 20px 20px 15px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.single_feature_six::before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #0c5adb;
  content: "";
  transition: 0.5s;
  z-index: -1;
}
.single_feature_six_icon {
  float: left;
  margin-top: 12px;
}
.single_feature_six_icon i {
  font-size: 40px;
  color: #fff;
}
.single_feature_six_content.white {
  overflow: hidden;
}
.single_feature_six_content p {
  padding-top: 10px;
}
.single_feature_six:hover::before {
  width: 100%;
}

.single_feature_seven {
  text-align: center;
  padding: 40px 24px;
  border: 1px solid #e4ecf9;
  position: relative;
  z-index: 1;
  background: #fff;
  margin-bottom: 30px;
}

.single_feature_seven:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 45px;
  height: 45px;
  content: "";
  background: #adc7f1;
  transition: 0.3s;
  z-index: -1;
}
.single_feature_six_seven_icon .icon {
  font-size: 55px;
  color: #0c5adb;
  transition: 0.5s;
}
.single_feature_seven_content h4 {
  transition: 0.5s;
  padding-top: 15px;
}
.single_feature_seven_content p {
  padding-top: 8px;
  transition: 0.5s;
}
.single_feature_seven:hover:before {
  width: 100%;
  height: 100%;
  background: #0c5adb;
}
.single_feature_seven:hover .single_feature_six_seven_icon .icon,
.single_feature_seven:hover .single_feature_seven_content h4,
.single_feature_seven:hover .single_feature_seven_content p {
  color: #fff;
}

.feature_style_eight {
  background: #fff;
  box-shadow: 0 20px 50px 5px #e9eef7;
  text-align: center;
  padding: 60px 40px 45px;
  transition: 0.5s;
  border-radius: 7px;
}
.feature_style_eight:hover {
  box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
}
.feature_style_eight_icon {
  position: relative;
  display: inline-block;
  font-size: 60px;
  width: 130px;
  height: 130px;
  line-height: 120px;
  text-align: center;
  border-radius: 50%;
  z-index: 1;
  transition: all 500ms ease;
  background: #fff;
  box-shadow: 0 20px 50px 5px #e9eef7;
  color: #0c5adb;
}
.feature_style_eight_icon .icon {
  display: inline-block;
}
.feature_style_eight:hover .feature_style_eight_icon {
  color: #fff;
}
.feature_style_eight:hover .feature_style_eight_icon {
  box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
}
.feature_style_eight_icon:before {
  position: absolute;
  content: "";
  background: rgba(12, 90, 219, 0.1);
  width: 100%;
  height: 100%;
  left: -8px;
  top: -5px;
  z-index: -1;
  border-radius: 50%;
  box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.feature_style_eight:hover .feature_style_eight_icon:before {
  background: rgba(12, 90, 219, 0.8);
}
.anim-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  -webkit-animation: service_hexagon 3s infinite linear;
  -moz-animation: service_hexagon 3s infinite linear;
  -o-animation: service_hexagon 3s infinite linear;
  animation: service_hexagon 3s infinite linear;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}
.feature_style_eight:hover .anim-icon {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}
.anim-icon .icon-1 {
  position: absolute;
  left: -25px;
  top: 60px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
  background: rgba(99, 119, 238, 0.8);
}
.anim-icon .icon-2 {
  position: absolute;
  left: 10px;
  top: -20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-animation: zoom-fade-two 4s infinite linear;
  animation: zoom-fade-two 4s infinite linear;
  background: rgba(99, 119, 238, 0.8);
}
.anim-icon .icon-3 {
  position: absolute;
  top: 15px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: zoom-fade-two 3s infinite linear;
  animation: zoom-fade-two 3s infinite linear;
  background: rgba(99, 119, 238, 0.8);
}
.feature_style_eight_title a {
  font-size: 24px;
  font-weight: 700;
}

.style_nine .feature_style_eight {
  padding: 45px 24px 25px;
}
.style_nine .feature_style_eight_icon {
  font-size: 40px;
  width: 100px;
  height: 100px;
  line-height: 95px;
  border-radius: 50%;
}
.style_nine .feature_style_eight_title a {
  font-size: 20px;
}

.techno_flipbox_font {
  border: 1px solid #0c5adb;
  border-radius: 8px;
}
.flipbox .section_main_title h1 {
  color: #fff;
}
.flipbox .section_sub_title h6 {
  color: #fff;
}
.flipbox .section_title {
  margin-bottom: 30px;
}
.techno_flipbox {
  perspective: 1000px;
  position: relative;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  z-index: 1;
}
.techno_flipbox .techno_flipbox_font,
.techno_flipbox .techno_flipbox_back {
  min-height: 200px;
  height: auto;
  box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.15);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateX(0);
  -webkit-transform: rotateX(0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
  position: relative;
}
.techno_flipbox .techno_flipbox_back::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent -80%, #00247e);
}
.icon {
  font-size: 45px;
  line-height: 45px;
}
.techno_flipbox .techno_flipbox_inner {
  text-align: center;
  padding: 0 25px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  perspective: inherit;
  -webkit-perspective: inherit;
  outline: transparent solid 1px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  z-index: 2;
}
.techno_flipbox_icon {
  font-size: 45px;
  text-align: center;
  display: inline-block;
  color: #fff;
  overflow: hidden;
}

.techno_flipbox .flipbox_title h3 {
  font-size: 22px;
  margin-top: 20px;
  text-transform: capitalize;
  -webkit-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
  color: #fff;
}
.techno_flipbox .techno_flipbox_back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 2px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.techno_flipbox .techno_flipbox_back .flipbox_button a {
  text-transform: capitalize;
  margin-top: 22px;
  background: #fff;
  color: #0c5adb;
  display: inline-block;
  padding: 4px 25px;
  border-radius: 5px;
  border: 2px solid #fff;
  transition: 0.5s;
}
.techno_flipbox_back .flipbox_desc p {
  color: #fff;
}
.techno_flipbox .techno_flipbox_back .flipbox_button a i {
  display: none;
}
.techno_flipbox .techno_flipbox_back .flipbox_title h3 {
  padding-bottom: 16px;
  padding-top: 0;
  color: #fff;
  margin-bottom: 0;
}

.flipbox_area.top_feature.upper .techno_flipbox_font .icon {
  font-size: 45px;
  color: #0c5adb;
  background: transparent;
}

.techno_flipbox:hover .techno_flipbox_font {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}
.techno_flipbox:hover .techno_flipbox_back {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.techno_flipbox .techno_flipbox_back .flipbox_button a:hover {
  color: #fff;
  border-color: #0c5adb;
  background: #0c5adb;
}

.pages .techno_flipbox .techno_flipbox_font .flipbox_title h3 {
  color: #444;
}
.pages .techno_flipbox_font .flipbox_desc p {
  color: #666;
}

.about_icon {
  float: left;
}
.about_icon .icon {
  font-size: 40px;
  color: #0c5adb;
}
.singel-about-content {
  overflow: hidden;
}
.singel-about-content.boder {
  border-left: 1px solid #ddd;
}
.singel-about-content h5 {
  font-weight: 700;
  margin: 0;
}
.single_about_thumb_inner img {
  width: 100%;
}
.about_thumb img {
  width: 100%;
}
.about_skill_thumb img {
  width: 100%;
}
.skill-wrapper h6 {
  margin-bottom: 8px;
}
.pd_gap {
  background: #fff;
  padding: 18px 0 18px;
  border-radius: 8px;
}
.home11 .about_icon .icon {
  font-size: 20px;
  margin-top: -8px;
}

.headingh_experience_number h1 {
  font-size: 136px;
  display: inline;
  font-weight: 800;
  color: #0c5adb;
  float: left;
  line-height: 1;
  margin-top: -14px;
  margin-right: 15px;
}
.heading_experience_title h2 {
  font-size: 36px;
  font-weight: 800;
  line-height: 1;
  color: #232323;
  padding-top: 11px;
  display: inline;
}
.heading_experience_text p {
  font-size: 18px;
}
.experience .main_video {
  position: relative;
  margin-top: -285px;
}

.data_science_about_icon .icon {
  height: 90px;
  width: 90px;
  line-height: 90px;
  font-size: 40px;
  color: #0c5adb;
  position: relative;
  text-align: center;
  box-shadow: 0 20px 50px 5px #e9eef7;
  border-radius: 50%;
  z-index: 1;
  background: #fff;
}
.data_science_about_icon .icon:before {
  position: absolute;
  content: "";
  background: rgba(12, 90, 219, 0.1);
  width: 100%;
  height: 100%;
  left: -8px;
  top: -5px;
  z-index: -1;
  border-radius: 50%;
  box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.singel_about_left_inner:hover .data_science_about_icon .icon:before {
  background: rgba(12, 90, 219, 0.9);
}
.singel_about_left_inner:hover .data_science_about_icon .icon {
  color: #fff;
}
.about_content_machine span {
  display: block;
  margin-bottom: 8px;
}
.about_content_machine span i {
  color: #fff;
  height: 20px;
  width: 20px;
  line-height: 20px;
  background: #0c5adb;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
}
.style_two .singel-about-content h5 {
  font-weight: 800;
  margin-top: 10px;
  font-size: 22px;
}
.style_two .about_icon .icon {
  color: #ffffff;
  background: #0c5adb;
  border-radius: 4px 4px 4px 4px;
  height: 75px;
  width: 75px;
  font-size: 45px;
  line-height: 75px;
  text-align: center;
}

.call-do-action {
  background: url(./images/call-do-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.call-do-action-content {
  position: relative;
  z-index: 1;
}
.call-do-action-title h1 {
  font-size: 38px;
  color: #fff;
  line-height: 38px;
  font-weight: 800;
}

.call-button a {
  background: #34bbff;
  padding: 12px 27px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 44;
  border-radius: 5px;
  color: #fff;
  margin-top: 45px;
}
.call-button a:before {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background: #00247e;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: -1;
}
.call-button a i {
  font-size: 18px;
  position: relative;
  top: 3px;
}

.call-button a:hover:before {
  width: 180%;
  background-color: #2e4a71 !important;
}

.call-do-thumb {
  position: absolute;
  z-index: 1;
  bottom: 65px;
  left: 85px;
}
.call-do-thumb1 {
  position: absolute;
  z-index: 1;
  bottom: -185px;
  left: -170px;
}
.call-do-thumb2 {
  position: absolute;
  right: 135px;
  bottom: 6px;
}
.call-do-thumb3 {
  position: absolute;
  right: 28px;
  top: -12px;
}
.call-do-thumb4 {
  position: absolute;
  right: -182px;
  top: -50px;
}

.prossess-ber-plugin span {
  color: #232323;
  font-weight: 600;
  font-size: 16px;
}
.barfiller {
  width: 100%;
  height: 8px;
  background: #4b4c59;
  position: relative;
  margin-bottom: 28px;
  margin-top: 11px;
  border-radius: 5px;
  filter: drop-shadow(0px 10px 10px rgba(60, 115, 206, 0.2));
  background-color: rgba(12, 90, 219, 0.2);
}
.barfiller .fill {
  display: block;
  position: relative;
  width: 0px;
  height: 100%;
  background: #333;
  z-index: 1;
}
.barfiller .tipWrap {
  display: none;
}
.barfiller .tip {
  font-size: 16px;
  left: 0px;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
  top: -39px;
}

.stat-bar:nth-of-type(1) .stat-bar-rating {
  animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
}
span.fill {
  background: #0cd0b2 !important;
  border-radius: 5px;
}
span.fill.my-class {
  background: #0c5adb !important;
  border-radius: 5px;
}
span.fill.my-class2 {
  background: #ff6a33 !important;
  border-radius: 5px;
}

.single_slider_shape {
  position: relative;
}
.single_slider_shape_image {
  position: absolute;
  right: -250px;
  top: -499px;
  z-index: 1;
}
.single_slider_shape_image img {
  width: 90%;
}
.single_slider_rot_inner {
  position: absolute;
  bottom: -340px;
  left: 50%;
}
.single_slider_rot.two .single_slider_rot_inner {
  left: -45%;
}
.single_slider_sp {
  position: absolute;
  bottom: 370px;
  left: 79%;
}
.single_slider_sp img {
  width: 100%;
  opacity: 0.8;
}

.single_about_shape {
  position: relative;
}
.single_about_shape_thumb {
  position: absolute;
  bottom: 0px;
  z-index: -1;
}
.dropdown-toggle::after {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.filteri {
  width: 300px;
}
.headome {
  margin-bottom: 30px !important;
  margin-top: 0 !important;
}
.headome h2 {
  font-weight: 800 !important;
  margin-top: 0 !important;
}
.nh {
  border: 1px solid #366094;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0.6em 0.6em;
}
.nh a {
  font-weight: 500 !important;
}
.nh i::before {
  font-weight: 600 !important;
}
.single_about_shape_thumb img {
  width: 100%;
  opacity: 0.1;
}
.single_about_signesur_thumb {
  float: left;
}
.single_about_signesur_thumb img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}
.border_ift {
  position: relative;
  z-index: 1;
}
.border_ift:after {
  content: "";
  width: 536px;
  height: 475px;
  background: 0 0;
  position: absolute;
  bottom: 12px;
  right: -41px;
  z-index: -1;
  border: 15px solid #00000008;
  animation: bannerimage_translate 3s infinite;
  animation-play-state: running;
  animation-direction: alternate;
}
@keyframes bannerimage_translate {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  0% {
    transform: translateX(-30px) translateY(-12px);
  }
}

.video_thumb img {
  width: 100%;
}
.video_shape_thumb1 {
  position: absolute;
  top: 80px;
  z-index: -1;
  left: -16px;
}
.video_shape_thumb2 {
  position: absolute;
  top: 16px;
  right: 0;
  z-index: -1;
}
.video_shape_thumb3 {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: -1;
}
.video_shape_thumb4 {
  position: absolute;
  bottom: 83px;
  right: -135px;
  z-index: -1;
}
.main_video {
  position: relative;
  margin-top: -175px;
}
.single-panel-thumb .main_video {
  position: relative;
  margin-top: -325px;
}

.shape_thumb {
  position: relative;
}
.about_shape_thumb {
  position: absolute;
  bottom: 202px;
  right: 157px;
}
.about_shape_thumb2 {
  position: absolute;
  right: 155px;
  bottom: 70px;
}
.about_shape_thumb_affiliate1 {
  position: absolute;
  bottom: 65px;
  left: 150px;
}
.about_shape_thumb_affiliate2 {
  bottom: 443px;
  position: absolute;
  left: 77px;
}
.about_shape_thumb_affiliate2 {
  bottom: 490px;
  position: absolute;
  left: 45px;
}

.about_icon_box_inner i {
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #0c5adb;
  text-align: center;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
  margin-right: 12px;
}
.about_icon_box_inner span {
  font-size: 16px;
  font-weight: 700;
  color: #232323;
}

.service_style_one {
  background: #fff;
  border-radius: 10px;
}
.service_style_one_icon i {
  width: 75px;
  height: 75px;
  line-height: 75px;
  background: #e8ecfc;
  border-radius: 100%;
  color: #0c5adb;
  display: inline-block;
  border-radius: 33% 66% 70% 30%/49% 62% 38% 51%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  font-size: 35px;
}
.service_style_one_title h4 {
  font-weight: 700;
  color: #0c5adb;
  position: relative;
}
.service_style_one_title h4:before {
  position: absolute;
  content: "";
  left: 0;
  top: 37px;
  height: 1px;
  width: 100px;
  background: #d1d1d1;
  margin: auto;
  right: 0;
}
.service_style_one_button a {
  color: #616161;
  border: 1px solid #e8ecfc;
  padding: 5px 23px;
  border-radius: 30px;
  display: inline-block;
  font-size: 16px;
}
.service_style_one_button a i {
  margin-left: 7px;
}
.service_style_one:hover .service_style_one_button a {
  background-color: #0c5adb;
  color: #fff;
  border-color: #0c5adb;
}
.service_style_one:hover .service_style_one_icon i {
  background-color: #0c5adb;
  color: #fff;
}

.service_style_two_number {
  float: left;
}
.service_style_two_content {
  overflow: hidden;
}
.service_style_two_number h5 {
  font-weight: 700;
  color: #0c5adb;
  position: relative;
}
.service_style_two_number h5:before {
  position: absolute;
  left: 34px;
  top: 14px;
  height: 2px;
  width: 32px;
  content: "";
  background: #0c5adb;
}
.service_style_two_title h4 {
  font-weight: 700;
  transition: 0.3s;
}
.service_style_two_button a {
  color: #0c5adb;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.service_style_two_button a i {
  margin-left: 5px;
  transition: 0.3s;
}
.service_style_two_title h4:hover {
  color: #0c5adb;
}

.service_style_three {
  background: #fff;
  border-radius: 7px;
  box-shadow: 2px 10px 50px rgba(158, 158, 158, 0.25);
  border: 2px solid transparent;
  transition: 0.5s;
}
.service_style_three_icon i {
  width: 95px;
  height: 95px;
  line-height: 95px;
  background: #0c5adb;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  font-size: 30px;
}
.service_style_three_title h4 {
  font-size: 22px;
  font-weight: 700;
  transition: 0.3s;
}
.service_style_three_bt_icon {
  position: relative;
  margin-bottom: -28px;
}
.service_style_three_bt_icon a {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  border: 2px solid #0c5adb;
  line-height: 50px;
  color: #0c5adb;
  background: #fff;
  transition: 0.5s;
  display: inline-block;
}
.service_style_three:hover {
  border: 2px solid #0c5adb;
}
.service_style_three_title h4:hover {
  color: #0c5adb;
}
.service_style_three:hover .service_style_three_bt_icon a {
  background: #0c5adb;
  color: #fff;
}

.service_style_four {
  background: #fff;
  border-radius: 7px;
  transition: 0.5s;
  box-shadow: 2px 10px 50px rgba(158, 158, 158, 0.25);
}
.service_style_four_icon i {
  font-size: 48px;
  display: inline-block;
  color: #0c5adb;
  transition: 0.5s;
}
.service_style_four_title h4 {
  transition: 0.5s;
  font-size: 22px;
  font-weight: 700;
}
.service_style_four_text p {
  transition: 0.5s;
}
.service_style_four_button a {
  color: #0c5adb;
  transition: 0.5s;
}
.service_style_four_button a i {
  margin-left: 5px;
}
.service_style_four:hover {
  background: #0c5adb;
  transform: translateY(-8px);
}
.service_style_four:hover .service_style_four_icon i,
.service_style_four:hover .service_style_four_title h4,
.service_style_four:hover .service_style_four_text p,
.service_style_four:hover .service_style_four_button a {
  color: #fff;
}

.service_style_five {
  border: 1px solid #d5c7c7;
  border-radius: 8px;
  background: transparent;
  transition: 0.5s;
}
.service_style_five_title h4 {
  font-size: 22px;
  font-weight: 700;
  transition: 0.5s;
}
.service_style_five_icon i {
  transition: 0.5s;
}
.service_style_five_text p {
  transition: 0.5s;
}
.service_style_five_button a {
  transition: 0.5s;
}
.service_style_five_button a i {
  margin-left: 5px;
}
.service_style_five:hover {
  border: 1px solid #0c5adb;
  background: #0c5adb;
  transform: translateY(-8px);
}
.service_style_five:hover .service_style_five_title h4,
.service_style_five:hover .service_style_five_icon i,
.service_style_five:hover .service_style_five_text p,
.service_style_five:hover .service_style_five_button a {
  color: #fff;
}

.single_service_inner {
  padding: 19px 0 5px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 1;
  transition: 0.5s;
  border-radius: 0 50px 50px 50px;
  margin-bottom: 30px;
  background: #fff;
}
.single_service_inner::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60px;
  background: #0c5adb;
  content: "";
  z-index: -1;
  border-radius: 0 30px 30px;
  transition: 0.5s;
  border: 1px transparent;
}
.single_service_icon {
  float: left;
  margin-left: 15px;
  margin-right: 30px;
  margin-top: -5px;
}
.single_service_icon i {
  display: inline-block;
  text-align: center;
  font-size: 25px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  color: #fff;
}
.single_service_content h4 {
  font-size: 16px;
  text-align: left;
  margin: 0 0 12px;
  transition: 0.5s;
}
.right .single_service_inner_thumb {
  margin-top: -130px;
  margin-left: 115px;
}
.right .single_service_brg {
  position: relative;
}
.right .single_service_brg_thumb {
  bottom: -66px;
  position: absolute;
  left: 25px;
}
.left .single_service_inner_thumb {
  margin-top: -144px;
  margin-left: 71px;
}
.left .single_service_brg {
  position: relative;
}
.left .single_service_brg_thumb {
  bottom: -66px;
  position: absolute;
  left: -16px;
}
.single_service_inner:hover .single_service_content h4 {
  color: #fff;
}
.single_service_inner:hover {
  background: #0c5adb;
}
.single_service_inner:hover::before {
  background: #fff;
}
.single_service_inner:hover .single_service_icon i {
  color: #0c5adb;
}

.service_style_seven {
  box-shadow: 0 0 15px rgba(51, 51, 51, 0.1);
  transition: 0.5s;
}
.service_style_seven_icon {
  float: left;
}
.service_style_seven_icon i {
  color: #0c5adb;
  font-size: 40px;
  margin-top: 14px;
}
.service_style_seven_content {
  overflow: hidden;
}
.service_style_seven_title h4 {
  font-weight: 700;
}
.service_style_seven:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}
.service_style_eight {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 35px 25px 33px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  overflow: hidden;
}
.service_style_eight::before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 50px;
  width: 50px;
  background: #0c5adb;
  border-radius: 0 0 5px 0;
  z-index: -1;
  transition: 0.5s;
}
.service_style_eight:hover::before {
  height: 100%;
  width: 100%;
}
.service_style_eight_icon .icon i {
  color: #fff;
}
.service_style_eight_content h4 {
  padding-bottom: 10px;
  font-size: 22px;
}
.service_style_eight_content a {
  color: #fff;
}
.service_style_nine {
  background: #fff;
  padding: 60px 40px 34px;
  box-shadow: 0 20px 50px 5px #e9eef7;
  border-radius: 10px;
  margin-bottom: 30px;
  transition: 0.5s;
}
.service_style_nine:hover {
  box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
}

.services_style_nine_icon .icon {
  height: 100px;
  width: 100px;
  line-height: 100px;
  font-size: 50px;
  color: #0c5adb;
  position: relative;
  text-align: center;
  box-shadow: 0 20px 50px 5px #e9eef7;
  border-radius: 50%;
  z-index: 1;
  background: #fff;
  transition: 0.5s;
}
.services_style_nine_icon .icon:before {
  position: absolute;
  content: "";
  background: rgba(12, 90, 219, 0.1);
  width: 100%;
  height: 100%;
  left: -8px;
  top: -5px;
  z-index: -1;
  border-radius: 50%;
  box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.service_style_nine:hover .services_style_nine_icon .icon {
  color: #fff;
}
.service_style_nine:hover .services_style_nine_icon .icon:before {
  background: rgba(12, 90, 219, 0.8);
}

.single_service_style_ten {
  box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
  background: #fff;
  padding: 30px 30px 15px;
  position: relative;
  transition: 0.5s;
  margin-bottom: 30px;
}
.single_service_style_ten::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 3px;
  width: 20%;
  background: #0c5adb;
  transition: 0.5s;
}
.single_service_style_ten:hover:before {
  width: 100%;
}
.single_service_style_ten:hover {
  transform: translateY(-10px);
}
.single_service_style_ten:hover .service_style_ten_icon .icon {
  background: rgba(12, 90, 219, 0.9);
  color: #fff;
}
.service_style_ten_content h4 a {
  font-size: 22px;
  font-weight: 700;
}
.service_style_ten_icon .icon {
  background: rgba(12, 90, 219, 0.1);
  display: inline-block;
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 35px;
  color: #0c5adb;
  border-radius: 5px;
  transition: 0.5s;
}

.single_service_style_eleven {
  box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
  padding: 35px 30px 30px;
  margin-bottom: 30px;
  border-radius: 0 40px;
  position: relative;
  z-index: 1;
}
.single_service_style_eleven::before {
  position: absolute;
  left: 0;
  content: "";
  height: 0%;
  width: 100%;
  background: #0c5adb;
  bottom: 0;
  z-index: -1;
  border-radius: 0 40px;
  transition: 0.5s;
}
.service_style_eleven_icon {
  float: left;
  margin-right: 15px;
}
.service_style_eleven_icon .icon {
  background: rgba(12, 90, 219, 0.1);
  height: 58px;
  width: 58px;
  line-height: 58px;
  font-size: 28px;
  text-align: center;
  border-radius: 50%;
  color: #0c5adb;
  transition: 0.5s;
}
.service_style_eleven_title h4 {
  transition: 0.5s;
  font-size: 21px;
}
.service_style_eleven_text p {
  transition: 0.5s;
}
.servic_style_eleven_button a {
  transition: 0.5s;
}
.single_service_style_eleven:hover .service_style_eleven_title h4,
.single_service_style_eleven:hover .service_style_eleven_text p,
.single_service_style_eleven:hover .servic_style_eleven_button a {
  color: #fff;
}
.single_service_style_eleven:hover .service_style_eleven_icon .icon {
  transform: rotate(360deg);
}
.single_service_style_eleven:hover::before {
  height: 100%;
}
.single_service_style_eleven:hover .service_style_eleven_icon .icon {
  background: #fff;
}

.service_style_12 {
  overflow: hidden;
  border: 1px solid #eee;
  border-bottom: 3px solid #0c5adb;
  border-radius: 5px;
}
.service_style_12_thumb img {
  width: 100%;
}
.service_style12_content {
  padding: 0 20px 20px;
}
.service_style_12_content_inner h2 {
  font-size: 22px;
  font-weight: 700;
}
.service_style_12_icon {
  position: relative;
  margin-top: -40px;
}
.service_style_12_icon .icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: #0c5adb;
  color: #fff;
  text-align: center;
  font-size: 40px;
  border-radius: 4px;
  display: block;
}
.service_style_12_content_inner {
  padding-top: 15px;
}
.service_style_12_content_inner a {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
}

.owl-dots {
  text-align: center;
  position: relative;
  right: 0;
  left: 0;
  cursor: pointer;
}
.owl-dot.active {
  background: #0c5adb;
  width: 40px;
}
.owl-dot {
  height: 6px;
  width: 30px;
  background: #ccc;
  line-height: 30px;
  display: inline-block;
  margin-right: 12px;
  border-radius: 103px;
  transition: 0.5s;
}

.service-area {
  background: url(./images/service-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.em-service-single-box {
  height: 320px;
  border-radius: 5px;
  filter: drop-shadow(0px 5px 30px rgba(162, 188, 231, 0.1));
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
}
.em-service-single-box:after {
  position: absolute;
  content: "";
  z-index: -1;
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: url(./images/box-bg2.png);
  transition: 0.5s;
}
.em-service-box-inner {
  position: relative;
  z-index: 1;
}
.em-service-box-inner:before {
  position: absolute;
  content: "";
  width: 370px;
  height: 1px;
  border-radius: 1px;
  background-color: rgba(35, 35, 35, 0.07058823529411765);
  bottom: 41px;
  left: 0;
  transition: 0.5s;
}

.em-service-content {
  padding: 40px 40px 0;
}

.em-icon {
  float: left;
  margin-right: 10px;
}
.em-icon-title h4 {
  font-size: 15px;
  color: #0c5adb;
  display: inline-block;
  font-weight: 500;
  transform: 0.5s;
}

.em-service-title h2 {
  font-size: 24px;
  line-height: 32px;
  padding: 15px 0 15px;
  transition: 0.5s;
}
.em-service-text p {
  transition: 0.5s;
}

.em-service-button {
  margin: 40px 0 0;
}
.em-service-button a {
  display: inline-block;
  color: #0c5adb;
  text-decoration: underline;
  transition: 0.5s;
}
.em-service-button a i {
  font-size: 28px;
  position: relative;
  top: 7px;
}

.em-service-single-box:hover:after {
  left: 0;
  width: 100%;
}
.em-service-single-box:hover .em-icon-title h4,
.em-service-single-box:hover .em-service-title h2,
.em-service-single-box:hover .em-service-text p,
.em-service-single-box:hover .em-service-button a {
  color: #fff;
}
.em-service-single-box:hover .em-service-box-inner:before {
  background-color: rgba(255, 255, 255, 0.1019607843137254);
}

.service-area.style-four {
  background: inherit;
}
.em-service-single-box1 {
  height: 345px;
  border-radius: 5px;
  filter: drop-shadow(0px 5px 30px rgba(162, 188, 231, 0.3));
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: center;
}
.em-service-single-box1:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: #0b59db;
  z-index: -1;
  transition: 0.5s;
}
.em-service-title1 h2 {
  font-size: 22px;
  line-height: 32px;
  padding: 16px 0 10px;
  transition: 0.5s;
}
.em-service-text1 p {
  transition: 0.5s;
  line-height: 30px;
}

.em-icon1 {
  position: relative;
  z-index: 1;
}
.em-icon1:before {
  position: absolute;
  content: "";
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(./images/t2.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.upper1.em-service-single-box1:before {
  background: #01cb99;
}
.upper2.em-service-single-box1:before {
  background: #ea599e;
}
.upper3.em-service-single-box1:before {
  background: #ff6a01;
}
.upper4.em-service-single-box1:before {
  background: #764ae5;
}

.active.em-service-single-box1 {
  background-color: #0c5adb;
}
.active .em-service-title1 h2 {
  color: #fff;
}
.active .em-service-text1 p {
  color: #fff;
}

.em-service-single-box1:hover:before {
  height: 100%;
  top: 0;
}
.em-service-single-box1:hover .em-service-title1 h2,
.em-service-single-box1:hover .em-service-text1 p {
  color: #fff;
}

.service-details-pn-list ul li {
  margin-bottom: 2px;
  transition: 0.5s;
  list-style: none;
}
.service-details-pn-list ul li a {
  position: relative;
  display: block;
  padding: 14px 15px 14px 18px;
  background-color: #f3f6f9;
  font-weight: 600;
  font-size: 17px;
  transition: 0.5s;
  color: #232323;
}
.service-details-pn-list ul li a:hover {
  background: #2e4a71 !important;
  color: #fff !important;
}
.service-details-pn-list ul li a i {
  float: right;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  margin-top: 5px;
}
.service-details-big-button a {
  color: #ffffff;
  background: #0c5adb;
  font-weight: 500;
  text-transform: capitalize;
  border: 0px solid #ffffff;
  border-radius: 5px;
  padding: 14px 35px 14px 35px;
  display: block;
}
.service-details-big-button a:hover {
  background: #2e4a71;
}

.details-big-content {
  text-align: center !important;
}
.service-details-big-button a span i {
  margin-left: 20px;
}
.service-details-pn-about {
  position: relative;
  z-index: 1;
}
.service-details-pn-about::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(12, 90, 219, 0.85);
  z-index: -1;
}
.service-details-pn-about-content {
  text-align: center;
}
.service-main-details-content-title h3 {
  font-weight: 800;
}
.service-details-pn-about-content-title h4 {
  color: #fff;
  font-weight: 700;
}
.service-details-pn-about-content-text p {
  color: #fff;
}
.service-details-pn-about-content-button a {
  display: inline-block;
  padding: 10px 30px;
  background: #fff;
  color: #0c5adb;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
}

.service-main-details-inner-thumb img {
  width: 100%;
}
.service-details-video-thumb img {
  width: 100%;
}
.service-details-video .single-video {
  position: relative;
}
.service-details-video .video-icon {
  position: absolute;
  left: 0;
  bottom: 265px;
  right: 0;
}
.service-details-research-button a {
  color: #ffffff;
  background: #0c5adb;
  font-weight: 500;
  text-transform: capitalize;
  border: 0px solid #ffffff;
  border-radius: 5px 5px 5px 5px;
  padding: 14px 35px 14px 35px;
  margin-top: 15px;
  transition: 0.5s;
}
.service-details-research-button a:hover {
  background: #2e4a71;
}
.service-details-research-title h4 {
  font-weight: 800;
}

.single_team {
  overflow: hidden;
  border-radius: 5px;
  transition: 0.5s;
}
.single_team_thumb {
  position: relative;
}
.single_team_thumb:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 90, 219, 0.85);
  content: "";
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.single_team_icon {
  position: absolute;
  top: 50%;
  right: -50px;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: #fff;
  width: 45px;
  padding: 13px 0;
  opacity: 0;
}
.single_team_icon a {
  display: block;
  font-size: 16px;
  color: #0c5adb;
  text-align: center;
  margin: 4px 0px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.single_team_thumb img {
  width: 100%;
}
.single_team_content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #fff;
  padding: 12px 20px 23px;
  text-align: center;
  border-bottom: 2px solid #0c5adb;
}
.single_team_content h4 {
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s;
  display: block;
}
.single_team_content span {
  color: #0c5adb;
  padding-top: 2px;
}

.single_team:hover {
  transform: translateY(-10px);
}
.single_team:hover .single_team_icon {
  opacity: 1;
  right: -1px;
}
.single_team_content h4:hover {
  color: #0c5adb;
}
.single_team:hover .single_team_thumb:before {
  opacity: 1;
}

.team_style_three {
  position: relative;
  transition: 0.5s;
}
.team_style_three-thumb {
  position: relative;
  z-index: 1;
  transition: 0.5s;
}
.team_style_three_thumb_inner {
  position: relative;
  z-index: 1;
  transition: 0.5s;
  overflow: hidden;
}
.team_style_three_thumb_inner:before {
  position: absolute;
  left: -70px;
  bottom: -59px;
  height: 120px;
  width: 133%;
  background: #fff;
  content: "";
  -webkit-transform: skew(-30deg);
  -khtml-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  transform: skew(-98deg);
}
.team_style_three-thumb:after {
  position: absolute;
  left: 0;
  bottom: 4px;
  height: 100%;
  width: 100%;
  background: #0c5adb;
  content: "";
  transform: skew(1deg);
  -webkit-transform: skew(1deg);
  -khtml-transform: skew(69deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}
.team_style_three_thumb_inner img {
  width: 100%;
}
.team_style_three_content {
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 18px;
  z-index: 1;
  padding: 15px 10px 35px 50px;
  transition: 0.5s;
}
.team_style_three_content:before {
  background: #0c5adb;
  transition: 0.5s;
}

.team_style_three_content:before,
.team_style_three_content:after {
  content: "";
  top: 0;
  left: 0;
  right: 50px;
  bottom: 0;
  position: absolute;
  z-index: -2;
  -webkit-transform: skew(-30deg);
  -khtml-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  transform: skew(-30deg);
  transform-origin: left bottom;
  -webkit-transform-origin: left bottom;
  -webkit-transition: all 0.2s linear 0ms;
  -khtml-transition: all 0.2s linear 0ms;
  -moz-transition: all 0.2s linear 0ms;
  -ms-transition: all 0.2s linear 0ms;
  -o-transition: all 0.2s linear 0ms;
  transition: all 0.2s linear 0ms;
}

.team_style_three_title h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.team_style_three_sub_title {
  transition: 0.5s;
  position: absolute;
  left: 51px;
  top: 40px;
}
.team_style_three_sub_title span {
  font-size: 14px;
  color: #fff;
  transition: 0.5s;
}
.team_style_three_icon a {
  color: #fff;
  display: inline-block;
  margin: 5px;
}
.team_style_three_icon {
  opacity: 0;
  transition: 0.5s;
  position: absolute;
  left: 47px;
  top: 50px;
}
.team_style_three:hover .team_style_three_icon {
  opacity: 1;
  top: 35px;
}
.team_style_three:hover .team_style_three-thumb:after {
  opacity: 1;
  -webkit-transform: rotate(-10deg);
  -webkit-transform: skew(1deg);
  transform: rotate(-10deg);
}
.team_style_three:hover .team_style_three_sub_title {
  margin-top: -20px;
  opacity: 0;
}
.team_style_three:hover .team_style_three_content:before {
  background: #00247e;
  transition: 0.5s;
}

.single_team_style_three {
  margin-bottom: 30px;
}
.single_team_style_three_thumb {
  position: relative;
}
.single_team_style_three_thumb img {
  width: 100%;
}
.single_team_style_three_icon {
  position: absolute;
  top: 90%;
  right: 0;
  left: 0;
  text-align: center;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 83%;
  background: #0c5adb;
  margin: 0 30px;
  margin: auto;
  padding: 12px 0;
  transition: 0.5s;
  opacity: 0;
}
.single_team_style_three_content {
  text-align: center;
  padding: 10px 0 22px;
  -webkit-box-shadow: 0 16px 32px rgba(207, 228, 255, 0.8);
  box-shadow: -1 16px 32px rgba(207, 228, 255, 0.8);
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  z-index: 1;
}
.single_team_style_three:hover .single_team_style_three_icon {
  top: 82%;
  opacity: 1;
}
.single_team_style_three_icon i {
  color: #fff;
  margin: 0 5px;
}

.single_software_box {
  margin-bottom: 20px;
}
.single_software_box_content {
  background: #eff2f7;
  padding: 8px 15px 20px 17px;
  position: relative;
  z-index: 1;
  border-radius: 3px;
}
.single_software_box_content::before {
  position: absolute;
  left: 0;
  content: "";
  bottom: 0;
  height: 0%;
  width: 100%;
  background: #0c5adb;
  z-index: -1;
  transition: 0.5s;
  border-radius: 3px;
}
.single_software_box:hover .single_software_box_content::before {
  height: 100%;
}
.single_software_box_content h5 {
  font-size: 17px;
}
.single_software_box:hover .single_software_box_content h5 {
  color: #fff;
}
.software_thumb_inner img {
  width: 100%;
}
.software_shape_thumb_inner1 {
  position: absolute;
  bottom: 0;
  left: 45px;
}
.software_shape_thumb_inner1 img {
  width: 40%;
}
.software_shape_thumb_inner2 {
  position: absolute;
  bottom: -35px;
  left: 340px;
}
.software_shape_thumb_inner2 img {
  width: 85%;
}
.software_shape_thumb_inner3 {
  position: absolute;
  top: -49px;
  right: 0;
}
.software_shape_thumb_inner4 {
  position: absolute;
  top: -74px;
  left: 104px;
}

.single_case_study {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  transition: 0.5s;
}
.single_case_study_thumb {
  transform: scale(1.01);
  transition: 0.5s;
}
.pdn_0 {
  padding: 0;
}
.single_case_study_thumb::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(12, 90, 219, 0.85);
  opacity: 0;
  transition: 0.5s;
  border: 3px solid #fff;
  right: 0;
  bottom: 0;
  margin: auto;
}
.single_case_study_thumb img {
  width: 100%;
}
.single_case_study_content {
  position: absolute;
  left: 0;
  top: 70%;
  right: 0;
  text-align: center;
  background: #fff;
  width: 80%;
  margin: auto;
  border-radius: 5px;
  padding: 20px 10px;
  opacity: 0;
  transition: 0.5s;
  transform: translateY(-50%);
  z-index: 1;
}
.single_case_study_icon {
  text-align: center;
  padding-bottom: 10px;
}
.single_case_study_content_inner {
  text-align: center;
}
.single_case_study_content_inner h2 {
  font-size: 22px;
}
.single_case_study_content_inner h2 a {
  display: inline-block;
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  transition: 0.5s;
}
.single_case_study_icon i {
  background: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  border-radius: 5px;
  color: #0c5adb;
  transition: 0.5s;
}

.single_case_study:hover .single_case_study_thumb {
  transform: scale(1.03);
}
.single_case_study:hover .single_case_study_content {
  opacity: 1;
  top: 50%;
}
.single_case_study:hover .single_case_study_thumb::before {
  opacity: 1;
}
.single_case_study_content_inner h2 a:hover {
  color: #0c5adb;
}
.single_case_study_icon i:hover {
  background: #2e4a71;
  color: #fff;
}

.case_study_style_two {
  position: relative;
}
.case_study_style_two_thumb {
  position: relative;
  z-index: 1;
}
.case_study_style_two_thumb:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 90, 219, 0.65);
  content: "";
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 10px;
}
.case_study_style_two_thumb img {
  width: 100%;
  border-radius: 10px;
}
.case_study_style_two_content {
  position: absolute;
  left: 0;
  top: 80%;
  right: 0;
  background: transparent;
  width: 100%;
  margin: auto;
  border-radius: 5px;
  padding: 0 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  text-align: left;
}
.case_study_style_two_title h4 a {
  color: #fff;
  font-weight: 700;
  display: block;
}
.case_study_style_two_title span {
  color: #fff;
  font-weight: 600;
}
.case_study_style_two_button a {
  color: #fff;
}
.case_study_style_two:hover .case_study_style_two_content {
  top: 70%;
}

.three .case_study_style_two_content {
  top: 75%;
}
.three .case_study_style_two:hover .case_study_style_two_content {
  top: 60%;
}
.three .case_study_style_two_title h4 {
  font-size: 20px;
}

.four .single_case_study {
  overflow: inherit !important;
  margin-bottom: 65px;
}
.four .single_case_study_inner {
  overflow: hidden;
}
.four .single_case_study_content {
  bottom: -100px !important;
  background: #00247e;
  opacity: 1;
  transition: 0.5s;
  top: inherit;
}
.four .single_case_study:hover .single_case_study_content {
  bottom: -100px !important;
  top: inherit;
}
.four .single_case_study_inner {
  overflow: hidden;
}
.four .single_case_study_content_inner h2 a {
  color: #fff;
}
.four .single_case_study_content_inner span {
  color: #fff;
}

.single-case-study-details {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 50px;
}
.case-study-details-title h3 {
  font-weight: 800;
}
.case-study-details-content-inner ul li span {
  font-size: 20px;
  margin-right: 10px;
  font-weight: 700;
}
.case-study-details-content-inner ul li {
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  font-weight: 300;
  padding: 13px 0;
  text-transform: capitalize;
  list-style: none;
}
.case-study-details-content-inner ul li:last-child {
  border-bottom: none;
}
.case-study-details-icon-text span {
  font-size: 20px;
  margin-right: 10px;
  font-weight: 700;
  float: left;
}
.case-study-details-icon-inner a {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: #0c5adb;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  margin-right: 10px;
  line-height: 35px;
}
.case-study-details-full-title h3 {
  font-weight: 800;
}
.case-study-details-thumb img {
  width: 100%;
}

.portfolio_nav {
  margin-bottom: 50px;
}
.portfolio_menu ul {
  text-align: center;
  list-style: none;
}
.portfolio_menu ul li {
  display: inline-block;
  margin: 0 14px;
  cursor: pointer;
}
.portfolio_menu ul li a {
  display: block;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  transition: 0.5s;
}
.portfolio_menu ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 24px;
  height: 2px;
  width: 0%;
  background: #fff;
  transition: 0.5s;
}
.portfolio_nav ul li {
  background: #f2f4ff;
  border: medium none;
  font-size: 16px;
  line-height: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: capitalize;
  padding: 12px 27px;
  font-weight: 500;
  letter-spacing: 2px;
  position: relative;
  margin: 0 3px;
  margin-bottom: 7px;
  display: inline-block;
  border-radius: 5px;
}
.portfolio_nav ul li:hover,
.portfolio_nav ul li.current_menu_item {
  color: #fff;
  background: #0c5adb;
}

.single_portfolio {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  transition: 0.5s;
}
.single_portfolio_thumb {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: 0.5s;
}
.single_portfolio_content {
  position: absolute;
  text-align: center;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  right: 20px;
  bottom: 20px;
  left: 20px;
  opacity: 0;
  background: #0c5adb;
  margin: auto;
  text-align: left;
  padding: 30px 20px 30px;
  border-radius: 5px;
}
.single_portfolio_icon {
  text-align: center;
  padding-bottom: 10px;
}
.single_portfolio_content_inner {
  text-align: center;
}
.single_portfolio_content_inner h2 {
  font-size: 22px;
  margin: 0;
}
.single_portfolio_content_inner h2 a {
  display: inline-block;
  overflow: hidden;
  font-weight: 600;
  color: #fff;
}
.single_portfolio_icon i {
  background: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  border-radius: 5px;
  color: #135ef3;
  transition: 0.5s;
}
.single_portfolio_thumb img {
  width: 100%;
}
.single_portfolio_content_inner span {
  color: #fff;
}

.single_portfolio:hover .single_portfolio_thumb {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.portfolio_menu ul li a:hover:before {
  width: 100%;
}
.single_portfolio:hover .single_portfolio_content {
  opacity: 1;
  bottom: 35px;
}
.single_portfolio_icon i:hover {
  background: #fff;
  color: #135ef3;
}

.style_two .single_portfolio_thumb {
  position: relative;
  z-index: 1;
}
.style_two .single_portfolio_thumb:after {
  content: "";
  background: rgba(12, 90, 219, 0.85);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  transform-origin: top;
  -moz-transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  -moz-transform: perspective(15em) rotateX(-90deg);
  -o-transform: perspective(15em) rotateX(-90deg);
  -ms-transform: perspective(15em) rotateX(-90deg);
  -webkit-transform: perspective(15em) rotateX(-90deg);
  transform: perspective(15em) rotateX(-90deg);
  z-index: 1;
  left: 0;
}
.style_two .single_portfolio_content_inner h2 a {
  font-weight: 700;
  color: #232323;
}
.style_two .single_portfolio_content_inner span {
  color: #135ef3;
}
.style_two .single_portfolio_icon i {
  background: #135ef3;
  color: #fff;
}
.style_two .single_portfolio_content {
  z-index: 1;
  background: #fff;
}
.style_two .single_portfolio:hover .single_portfolio_thumb:after {
  -moz-transform: perspective(0) rotateX(0);
  -o-transform: perspective(0) rotateX(0);
  -ms-transform: perspective(0) rotateX(0);
  -webkit-transform: perspective(0) rotateX(0);
  transform: perspective(0) rotateX(0);
}

.style_three .single_portfolio_thumb {
  position: relative;
}
.style_three .single_portfolio_thumb::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 0%;
  width: 100%;
  background: rgba(12, 90, 219, 0.75);
  transition: 0.5s;
}
.style_three .single_portfolio_content {
  right: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  text-align: left;
  padding: 0;
}
.style_three .single_portfolio:hover .single_portfolio_thumb::before {
  height: 100%;
}

.portfolio_area.style-four {
  overflow: hidden;
}

.style-four .portfolio_nav ul li {
  background: #f2f4ff;
  border: medium none;
  font-size: 16px;
  line-height: 1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: capitalize;
  padding: 8px 15px;
  font-weight: 500;
  letter-spacing: 2px;
  position: relative;
  margin: 78px 3px;
  margin-bottom: 7px;
  display: inline-block;
  border-radius: 2px;
  filter: drop-shadow(0px 5px 15px rgba(152, 179, 223, 0.2));
  background-color: #ffffff;
}
.style-four .portfolio_nav ul li:hover,
.portfolio_nav ul li.current_menu_item {
  color: #fff;
  background: linear-gradient(to left, #2475fc, #1129b9);
}

.style-four .single_portfolio {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  transition: 0.5s;
  border-radius: 10px;
  filter: drop-shadow(0 0 20px rgba(170, 192, 228, 0.3));
  background-color: #ffffff;
  padding: 20px 20px 22px;
}

.single_portfolio_thumb1 {
  position: relative;
  z-index: 1;
}
.single_portfolio_thumb1:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-radius: 10px;
  background-color: rgba(4, 96, 248, 0.6);
  transition: 0.8s;
}
.style-four .single_portfolio_thumb1 img {
  width: 100%;
}

.style-four .single_portfolio_content_inner {
  text-align: left;
  padding: 20px 0 0;
}

.style-four .single_portfolio_icon {
  text-align: center;
  padding-bottom: 10px;
  position: absolute;
  top: 0%;
  left: 33%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: 0.5s;
}
.style-four .single_portfolio_icon a i {
  display: inline-block;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  color: #132fbf;
}

.style-four .single_portfolio_content_inner h2 a {
  display: inline-block;
  overflow: hidden;
  font-weight: 700;
  color: #232323;
  line-height: 30px;
  padding: 7px 0 0px;
}

.style-four .port-icon-thumb {
  float: left;
  margin-right: 9px;
}
.style-four .port-icon-title p {
  color: #0c5adb;
  font-weight: 600;
  margin: 0;
}

.style-four .single_portfolio:hover .single_portfolio_content_inner h2 a {
  color: #0c5adb;
  text-decoration: underline;
}
.style-four .single_portfolio:hover .single_portfolio_thumb1:before {
  height: 100%;
  top: 0;
}
.style-four .single_portfolio:hover .single_portfolio_icon {
  opacity: 1;
  top: 39%;
}

.single_it_work {
  text-align: center;
}
.single_it_work_content_list {
  position: relative;
}
.single_it_work_content_list::before {
  position: absolute;
  right: -75px;
  top: 26px;
  height: 1px;
  width: 130px;
  content: "";
  background: #ddd;
}
.single_it_work_content_list.three::before {
  display: none;
}
.single_it_work_content_list span {
  background: #366196;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  position: relative;
  height: 45px;
  width: 45px;
  line-height: 45px;
  display: inline-block;
  transition: 0.3s;
}
.single_work_content_title h4 {
  font-size: 20px;
  font-weight: 700;
}
.single_it_work_content_list span:after {
  position: absolute;
  left: -10px;
  top: -10px;
  height: 65px;
  width: 65px;
  content: "";
  background: #366196;
  z-index: -1;
  border-radius: 50%;
  right: 0;
  margin: auto;
  opacity: 0.4;
  transition: 0.3s;
}
.single_it_work_content_list span:before {
  position: absolute;
  left: -20px;
  top: -20px;
  height: 85px;
  width: 85px;
  content: "";
  background: #366196;
  z-index: -1;
  border-radius: 50%;
  right: 0;
  margin: auto;
  opacity: 0.2;
  transition: 0.3s;
}
.single_it_work:hover .single_it_work_content_list span,
.single_it_work:hover .single_it_work_content_list span:before,
.single_it_work:hover .single_it_work_content_list span:after {
  transform: scale(1.1);
}

.how_it_work.odoo .custom-container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}
.how_it_work.odoo .single_it_work {
  text-align: center;
  padding: 0 11px;
  margin-bottom: 30px;
}
.how_it_work.odoo .work_progress_icon i {
  background: linear-gradient(
    90deg,
    rgba(30, 85, 189, 1) 0%,
    rgba(67, 126, 235, 1) 100%
  );
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  font-size: 50px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  width: 120px;
  z-index: 1;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: inline-block;
}
.how_it_work.odoo .work_progress_icon {
  position: relative;
  z-index: 1;
  margin-bottom: 127px;
}
.how_it_work.odoo .work_progress_icon::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  border: 2px solid #ddd;
  border-radius: 100%;
  bottom: -32px;
}
.how_it_work.odoo .work_progress_icon::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: auto;
  width: 2px;
  height: 40px;
  border-left: 2px dashed #ddd;
  border-radius: 100%;
  bottom: -74px;
}
.how_it_work.odoo .single_it_work_content_list span:before,
.how_it_work.odoo .single_it_work_content_list span:after {
  display: none;
}
.how_it_work.odoo .single_it_work_content_list span {
  border-radius: 50%;
  font-weight: 600;
  position: absolute;
  top: 0;
  width: 45px;
  line-height: 45px;
  display: inline-block;
  transition: 0.3s;
  background: #fff;
  border-radius: 50%;
  color: #0c5adb;
  box-shadow: 0 0 10px #cccccc;
}
.how_it_work.odoo .single_it_work_content_list {
  position: absolute;
  top: 0;
  right: 0;
  left: 53%;
  transform: translate(-50%);
}
.how_it_work.odoo .single_it_work::after {
  position: absolute;
  right: 0;
  top: 10px;
  content: "";
  background-image: url(./images/direction-sign.png);
  height: 40px;
  width: 200px;
  background-repeat: no-repeat;
  margin-right: -119px;
  background-size: contain;
}
.how_it_work.odoo .single_it_work_content_list::before {
  display: none;
}
.how_it_work.odoo .single_it_work.last:after {
  display: none;
}
.how_it_work.odoo .section_main_title h1,
.section_main_title h2 {
  font-size: 39px;
}

.it_work_style_two .techno_flipbox .techno_flipbox_font,
.it_work_style_two .techno_flipbox .techno_flipbox_back {
  min-height: 410px;
}
.it_work_style_two .it_work_style_two .techno_flipbox .techno_flipbox_inner {
  top: 50%;
}
.techno_flipbox_font .icon {
  color: #fff;
  font-size: 30px;
  line-height: 80px;
  height: 80px;
  width: 80px;
  margin-top: 30px;
  background: #376196;
  border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
  display: inline-block;
}
.it_work_style_two .techno_flipbox_font {
  background: #ffffff;
  border: 2px solid #e8ecfc;
  border-radius: 7px 7px 7px 7px;
}
.it_work_style_two .techno_flipbox_font .flipbox_title h3 {
  color: #444;
  font-weight: 700;
  font-size: 24px;
}
.it_work_style_two .techno_flipbox_font .flipbox_desc p {
  color: #666;
}
.it_work_style_two .techno_flipbox .techno_flipbox_back .flipbox_button a {
  background: inherit;
  color: #fff;
  display: inline-block;
  padding: 0;
  border-radius: inherit;
  border: inherit;
  font-size: 18px;
  margin-top: 5px;
  font-weight: 600;
}
.it_work_style_two .techno_flipbox .techno_flipbox_back::before {
  background: inherit;
}

.style_three .single_how_it_work {
  position: relative;
}
.style_three .how_it_work_number {
  position: absolute;
  top: -50px;
  left: 56px;
}
.style_three .how_it_work_icon .icon {
  color: #0c5adb;
  font-size: 65px;
  z-index: 1;
  transition: 0.5s;
}
.style_three .how_it_work_number h4 {
  height: 45px;
  width: 45px;
  line-height: 45px;
  background: #0c5adb;
  color: #fff;
  border-radius: 50%;
  font-size: 20px;
}
.style_three .how_it_work_icon_bg_thumb_inner {
  position: absolute;
  top: -48px;
  z-index: -1;
  left: 0;
  right: 0;
  margin: auto;
}
.style_three .how_it_work_bg_thumb_inner_hv {
  position: absolute;
  top: -18px;
  z-index: -1;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: 0.5s;
}
.style_three .how_it_work_title a {
  font-weight: 700;
  font-size: 22px;
}
.style_three .single_how_it_work:hover .how_it_work_bg_thumb_inner_hv {
  top: -48px;
  opacity: 1;
}
.style_three .single_how_it_work:hover .how_it_work_icon .icon {
  color: #fff;
}

.odoo_contact_section .row.upper {
  box-shadow: 0px 0px 37px 0px rgb(0 0 0 / 20%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-bottom: 0px;
  padding: 40px 40px 40px 40px;
  z-index: 1;
  border-radius: 10px;
  position: relative;
  margin-top: -203px;
  background: #fff;
}

.odoo-left-side {
  text-align: center;
}
.odoo-contact-thumb img {
  width: 100%;
}
.odoo-contact-content {
  padding: 18px 0px 23px;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
}
.odoo-contact-content h2 {
  margin: 0 0 16px;
  font-size: 20px;
}
.odoo-social-icon ul li {
  display: inline-block;
  height: 32px;
  width: 32px;
  background: #256bdf;
  border-radius: 4px;
  line-height: 32px;
  margin-right: 1px;
}
.odoo-social-icon ul li a {
  color: #fff;
  display: inline-block;
}

.odoo_contact_section .form_box input {
  height: 54px;
  background-color: #fff;
  border-color: transparent;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #e6e6e6;
  padding: 6px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
}

.odoo_contact_section .quote_wrapper {
  background: #fff;
  padding: 32px 50px 9px;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
}
.odoo_contact_section .apointment-form-title {
  text-align: center;
  margin-bottom: 30px;
}
.odoo_contact_section .apointment-form-title h2 {
  margin: 0 0 10px;
  font-size: 25px;
}

.odoo_contact_section select,
.form-control {
  height: 54px;
  background-color: #fff;
  border-color: transparent;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #e6e6e6;
  padding: 6px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  width: 100%;
}
.odoo_contact_section .quote_btn button {
  background: #0c5adb;
  border: 0;
  display: inline-block;
  text-align: center;
  padding: 14px 39px;
  border-radius: 7px;
  transition: 0.3s;
  width: 100%;
  color: #fff;
}
.odoo_contact_section .button:hover {
  background: #19314f;
  color: #fff;
}

.odoo_contact_section .form_box input:focus,
.odoo_contact_section .form_box textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.single_pricing {
  padding: 51px 40px 60px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  transition: 0.5s;
}
.single_pricing.active {
  border: 1px solid #0c5adb;
}
.pricing_title h3 {
  font-size: 30px;
  color: #23344c;
  font-weight: 700;
}
.pricing_body {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  transition: 0.5s;
}
.single_pricing.active .pricing_body {
  border: 1px solid #0c5adb;
}
.pricing_body .featur ul li {
  margin-bottom: 13px;
  display: block;
}
.checki {
  color: green;
}
.curencyp {
  font-size: 29px;
  font-weight: 500;
  position: relative;
  top: -15px;
  color: #3f73b4;
}
.pricing_tk h2 {
  display: inline-block;
  font-size: 55px;
  font-weight: 700;
  color: #3f73b4;
}
.pricing_tk h2 span {
  font-size: 19px;
  font-weight: 500;
  color: #3f73b4;
}
.order_now a {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  padding: 16px 42px;
  color: #616161;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  transition: 0.5s;
  background: transparent;
}
.single_pricing.active .order_now a {
  color: #fff;
  border: 1px solid #366196;
  background: #366196;
}
.single_pricing:hover {
  border: 1px solid #366196;
}
.single_pricing:hover .pricing_body {
  border: 1px solid #366196;
}
.single_pricing:hover .order_now a {
  background: #366196;
  color: #fff;
  border: 1px solid #366196;
}

.pricing_style2 {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  padding: 0 0 32px;
  transition: 0.5s;
  overflow: hidden;
  box-shadow: 0px 8px 14.72px 1.28px rgba(207, 207, 207, 0.3);
  border-radius: 10px;
}
.pricing_head_style2 {
  padding: 12px 0 21px;
  background: #0762f8;
}
.pricing_title_style2 h3 {
  font-size: 25px;
  color: #fff;
  text-transform: capitalize;
  padding-bottom: 5px;
}
.pricing_tk_style2 h2 {
  display: inline-block;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  color: #fff;
  transition: 0.5s;
}
.pricing_tk_style2 span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}
.pricing_body_style2 {
  margin-top: 40px;
}
.pricing_body_style2 .featur_style2 ul li {
  display: block;
  font-size: 15px;
  margin-bottom: 18px;
}
.pricing_body_style2 .deactive {
  text-decoration: line-through;
  color: #ccdcea;
}
.pricing_style2_button {
  margin-top: 33px;
}
.order_style2_now a {
  background: #f5f5f5;
  color: #0762f8;
  padding: 18px 55px;
  font-size: 18px;
  font-weight: 500;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
  display: inline-block;
}
.active .order_style2_now a {
  background: #0762f8;
  color: #fff;
}
.order_style2_now a:before {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background: #0762f8;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: -1;
  transition: 0.5s;
}
.pricing_style2:hover {
  transform: translateY(-10px);
}
.pricing_style2:hover .order_style2_now a:before {
  width: 180%;
}
.pricing_style2:hover .order_style2_now a {
  color: #fff;
}

.single_pricing_three {
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  text-align: center !important;
  border-radius: 5px;
  overflow: hidden;
}
.pricing_head_three {
  position: relative;
  background: #0c5adb;
  padding: 36px 0 50px;
  z-index: 1;
}
.pricing_head_three:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: url(./images/pricing-bg1.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto;
  z-index: -1;
}
.pricing_thumb {
  position: relative;
  display: inline-block;
  border: 1px solid #0c5adb;
  border-radius: 50%;
  z-index: 1;
  background: #fff;
  top: -50px;
}
.pricing_body_three .featur ul li {
  list-style: none;
  padding: 6px 0;
}
.pricing_tk_three h2 {
  font-size: 70px;
  color: #fff;
}
.pricing_tk_three span {
  font-size: 20px;
}
.pricing_head_three .curencyp {
  font-size: 30px !important;
  font-weight: 700;
  position: relative;
  top: -37px;
  color: #fff;
  right: -18px;
}
.order_now a {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  padding: 16px 42px;
  color: #616161;
  border: 1px solid #0c5adb;
  border-radius: 5px;
  transition: 0.5s;
  background: transparent;
}
.single_pricing_three:hover .order_now a {
  background: #0c5adb;
  color: #fff;
}

.pricing_style_four {
  background: #fff;
  margin-bottom: 30px;
  box-shadow: 0 20px 50px 5px #e9eef7;
}
.pricing_style_four_content {
  background: url(./images/pr-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 25px 0 77px;
}
.active .pricing_style_four_content {
  background: url(./images/pr-bg2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 25px 0 77px;
}

.pricing_style_four_content_inner {
  text-align: right;
  padding-right: 50px;
}
.pricing_style_four_title h4 {
  font-weight: 500;
  text-transform: uppercase;
}
.pricing_style_four_tk h2 {
  font-weight: 500;
}
.pricing_style_four_tk h2 span {
  font-size: 20px;
}
.pricing_style_four_body {
  padding: 55px 28px 40px;
}
.pricing_style_four_body ul {
  list-style: none;
}
.pricing_style_four_body ul li {
  font-size: 18px;
  margin-bottom: 18px;
}
.pricing_style_four_body ul li i {
  color: #0c5adb;
  margin-right: 10px;
}
.pricing_style_four_button a {
  background: #211e3b;
  display: block;
  text-align: center;
  padding: 20px 0;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  z-index: 1;
}
.pricing_style_four_button a span {
  font-size: 20px;
  margin-left: 5px;
}
.pricing_style_four_button a:before {
  position: absolute;
  left: 0;
  content: "";
  width: 10%;
  height: 100%;
  background: #0c5adb;
  top: 0;
  transition: 0.5s;
  z-index: -1;
}
.pricing_style_four:hover .pricing_style_four_button a:before {
  width: 100%;
}
.single_price_icon {
  position: relative;
}
.single_price_icon_inner {
  position: absolute;
  bottom: 0;
  left: 74px;
}
.single_price_icon_inner .icon {
  color: #fff;
  font-size: 50px;
  height: 100px;
  width: 100px;
  background: #00247e;
  line-height: 95px;
  display: inline-block;
  text-align: center;
  border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
  border: 5px solid #fff;
}

.single_process {
  text-align: center;
  position: relative;
  transition: 0.5s;
  margin-bottom: 30px;
}
.single_process_thumb {
  display: inline-block;
  border-radius: 100%;
  animation: checkAnim 2s infinite;
  height: 230px;
  width: 230px;
  line-height: 230px;
}
.single_process_thumb img {
  border-radius: 100%;
  border: 10px solid #fff;
  width: 100%;
}
.single_process_number {
  position: relative;
}
.single_process_number_inner {
  position: absolute;
  left: 0;
  bottom: -25px;
  margin: auto;
  transition: 0.5s;
  content: "";
  right: 0;
}
.single_process_number span {
  height: 50px;
  width: 50px;
  background: #0c5adb;
  display: inline-block;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
}
.single_process_content {
  padding-top: 30px;
}
.single_process_content h2 {
  font-size: 22px;
  font-weight: 700;
}
.single_process:hover .single_process_number_inner {
  bottom: 50px;
}
.cntr_bg_up {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 11px 11px 38px rgba(0, 0, 0, 0.2);
}
.countr_text h1 {
  font-size: 40px;
  font-weight: 800;
}
.counter_desc h5 {
  margin-top: 6px;
  color: #0c5adb;
}
.single_counter_icon i {
  font-size: 30px;
  color: #fff;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  display: inline-block;
  width: 80px;
  height: 80px;
  background: #0c5adb;
  border-radius: 100%;
  line-height: 80px;
}
.white .countr_text h1 {
  color: #fff;
}
.white .counter_desc h5 {
  color: #fff;
}
.border_bottom_lin {
  position: relative;
}
.border_bottom_lin::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 30px;
  height: 90px;
  width: 3px;
  background: #0c5adb;
  content: "";
  margin: auto;
}
.single_counter_content {
  overflow: hidden;
}
.single_counter_icon_two .icon {
  float: left;
  margin-right: 20px;
  margin-top: 12px;
}
.single_counter_icon_two .icon i {
  font-size: 35px;
  background: #0c5adb;
  height: 70px;
  width: 70px;
  line-height: 70px;
  display: inline-block;
  text-align: center;
  color: #fff;
  border-radius: 50%;
}

.counter_style_three {
  text-align: center;
  border: 2px solid #686666;
  padding: 0 20px 40px;
  border-radius: 10px;
  transition: 0.5s;
  margin-bottom: 40px;
}
.color2 .counter_style_three_icon .icon {
  background: #fd8424;
}
.color3 .counter_style_three_icon .icon {
  background: #d83030;
}
.counter_style_three.color2:hover {
  border-color: #fd8424;
}
.counter_style_three.color3:hover {
  border-color: #d83030;
}

.counter_style_three_icon .icon {
  background: #0c5adb;
  display: inline-block;
  height: 85px;
  width: 85px;
  line-height: 80px;
  color: #fff;
  font-size: 36px;
  border-radius: 50% 15%;
  position: relative;
  margin-top: -50px;
}
.counter_style_three:hover {
  border: 2px solid #0c5adb;
}

.counter_style_four {
  margin-bottom: 30px;
}
.counter_style_four_icon .icon {
  font-size: 42px;
  color: #fff;
}
.counter_style_four_icon .icon:before {
  content: "";
  position: absolute;
  left: -23px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -8px;
  width: 85px;
  height: 85px;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  background-image: url(./images/cn-shape.png);
  transform: rotate(-70deg);
  background-repeat: no-repeat;
  background-size: cover;
}
.counter_style_four_text h1 {
  color: #fff;
  font-size: 35px;
}
.counter_style_four_text h5 {
  color: #fff;
  font-size: 18px;
}
.counter_style_four:hover .counter_style_four_icon .icon:before {
  transform: rotate(-130deg);
}

.counter_area.odoo .countr_text h1 {
  margin-bottom: 11px;
}
.counter_area.odoo .text_center {
  text-align: center;
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  border-color: #a4c2f3;
}
.counter_area.odoo .text_center.last {
  border: none;
  border-color: inherit;
}

.erp_area .section_content_text p,
.section-inner-content p {
  font-size: 16px;
  line-height: 24px;
}
.erp_area .text_left .section_main_title h1 {
  font-size: 39px;
}

.odoo_section.pt-70.pb-100 {
  background: #0c5adb;
  padding: 81px 0 224px;
  position: relative;
  z-index: 1;
}
.odoo_section.pt-70.pb-100 .shape_img {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: -1;
}

.single_testimonial_content_text {
  box-shadow: 0 18px 17px 0 rgba(0, 0, 0, 0.1);
  padding: 32px 43px 20px;
  position: relative;
  background: #fff;
}
.single_testimonial_content_text:before {
  content: "";
  bottom: -20px;
  top: auto;
  border-color: #fff transparent transparent #fff;
  display: block;
  position: absolute;
  left: 0;
  margin-left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px;
}
.single_testimonial_thumb {
  float: left;
}
.single_testimonial_thumb img {
  width: 100%;
  border-radius: 50%;
  margin: auto;
}
.single_testimonial_content_title h4 {
  font-size: 20px;
  margin-top: 30px;
  font-weight: 700;
}
.single_testimonial_content_title span {
  color: #0c5adb;
  font-weight: 500;
  font-size: 16px;
  display: block;
  padding-top: 4px;
}
.single_testimonial_content_title.white span {
  color: #fff;
}
.experience_thumb img {
  width: 100%;
}

.testimonial_area.odoo .single_testimonial_content_text {
  position: relative;
  padding: 52px 30px 35px;
  -webkit-box-shadow: 0 25px 40px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 5px;
  text-align: left;
  margin-bottom: 40px;
}
.testimonial_area.odoo .single_testimonial_content_title h4 {
  font-size: 20px;
  margin-top: 0;
  font-weight: 700;
}
.testimonial_area.odoo .testi-star i.active {
  color: #0c5adb;
  font-size: 21px;
  margin: 0 1px;
}
.testimonial_area.odoo .single_Testimonial_inner_content {
  position: relative;
  top: 8px;
}

.single_testimonial_two {
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.17);
  text-align: center;
  background: #fff;
  padding: 40px 20px 30px;
}
.single_testimonial_thumb_two img {
  border-radius: 50%;
  margin: auto;
}
.single_testimonial_content_title_two h4 {
  font-size: 22px;
}
.single_testimonial_content_title_two span {
  color: #0c5adb;
}
@keyframes checkAnim {
  0%,
  100% {
    box-shadow: 0 0 0 5px rgba(12, 90, 219, 0.8);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(12, 90, 219, 0.8);
  }
}

#section-quote {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
}
#section-quote > h2 {
  color: #0e1c2a;
  font-family: OpenSans-Regular;
  font-size: 36px;
  font-weight: normal;
}
#section-quote > p {
  color: #767676;
  font-family: OpenSans-Light;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
#section-quote .container-quote {
  position: relative;
  width: 100%;
  margin: 55px 0 150px;
  height: 135px;
  -webkit-perspective: 10000px;
  -moz-perspective: 10000px;
  -ms-perspective: 10000px;
  perspective: 10000px;
}
#section-quote .container-quote .quote {
  width: 520px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 50%;
  cursor: pointer;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 1s cubic-bezier(0.5, -0.01, 0, 1.005);
  -moz-transition: all 1s cubic-bezier(0.5, -0.01, 0, 1.005);
  -o-transition: all 1s cubic-bezier(0.5, -0.01, 0, 1.005);
  transition: all 1s cubic-bezier(0.5, -0.01, 0, 1.005);
}
#section-quote .container-quote .quote.hide-top {
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-100%) rotateX(70deg);
  -moz-transform: translateX(-50%) translateY(-100%) rotateX(70deg);
  -ms-transform: translateX(-50%) translateY(-100%) rotateX(70deg);
  -o-transform: translateX(-50%) translateY(-100%) rotateX(70deg);
  transform: translateX(-50%) translateY(-100%) rotateX(70deg);
}
#section-quote .container-quote .quote.show {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(0%) rotateX(0deg);
  -moz-transform: translateX(-50%) translateY(0%) rotateX(0deg);
  -ms-transform: translateX(-50%) translateY(0%) rotateX(0deg);
  -o-transform: translateX(-50%) translateY(0%) rotateX(0deg);
  transform: translateX(-50%) translateY(0%) rotateX(0deg);
}
#section-quote .container-quote .quote.hide-bottom {
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(100%) rotateX(-70deg);
  -moz-transform: translateX(-50%) translateY(100%) rotateX(-70deg);
  -ms-transform: translateX(-50%) translateY(100%) rotateX(-70deg);
  -o-transform: translateX(-50%) translateY(100%) rotateX(-70deg);
  transform: translateX(-50%) translateY(100%) rotateX(-70deg);
}
#section-quote .container-quote .quote p {
  line-height: 28px;
  display: block;
}
#section-quote .container-quote .quote .container-info {
  display: inline-block;
  padding-left: 40px;
  position: relative;
  margin-top: 20px;
  text-align: left;
}
#section-quote .container-quote .quote .container-info .pp {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  top: 50%;
  border: 2px solid #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 120%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
#section-quote .container-quote .quote-text-1 .container-info .pp {
  background-image: url(./images/1_1.jpg);
}
#section-quote .container-quote .quote-text-2 .container-info .pp {
  background-image: url(./images/2_1.jpg);
}
#section-quote .container-quote .quote-text-3 .container-info .pp {
  background-image: url(./images/3_1.jpg);
}
#section-quote .container-quote .quote-text-4 .container-info .pp {
  background-image: url(./images/4_1.jpg);
}
#section-quote .container-quote .quote-text-5 .container-info .pp {
  background-image: url(./images/5_1.jpg);
}
#section-quote .container-quote .quote-text-6 .container-info .pp {
  background-image: url(./images/6_1.jpg);
}
#section-quote .container-quote .quote-text-8 .container-info .pp {
  background-image: url(./images/8_1.jpg);
}
#section-quote .container-quote .quote-text-9 .container-info .pp {
  background-image: url(./images/9.jpg);
}
#section-quote .container-quote .quote-text-10 .container-info .pp {
  background-image: url(./images/10.jpg);
}
#section-quote .container-quote .quote-text-11 .container-info .pp {
  background-image: url(./images/11.jpg);
}
#section-quote .container-quote .quote-text-12 .container-info .pp {
  background-image: url(./images/12.jpg);
}
#section-quote .container-quote .quote-text-13 .container-info .pp {
  background-image: url(./images/13.jpg);
}
#section-quote .container-quote .quote-text-14 .container-info .pp {
  background-image: url(./images/14.jpg);
}
#section-quote .container-quote .quote .container-info .name {
  color: #444;
  font-size: 22px;
  font-weight: 600;
}
#section-quote .container-quote .quote .container-info .job {
  color: #666;
  font-size: 16px;
}
#section-quote .container-pe-quote {
  position: absolute;
  height: 100%;
  top: 0px;
  z-index: 1;
}
#section-quote .container-pe-quote.left {
  left: 110px;
}
#section-quote .container-pe-quote.right {
  right: 80px;
}
#section-quote .container-pe-quote .pp-quote {
  cursor: pointer;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  animation-name: floating;
  -webkit-animation-name: floating;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-transform-origin: 50% 1px;
  -moz-transform-origin: 50% 1px;
  -ms-transform-origin: 50% 1px;
  -o-transform-origin: 50% 1px;
  transform-origin: 50% 1px;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease;
}
#container-general
  .anim-section-quote
  #section-quote
  .container-pe-quote
  .pp-quote {
  opacity: 1;
}
#section-quote .container-pe-quote .pp-quote .img {
  overflow: hidden;
  border-radius: 100%;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 120%;
  position: relative;
  z-index: 1;
}
#section-quote .container-pe-quote .pp-quote.li-quote-1 .img {
  background-image: url(./images/1_1.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-2 .img {
  background-image: url(./images/2_1.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-3 .img {
  background-image: url(./images/3_1.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-4 .img {
  background-image: url(./images/4_1.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-5 .img {
  background-image: url(./images/5_1.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-6 .img {
  background-image: url(./images/6_1.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-7 .img {
  background-image: url(./images/7_1.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-8 .img {
  background-image: url(./images/8_1.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-9 .img {
  background-image: url(./images/9.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-10 .img {
  background-image: url(./images/10.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-11 .img {
  background-image: url(./images/11.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-12 .img {
  background-image: url(./images/12.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-13 .img {
  background-image: url(./images/13.jpg);
}
#section-quote .container-pe-quote .pp-quote.li-quote-14 .img {
  background-image: url(./images/14.jpg);
}
#section-quote .container-pe-quote .pp-quote:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: calc(100% + 5px);
  width: calc(100% + 5px);
  border-radius: 100%;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#section-quote .container-pe-quote .pp-quote:hover:after,
#section-quote .container-pe-quote .pp-quote.active:after {
  background: #0c5adb;
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -moz-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  -o-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
@keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
    -moz-transform: rotate(0deg) translate(-10px) rotate(0deg);
    -ms-transform: rotate(0deg) translate(-10px) rotate(0deg);
    -o-transform: rotate(0deg) translate(-10px) rotate(0deg);
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    -moz-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    -ms-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    -o-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
@-webkit-keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
    -moz-transform: rotate(0deg) translate(-10px) rotate(0deg);
    -ms-transform: rotate(0deg) translate(-10px) rotate(0deg);
    -o-transform: rotate(0deg) translate(-10px) rotate(0deg);
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    -moz-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    -ms-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    -o-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
#section-quote .container-pe-quote .li-quote-1 {
  height: 90px;
  width: 90px;
  left: 40px;
  top: 30px;
  animation-duration: 10s;
  -webkit-animation-duration: 10s;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
#section-quote .container-pe-quote .li-quote-2 {
  height: 63px;
  width: 63px;
  left: 150px;
  top: 150px;
  animation-duration: 12s;
  -webkit-animation-duration: 12s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
#section-quote .container-pe-quote .li-quote-3 {
  height: 82px;
  width: 82px;
  left: -70px;
  top: 180px;
  animation-duration: 11s;
  -webkit-animation-duration: 11s;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
#section-quote .container-pe-quote .li-quote-4 {
  height: 48px;
  width: 48px;
  left: 80px;
  top: 250px;
  animation-duration: 6s;
  -webkit-animation-duration: 6s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
#section-quote .container-pe-quote .li-quote-5 {
  height: 73px;
  width: 73px;
  left: -60px;
  top: 320px;
  animation-duration: 7s;
  -webkit-animation-duration: 7s;
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
#section-quote .container-pe-quote .li-quote-6 {
  height: 63px;
  width: 63px;
  left: 65px;
  top: 380px;
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
#section-quote .container-pe-quote .li-quote-12 {
  height: 63px;
  width: 63px;
  left: -15px;
  top: 460px;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
#section-quote .container-pe-quote .li-quote-7 {
  height: 73px;
  width: 73px;
  right: -15px;
  top: 30px;
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
#section-quote .container-pe-quote .li-quote-8 {
  height: 73px;
  width: 73px;
  right: 50px;
  top: 150px;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
#section-quote .container-pe-quote .li-quote-9 {
  height: 48px;
  width: 48px;
  right: 150px;
  top: 90px;
  animation-duration: 7s;
  -webkit-animation-duration: 7s;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
#section-quote .container-pe-quote .li-quote-10 {
  height: 90px;
  width: 90px;
  right: 225px;
  top: 250px;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
#section-quote .container-pe-quote .li-quote-11 {
  height: 52px;
  width: 52px;
  right: 40px;
  top: 320px;
  animation-duration: 7s;
  -webkit-animation-duration: 7s;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
#section-quote .container-pe-quote .li-quote-13 {
  height: 42px;
  width: 42px;
  right: 128px;
  top: 380px;
  animation-duration: 9s;
  -webkit-animation-duration: 9s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
#section-quote .container-pe-quote .li-quote-14 {
  height: 48px;
  width: 48px;
  right: 108px;
  top: 470px;
  animation-duration: 7s;
  -webkit-animation-duration: 7s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.testimonial_style_three_content {
  position: relative;
  z-index: 1;
  padding: 50px 50px 50px 50px;
}
.testimonial_style_three_content::before {
  position: absolute;
  content: "";
  left: -100px;
  top: 0;
  height: 100%;
  width: 123%;
  background: #0c5adb;
  z-index: -1;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 17% 0%);
}

.testimonial_style_three_title {
  border-left: 2px solid #fff;
  padding-left: 25px;
}
.testimonial_style_three_title h4 {
  color: #fff;
  font-weight: 800;
}
.testimonial_style_three_title span {
  color: #fff;
  font-weight: 600;
}
.testimonial_style_three_text p {
  color: #fff;
  font-size: 20px;
  font-style: italic;
}
.testimonial_style_three_reviwer_star i {
  color: #fff;
  font-size: 20px;
  display: inline-block;
  margin-right: 2px;
}
.testimonial_style_three_quote i {
  font-size: 50px;
  color: #fff;
}

.testimonial-area {
  background: url(./images/testi-bg1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.testimonial-single-box1 {
  position: relative;
  z-index: 1;
}
.testi-counter-box {
  position: absolute;
  z-index: 1;
  top: 180px;
  left: -70px;
}
.testi-counter-box-inner {
  width: 155px;
  height: 155px;
  text-align: center;
  background: #ff6a32;
  border-radius: 50%;
  padding: 10px 0 0;
  border: 12px solid #ebc7c4;
}

.testi-counter-title h3 {
  font-size: 26px;
  font-weight: 800;
  line-height: 27px;
  color: #fff;
}
.testi-counter-icon i {
  display: inline-block;
  font-size: 13px;
  color: #ffffff;
  padding: 5px 0 0;
}
.testi-counter-text span {
  color: #fff;
  font-size: 15px;
  position: relative;
  top: -4px;
}

.em-testimonial-title p {
  padding: 15px 0 15px;
}
.people-thumb {
  float: left;
  margin-right: 20px;
}
.people-title {
  display: inline-block;
}
.people-title h4 {
  font-size: 18px;
}
.people-title p {
  font-size: 15px;
  padding: 3px 0 0;
}

.testimonial_list3 .owl-dots {
  text-align: center;
  position: absolute;
  right: -460px;
  bottom: 22px;
  left: 0;
  cursor: pointer;
  animation: 1.5s fadeInRight;
}
.testimonial_list3 .owl-dot {
  height: 12px;
  width: 12px;
  background: #548df7;
  line-height: 30px;
  display: inline-block;
  margin-right: 8px;
  border-radius: 103px;
  transition: 0.5s;
}
.testimonial_list3 .owl-dot.active {
  background: #ff6933;
  width: 12px;
  height: 12px;
}

.testimonial-area.style-two {
  background: #f7f9fd;
  padding: 290px 0 170px;
}
.style-two .people-thumb {
  float: left;
  margin-right: 32px;
  margin-top: 80px;
  position: relative;
}
.style-two .testimonial-content {
  overflow: hidden;
  padding: 0px 32px 30px;
  border-right: 12px solid #f0f2f1;
  border-bottom: 12px solid #f0f2f1;
  transition: 0.5s;
}
.style-two .em-testimonial-title h6 {
  font-size: 17px;
}
.style-two .em-testimonial-title h6 span {
  text-align: right;
  font-size: 16px;
  color: #828fa0;
  padding: 0 0 0 26px;
}
.style-two .em-testimonial-text p {
  color: #828fa0;
  padding: 5px 0 10px;
}
.quote-icon i {
  font-size: 80px;
  color: #0b59db;
}

.testimonial-content:hover {
  border-bottom: 12px solid #0b59db;
  border-right: 12px solid #0b59db;
}

.style-two .owl-dots {
  text-align: center;
  position: relative;
  right: 0;
  left: 0;
  cursor: pointer;
  top: 65px;
}

.nagative_margin2 {
  position: relative;
  margin-top: -108px;
}
.nav-tabs .nav-item {
  margin: 0 5px;
}
.nav-tabs .nav-link {
  border: 1px solid #0c5adb !important;
  background: #0c5adb;
  padding: 22px 50px;
  font-size: 20px;
  color: #fff;
}
.nav-tabs .nav-item.active .nav-link {
  border: 1px solid #fff !important;
  background: #fff;
  color: #0c5adb;
}
.nav-tabs {
  border-bottom: inherit !important;
}
.nav-tabs .nav-link i {
  font-size: 30px;
  display: block;
  text-align: center;
  padding-bottom: 5px;
}
.tab_thumb img {
  width: 100%;
}
.tab_content_title h4 {
  font-weight: 700;
}
.tab_content_sub_text ul {
  display: block;
}
.tab_content_sub_text ul li {
  list-style: none;
  margin-bottom: 5px;
}

.choose_us .techno_flipbox .techno_flipbox_font,
.choose_us .techno_flipbox .techno_flipbox_back {
  min-height: 410px;
}
.choose_us .techno_flipbox .techno_flipbox_font {
  position: relative;
  border: 0 !important;
}
.choose_us .techno_flipbox .techno_flipbox_font::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    black 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}
.choose_us .techno_flipbox .techno_flipbox_inner {
  top: 72%;
}
.choose_us .techno_flipbox .techno_flipbox_back .techno_flipbox_inner {
  top: 50%;
}

.choose_icon_lft {
  float: left;
}
.choose_icon_lft i {
  color: #0c5adb;
  height: 70px;
  width: 70px;
  line-height: 68px;
  border: 1px solid #307cff;
  border-radius: 4px 4px 4px 4px;
  font-size: 30px;
  text-align: center;
  background: transparent;
  transition: 0.5s;
}
.choose_content_title_lft h5 {
  font-weight: 700;
}
.choose_us_content:hover .choose_icon_lft i {
  background: #0c5adb;
  color: #fff;
}
.choose_content_text_bold p {
  font-size: 19px;
  font-weight: 600;
  color: #232323;
}

.single_choose_us_thumb img {
  width: 100%;
}
.single_choose_us_icon .icon {
  height: 70px;
  width: 70px;
  background: #0c5adb;
  line-height: 68px;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-size: 35px;
  float: left;
  margin-right: 20px;
  border: 1px solid #0c5adb;
  transition: 0.5s;
}
.single_choose_us_content:hover .single_choose_us_icon .icon {
  background: transparent;
  color: #0c5adb;
}
.why-choose-us.style-two {
  background: #f7f9fd;
}
.choose-icon h6 {
  font-size: 18px;
  color: #1d2124;
  font-weight: 600;
  display: inline-block;
  margin-left: 10px;
}
.choose-icon span i {
  display: inline-block;
  font-size: 14px;
}
span.chose-icon {
  width: 20px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  background: #ff6900;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
}
span.chose-icon1 {
  width: 20px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  background: #0b59db;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
}
span.chose-icon2 {
  width: 20px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  background: #e73d8d;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
}
.choose-icon-text p {
  padding: 7px 0 0;
  margin: 0 0 11px;
  color: #828fa0;
}

.why-choose-us-thumb {
  position: relative;
  z-index: 1;
  left: 75px;
}
.why-choose-shape-thumb {
  position: absolute;
  top: 177px;
  left: -15px;
}
.why-choose-main-thumb {
  position: relative;
  left: -160px;
}
.why-choose-main-thumb1 {
  position: relative;
  left: -75px;
}

.single_fun_faq {
  background: url(./images/fun1.jpg);
  padding: 20px 30px;
  margin-bottom: 30px;
}
.single_fun_faq_number h1 {
  font-size: 45px;
  font-weight: 800;
}

.main-accordion-lt {
  background: #2d2d2d;
}
.acd-items.acd-arrow {
  margin-left: 160px;
}
.panel-heading .panel-title a {
  background: #393939;
  display: block;
  font-weight: 600;
  padding: 10px 15px;
  position: relative;
  color: #fff;
  font-size: 18px;
}
.faq .panel-heading .panel-title a {
  background: #eff2f7;
  color: #232323;
  transition: 0.5s;
}
.faq .panel-heading .panel-title a:hover {
  color: #0c5adb;
}

.faq .panel-body p {
  color: #8a8a8a;
}
.acd-items .panel-heading {
  padding: 0;
}

.panel.panel-default {
  border-color: #e7e7e7;
  border-radius: inherit;
  box-shadow: inherit;
}
.panel-body p {
  color: #cccccc;
}
.default .panel-body p {
  color: #666;
}
.accordion-area .absod {
  padding: 0;
}
.panel-heading.mb-3 a i {
  font-size: 15px;
  margin-right: 10px;
}
.panel-group.symb .panel-heading a::after {
  content: "-";
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 7px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.panel-group.symb .panel-heading a.collapsed:after {
  content: "+";
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.single_faq_title h4 {
  font-weight: 700;
}

.form_box input {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  transition: 0.5s;
}

.form_box textarea {
  width: 100%;
  background: transparent;
  padding-left: 15px;
  padding-top: 20px;
  height: 250px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  transition: 0.5s;
}
.quote_btn button {
  background: #fff;
  border: 0;
  display: inline-block;
  text-align: center;
  padding: 14px 39px;
  border-radius: 7px;
  transition: 0.3s;
}
.quote_btn button:hover {
  background: #00247e;
  color: #fff;
}
.appointment_form_inner {
  background: #ffffff;
  padding: 70px 70px 70px 70px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
}
.appointment_title h1 {
  text-align: center;
  font-weight: 800;
}
.appointment_form .form_box input {
  border: 1px solid #e6e6e6;
  color: #495057;
}
.appointment_form .form_box textarea {
  border: 1px solid #e6e6e6;
  color: #495057;
}
.appointment_form .quote_btn button {
  background: #0c5adb;
  color: #fff;
}
.appointment_form .form_box input:focus,
.appointment_form .form_box textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.main_contact_area .form_box input {
  border: 1px solid #e6e6e6;
  color: #495057;
  background-color: #fff;
}
.main_contact_area .form_box textarea {
  border: 1px solid #e6e6e6;
  color: #495057;
  background-color: #fff;
}
.main_contact_area .quote_btn button {
  background: #0c5adb;
  color: #fff;
}
.main_contact_area .form_box input:focus,
.main_contact_area .form_box textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.map {
  width: 100%;
  margin: 0;
  height: 410px;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.google_map_area .col-lg-12 {
  padding: 0;
}

.style_three .contact_address_company ul li {
  list-style: none;
  display: block;
  margin-bottom: 12px;
}
.style_three .contact_address_company ul li i {
  font-size: 25px;
  color: #fff;
  margin-right: 15px;
  height: 55px;
  width: 55px;
  line-height: 55px;
  background: #0c5adb;
  text-align: center;
  border-radius: 50%;
}
.style_three .contact_address_company ul li a {
  color: #616161;
}
.style_three .contact_from_box {
  box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
  background: #fff;
  padding: 35px 50px 40px;
  border-top: 8px solid #0c5adb;
  border-radius: 5px;
}
.style_three .form_box textarea {
  height: 180px;
}
.style_three .footer-bottom-content-copy p {
  color: #232323;
}
.style_three .footer-bottom-right-text a {
  color: #232323;
}

.techno-seo-area {
  background: url(./images/techno-seo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 0 85px;
}
.techno-seo-title h3 {
  font-size: 30px;
  color: #fff;
}
.techno-seo-title p {
  padding: 22px 0 17px;
  color: #fff;
}
.upper1 .subscribe_form input {
  font-size: 14px;
  height: 70px;
  padding: 5px 25px;
  position: relative;
  background: #f8f9fa;
  margin: auto;
  border: 10px solid #ffffff;
  border-radius: 10px;
  width: 620px;
}
.upper1 .subscribe_form_send button {
  transition: all 0.4s ease 0s;
  border: medium none;
  border-radius: 5px;
  background: #0c5adb;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin: auto;
  padding: 13px 28px;
  position: absolute;
  right: 270px;
  bottom: -3%;
  top: inherit;
  display: inline-block;
  transform: translateY(-50%);
  z-index: 11;
}

.seo-shape-thumb {
  position: absolute;
  left: -224px;
  bottom: 0px;
}
.seo-shape-thumb1 {
  position: absolute;
  top: 101px;
  left: 199px;
}
.seo-shape-thumb2 {
  position: absolute;
  right: 40px;
  top: 85px;
}
.seo-shape-thumb2 img {
  width: 75%;
}
.seo-shape-thumb3 {
  position: absolute;
  right: -155px;
  bottom: 0;
}

p.seo-text {
  padding: 19px 0 2px;
  color: #828fa0;
}
p.seo-text1 {
  padding: 0px 0 8px;
  color: #828fa0;
}

.single_contact_address {
  border: 1px solid #d0e5fb;
  border-radius: 5px 5px 5px 5px;
  padding: 30px 25px 15px;
}
.contact_address_icon i {
  font-size: 40px;
  color: #0c5adb;
}
.contact_address_title h4 {
  font-weight: 700;
}
.single_contact_address_two {
  background: #fff;
  box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.2);
  padding: 30px 22px 37px;
  margin-bottom: 30px;
}
.single_contact_address_two_content h4 {
  font-size: 22px;
}
.single_contact_address_two_icon .icon {
  height: 70px;
  width: 70px;
  line-height: 70px;
  font-size: 35px;
  display: inline-block;
  background: #0c5adb;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  float: left;
  margin-right: 15px;
}
.single_contact_address_two_content span {
  color: #0c5adb;
  text-transform: uppercase;
}
.single_contact_rt_thumb img {
  width: 100%;
}
.cnt_box {
  background: #fff;
  box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.2);
}
.contact_sm_area .form_box input {
  border: 1px solid #ddd;
}
.contact_sm_area .form_box textarea {
  border: 1px solid #ddd;
  height: 180px;
}
.contact_sm_area .quote_btn button {
  background: #0c5adb;
  color: #fff;
}
.contact_sm_area .form_box input:focus,
.contact_sm_area .form_box textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.single_contact_abs_thumb img {
  width: 100%;
}
.app .form_box textarea {
  height: 170px;
}

.single_contact_now {
  background: #fff;
  padding: 25px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
}
.single_contact_now_inner {
  border: 2px solid #ddd;
  padding: 30px 20px 15px;
  border-radius: 5px;
  transition: 0.5s;
}
.single_contact_now_icon i {
  color: #0c5adb;
  font-size: 40px;
}
.single_contact_now:hover .single_contact_now_inner {
  border-color: #0c5adb;
}

.single_blog {
  border: 1px solid #ebebeb;
  overflow: hidden;
  transition: 0.5s;
}
.single_blog_content {
  background: #fff;
}
.single_blog:hover {
  border-color: transparent;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}
.blog_page_title h3 {
  font-size: 21px;
  transition: 0.5s;
}
.blog_page_title a {
  font-weight: 700;
}
.single_blog_thumb img {
  width: 100%;
}
.blog_page_button a {
  font-size: 15px;
  transition: 0.5s;
  color: #616161;
}
.blog_page_button.style_two a {
  font-size: 15px;
  transition: 0.5s;
  color: #0c5adb;
  border: 1px solid #0c5adb;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 30px;
}
.blog_page_button a i {
  margin-left: 6px;
}
.blog_page_button a:hover {
  color: #0c5adb;
}
.blog_page_title a:hover {
  color: #0c5adb;
}
.techno_blog_meta a {
  color: #616161;
  transition: 0.3s;
  position: relative;
}
.techno_blog_meta a:before {
  position: absolute;
  right: -6px;
  height: 14px;
  width: 1px;
  background: #666;
  content: "";
  top: 5px;
}
.techno_blog_meta a:hover {
  color: #0c5adb;
}

.blog_area.oddo {
  background: #eff2f7;
}

.techno_blog_meta.shado_bg {
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.11);
  padding: 10px 12px;
  position: relative;
  margin-top: -26px;
  background: #fff;
  border-radius: 5px;
}
.single_blog_thumb.el_thumb {
  overflow: hidden;
}
.single_blog_thumb.el_thumb img {
  transition: 0.5s;
  overflow: hidden;
}
.single_blog:hover .single_blog_thumb.el_thumb img {
  transform: scale(1.2);
}
.single_blog_date {
  position: absolute;
  right: 0;
  top: 0;
}
.single_blog_date_inner {
  background: #0c5adb;
  display: inline-block;
  padding: 2px 15px 10px;
}
.color2 .single_blog_date_inner {
  background: #fd8424;
}
.color3 .single_blog_date_inner {
  background: #d83030;
}
.single_blog_date_inner h3 {
  color: #fff;
}
.single_blog_date_inner span {
  display: block;
  color: #fff;
}

.single-blog-box {
  overflow: hidden;
  filter: drop-shadow(0 0 40px rgba(125, 149, 188, 0.1));
  background-color: #ffffff;
  border-radius: 5px;
  transition: 0.5s;
  margin-bottom: 30px;
}

.single-blog-thumb {
  transition: 0.5s;
  overflow: hidden;
}
.single-blog-thumb img {
  width: 100%;
  overflow: hidden;
  transition: 0.5s;
}

.em-blog-content {
  padding: 12px 30px 25px;
  border-radius: 5px;
}

.meta-blog-text p {
  padding: 0 0 0 25px;
  margin: 0;
  position: relative;
  z-index: 1;
}
.meta-blog-text p:before {
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  width: 55%;
  height: 55%;
  background: url(./images/date.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.em-blog-title h2 {
  font-size: 22px;
  padding: 4px 0 0;
}
.em-blog-title h2 a {
  font-size: 22px;
  color: #232323;
  display: inline-block;
  font-weight: 700;
  transition: 0.5s;
}
.em-blog-text p {
  display: inline-block;
  padding: 14px 0 22px;
  border-bottom: 1px solid rgba(125, 149, 188, 0.1);
  margin-bottom: 11px;
}

.em-blog-icon {
  display: inline-block;
  float: left;
}
.em-blog-thumb {
  float: left;
}
.em-blog-icon-title {
  display: inline-block;
}
.em-blog-icon-title h6 {
  font-size: 16px;
  margin-left: 10px;
}

.blog-button {
  text-align: right;
  padding: 6px 0 0;
}
.blog-button a {
  display: inline-block;
  color: #232323;
  text-decoration: underline;
  transition: 0.5s;
}
.blog-button a i {
  display: inline-block;
  font-size: 16px;
  padding: 0 0 0 5px;
  position: relative;
  top: 2px;
  color: #153ac8;
}

.single-blog-box:hover .em-blog-title h2 a {
  color: #0c5adb;
  text-decoration: underline;
}
.single-blog-box:hover .blog-button a {
  color: #0c5adb;
}
.single-blog-box:hover .single-blog-thumb img {
  transform: scale(1.1);
}
.single-blog-box:hover {
  filter: drop-shadow(0 0 13px rgba(125, 149, 188, 0.2));
}

.blog-area.blog-new .owl-carousel .owl-dots.disabled,
.blog-area.blog-new .owl-carousel .owl-nav.disabled {
  display: inline-block;
}
.blog-area.blog-new .owl-prev i {
  background-image: linear-gradient(to left, #2475fc, #1129b9);
  border-radius: 5px;
  height: 50px;
  width: 50px;
  z-index: 1;
  transition: all 0.3s ease 0s;
  line-height: 50px;
  color: #fff;
  border: 2px solid #fff;
  text-align: center;
  font-size: 25px;
}
.blog-area.blog-new .owl-next i {
  background-image: linear-gradient(to left, #2475fc, #1129b9);
  border-radius: 5px;
  height: 50px;
  width: 50px;
  z-index: 1;
  transition: all 0.3s ease 0s;
  line-height: 50px;
  color: #fff;
  border: 2px solid #fff;
  text-align: center;
  font-size: 25px;
}
.blog-area.blog-new .owl-next {
  position: absolute;
  top: -83px;
  right: 15px;
}
.blog-area.blog-new .owl-prev {
  position: absolute;
  top: -83px;
  right: 80px;
  left: inherit;
}
.blog-area.blog-new .owl-carousel:hover .owl-prev {
  left: inherit;
}

.subscribe_content_title h2 {
  font-size: 32px;
  font-weight: 700;
}
.subscribe_form input {
  font-size: 14px;
  height: 65px;
  padding: 5px 25px;
  position: relative;
  width: 100%;
  background: #f6f6fc;
  border: 0;
  border-radius: 0;
  margin-bottom: 14px;
  margin: auto;
  border: 0;
  border-radius: 15px;
}
.subscribe_form_send button {
  text-align: center;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border: medium none;
  border-radius: 15px;
  background: #0c5adb;
  display: block;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  margin: auto;
  padding: 15px 43px;
  position: absolute;
  right: 20px;
  top: 74%;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.subscribe_form_send button:hover {
  color: #fff;
}

.style_two .row.sbc_bg_box {
  background: #0c5adb;
  border-radius: 15px;
  padding: 25px 0 55px;
  position: relative;
  margin-bottom: -140px;
  z-index: 1;
}
.style_two.tp-mg .row.sbc_bg_box {
  margin-bottom: 0;
  margin-top: -145px;
}
.style_two .subscribe_bg_box form {
  background: #fff;
  padding: 30px 40px 25px;
  border-radius: 80px;
}
.style_two .subscribe_form input {
  font-size: 14px;
  height: 65px;
  padding: 5px 25px;
  position: relative;
  width: 75%;
  background: #f6f6fc;
  border: 0;
  border-radius: 0;
  margin-bottom: 14px;
  margin: 0;
  border: 0;
  border-radius: 40px;
}
.style_two .subscribe_form_send button {
  border-radius: 40px;
  background: #0c5adb;
  font-weight: 600;
  margin: auto;
  padding: 17px 55px;
  right: 55px;
  top: 68%;
  color: #fff;
}
.style_two .subscribe_form_send button:hover {
  color: #fff;
}

.style_three .row.sbc_bg_box {
  background: url(./images/subcrib-bg.png);
  border-radius: 15px;
  padding: 42px 45px 50px;
  background-repeat: no-repeat;
}

.style_three .subscribe_content_title h2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 50px;
}
.style_three .subscribe_content_title p {
  font-size: 20px;
  padding: 6px 0 0px;
}

.subscribe_form {
  position: relative;
  z-index: 1;
}
.style_three .subscribe_form input {
  font-size: 14px;
  height: 68px;
  width: 640px !important;
  padding: 5px 25px;
  position: relative;
  width: 100%;
  background: #ffffff;
  border: 0;
  margin-bottom: 14px;
  border-radius: 10px;
}

.style_three .subscribe_form_send button {
  transition: all 0.4s ease 0s;
  border: medium none;
  border-radius: 10px;
  background: #0c5adb;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  margin: auto;
  padding: 15px 30px;
  position: absolute;
  right: 37px;
  top: 159px;
  display: inline-block;
  transform: translateY(-50%);
  z-index: 1;
}

.subscribe-thumb {
  position: relative;
  z-index: 1;
  top: 37px;
  left: 120px;
  animation: moveLeftBounce 3s linear infinite;
}

@keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.upper .subscribe_form input {
  font-size: 14px;
  height: 70px;
  padding: 5px 25px;
  position: relative;
  width: 100%;
  background: #f8f9fa;
  margin-bottom: 14px;
  margin: auto;
  border: 10px solid #ffffff;
  border-radius: 10px;
}
.upper .subscribe_form_send button {
  transition: all 0.4s ease 0s;
  border: medium none;
  border-radius: 5px;
  background: #0c5adb;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  margin: auto;
  padding: 13px 28px;
  position: absolute;
  right: 12px;
  bottom: -4%;
  top: inherit;
  display: inline-block;
  transform: translateY(-50%);
  z-index: 11;
}

.footer-middle {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background: #111a3a;
}
.widget-title {
  color: #fff;
  font-size: 22px;
}
.company-info-desc p {
  color: #fff;
}
.menu-quick-link-container ul li a {
  color: #fff;
  position: relative;
  transition: 0.5s;
}
.menu-quick-link-container ul li a:before {
  position: absolute;
  content: "";
  left: -24px;
  top: 10px;
  height: 1px;
  width: 13px;
  background: #fff;
}
.menu-quick-link-container ul li {
  padding-bottom: 10px;
  list-style: none;
}
.recent-post-text a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
.recent-post-image {
  float: left;
  margin-right: 20px;
}
.follow-company-text a {
  font-size: 16px;
  transition: 0.5s;
}
.follow-company-text a p {
  color: #fff;
  transition: 0.5s;
}
.follow-company-text {
  float: left;
}
.follow-company-icon a {
  font-size: 16px;
  color: #fff;
  margin: 0 5px;
  font-weight: 500;
  display: inline-block;
  transition: 0.5s;
}

.footer-social-info p span {
  font-weight: 600 !important;
  color: #fff;
}
.footer-social-info p {
  color: #fff;
}
.recent-post-text span {
  color: #adb0be;
}
.recent-post-item.active {
  border-bottom: 1px solid #adb0be;
}
.follow-company-icon a:hover {
  color: #0c5adb;
}
.follow-company-text a p:hover {
  color: #0c5adb;
}
.menu-quick-link-container ul li a:hover {
  color: #0c5adb;
}
.recent-post-text a:hover {
  color: #0c5adb;
}

.footer_middle_area {
  overflow: hidden;
}
.footer_middle_social_icon {
  text-align: center;
}
.footer_middle_social_icon a {
  height: 65px;
  width: 65px;
  line-height: 65px;
  display: inline-block;
  background: #0c5adb;
  border-radius: 50%;
  font-size: 25px;
  color: #fff;
  text-align: center;
  margin: 0 5px;
  transition: 0.5s;
}
.footer_middle_social_icon a.color1 {
  background: #ef6330;
}
.footer_middle_social_icon a.color2 {
  background: #43baff;
}
.footer_middle_social_icon a.color3 {
  background: #ff9000;
}
.footer_middle_social_icon a.color4 {
  background: #e60023;
}
.footer_middle_social_icon a.color5 {
  background: #00247e;
}
.footer_middle_social_icon a.color6 {
  background: #6659d4;
}
.footer_middle_social_icon a.color7 {
  background: #5eb414;
}

.footer_middle_social_icon a:hover {
  background: #0c5adb;
}
.footer_bottom_menu_inner {
  text-align: center;
}
.footer_bottom_menu_inner ul {
  list-style: none;
}
.footer_bottom_menu_inner ul li {
  display: inline-block;
}
.footer_bottom_menu_inner ul li a {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin: 0 12px;
  transition: 0.5s;
}
.footer_bottom_menu_inner ul li a:hover {
  color: #0c5adb;
}
.footer_bg_shape {
  position: relative;
}
.footer_bg_shape:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 80%;
  width: 50%;
  background: url(./images/footer-bgdt.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.6;
}

.style_three .widget-title {
  color: #232323;
  font-size: 22px;
}
.style_three .menu-quick-link-container ul li a {
  color: #232323;
}
.style_three .menu-quick-link-container ul li a:hover {
  color: #0c5adb;
}
.style_three .company-info-desc p {
  color: #232323;
}
.style_three .footer-social-info p {
  color: #232323;
}
.style_three .recent-post-text a {
  color: #232323;
}
.style_three .follow-company-icon a {
  color: #0c5adb;
}
.style_three .follow-company-text a p {
  color: #232323;
}
.style_three .footer-social-info p span {
  color: #232323;
}
.style_three .menu-quick-link-container ul li a:before {
  background: #0c5adb;
}

.style-four.footer-middle {
  background: url(./images/footer-bg-techno.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.style-four .follow-company-text {
  float: inherit;
}
.style-four .follow-company-text a {
  font-size: 20px;
  transition: 0.5s;
  font-weight: 700;
}
a.social-icon-color {
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #187dff;
  border-radius: 5px;
}
a.social-icon-color1 {
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #229ff9;
  border-radius: 5px;
}
a.social-icon-color2 {
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: linear-gradient(to top left, #e94d5a, #c327a2);
  border-radius: 5px;
}
a.social-icon-color3 {
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #f70102;
  border-radius: 5px;
}
.style-four .follow-company-icon a:hover {
  color: #fff;
}

.style-four .menu-quick-link-container ul li {
  padding-bottom: 15px;
  list-style: none;
}
.style-four .menu-quick-link-container ul li a:hover {
  color: #fff;
}

.style-four .recent-post-item.active {
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
}
.style-four .recent-post-text a {
  color: #fff;
  font-weight: 700;
  transition: 0.5s;
  font-size: 16px;
  line-height: 25px;
}
.style-four .recent-post-text span {
  color: #9eb3ef;
}
.style-four .company-info-title h6 {
  margin: 3px 0 3px;
  font-size: 18px;
  color: #fff;
}
.style-four .company-info-title p {
  color: #9eb3ef;
  padding: 0 0 7px;
}
.style-four .company-icon {
  float: left;
  margin-right: 20px;
}
.style-four .company-icon a i {
  display: inline-block;
  font-size: 17px;
  color: #fff;
  width: 38px;
  height: 38px;
  line-height: 40px;
  background: #3d65de;
  text-align: center;
  border-radius: 50%;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.23);
}
.footer-bottom-content-copy p {
  color: #fff;
}
.footer-bottom-right-text {
  text-align: right;
}
.footer-bottom-right-text a {
  color: #fff;
  display: inline-block;
}
.footer-bottom-right-text .absod {
  margin-right: 30px;
  position: relative;
}
.footer-bottom-right-text .absod::before {
  position: absolute;
  content: "";
  right: -17px;
  top: 6px;
  height: 14px;
  width: 1px;
  background: #fff;
  transform: skew(-20deg);
}

.blog-details-area {
  background: #fff;
}
.blog_dtl_top_bs span {
  background: #0c5adb;
  padding: 8px 30px;
  color: #fff;
  border-radius: 30px;
}
.techno-blog-thumb img {
  transform: scale(1.01);
  transition: 0.5s;
  width: 100%;
}
.techno-single-blog.admin:hover .techno-blog-thumb img {
  transform: scale(1.2);
}

.blog_dtl_content {
  padding: 38px 45px 45px;
  -webkit-box-shadow: 0 3px 19px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 19px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 10px 10px;
}
.recent_post_dtls {
  padding: 38px 45px 45px;
  -webkit-box-shadow: 0 3px 19px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 19px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 10px 10px;
}

.blog_dt_title h4 {
  font-weight: 700;
}
.blog_dtl_thumb img {
  width: 100%;
}
.blog_dtl_content h2 {
  color: #0d1218;
  font-size: 35px;
  padding-top: 20px;
  font-weight: 700;
}
.techno-blog-meta-left {
  padding: 12px 0 35px;
}
.techno-blog-meta-left span {
  color: #0d1218;
  margin-right: 40px;
}
.techno-blog-meta-left i {
  margin-right: 10px;
}
.blog_details_dtn_icon a i {
  border: 1px solid #e6e6e6;
  color: #565872;
  display: inline-block;
  font-size: 15px;
  height: 40px;
  line-height: 41px;
  margin: 0 9px 0 0;
  text-align: center;
  width: 40px;
  border-radius: 5px;
}
.blog_details_dtn_icon a i:hover {
  background: #0c5adb;
  border-color: #0c5adb;
  color: #fff;
}
.blog_dtl_content h3 {
  color: #0d1218;
  padding: 10px 0 20px;
  font-size: 24px;
  font-weight: 700;
}
.blog_details blockquote {
  margin: 37px 0 36px;
  font-size: 14px;
  border-left: 7px solid #0c5adb;
  background: #f7fafd;
  font-style: italic;
  font-weight: 500;
  padding: 25px 35px 15px;
}
.signatures {
  text-align: right;
  font-weight: 600;
  font-style: italic;
  font-size: 15px;
}
.blog_comments_section {
  padding: 38px 45px 45px;
  -webkit-box-shadow: 0 3px 19px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 19px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 10px 10px;
}
.comments_ttl > h3 {
  font-size: 24px;
  color: #0d1218;
  font-weight: 700;
}
.comments_ttl {
  margin-bottom: 37px;
}
.commentst_content p {
  color: #0d1218;
}
.comments_thumb {
  float: left;
  margin-right: 20px;
  overflow: hidden;
}
.commentst_content {
  overflow: hidden;
}
.blog_comments_section .post_meta {
  margin-bottom: 6px;
}
.blog_comments_section .post_meta span {
  font-weight: 400;
  padding-right: 15px;
  color: #6d6d6d;
}
.blog_comments_section .post_meta span:hover {
  color: #03a9f4;
}
.blog_comments_section .post_meta span:last-child:before {
  display: none;
}
.commentst_meta_reply {
  float: right;
}
.commentst_meta_reply i {
  margin-right: 10px;
}
.commentst_meta_reply:hover {
  color: #03a9f4;
}
.single_commentst_inner {
  margin-bottom: 44px;
  margin-top: 46px;
  padding-left: 63px;
}
.badmin i {
  margin-right: 3px;
}

.blog_reply {
  overflow: hidden;
  margin-top: 50px;
  padding: 20px 15px 37px;
  box-shadow: 0 7px 16px rgba(0, 0, 0, 0.15);
}
.reply_ttl > h3 {
  font-size: 24px;
  color: #0d1218;
  font-weight: 700;
}
.reply_ttl {
  margin-bottom: 36px;
}
.blog_reply .em_contact_form {
  margin-bottom: 0;
}
.blog_reply .contact_bnt button:hover {
  background: #0d2352;
  color: #fff;
}

.request_form_title h2 {
  color: rgba(221, 221, 221, 0.79);
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 47px 0 7px;
}
.request_form_title h1 {
  color: rgba(255, 255, 255, 1);
  font-size: 35px;
  font-weight: 700;
  padding-bottom: 40px;
  text-transform: uppercase;
  margin: 0;
}

.blog_reply_coment_dtl .form_box input {
  border: 1px solid #e6e6e6;
  color: #495057;
  background-color: #fff;
}
.blog_reply_coment_dtl .form_box textarea {
  border: 1px solid #e6e6e6;
  color: #495057;
  background-color: #fff;
}
.blog_reply_coment_dtl .quote_btn button {
  background: #0c5adb;
  color: #fff;
}
.blog_reply_coment_dtl .form_box input:focus,
.blog_reply_coment_dtl .form_box textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.paginations {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 28px;
}
.page-numbers li {
  display: inline-block;
}
.paginations a,
.page-numbers span.current {
  width: 35px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin: auto 5px;
  border: 1px solid #ddd;
  color: #888;
}
.paginations a:hover,
.paginations a.current,
.page-numbers span.current {
  background: #0c5adb;
  border-color: #0c5adb;
  color: #fff;
}
.wedget_sideber_thumb img {
  width: 100%;
}
.widget_about_thumb {
  text-align: center;
}
.widget_about_content {
  text-align: center;
}
.widget_about_content h5 {
  font-weight: 700;
  padding-bottom: 3px;
}
.widget_about_icon {
  text-align: center;
}
.widget_about_icon a {
  display: inline-block;
  margin: 0 3px;
}

.single_team_details {
  text-align: center;
  padding: 32px 0 18px;
  box-shadow: 0 10px 30px 0 rgba(17, 12, 79, 0.1);
  background: #fff;
  margin-bottom: 30px;
}
.single_team_details_content_inner {
  padding: 10px 0 15px;
}
.single_team_details_content_inner h2 {
  font-size: 24px;
}
.single_team_details_content_inner span {
  color: #0c5adb;
}
.single_team_details_icon a {
  font-size: 16px;
  background: #0c5adb;
  height: 32px;
  width: 32px;
  display: inline-block;
  text-align: center;
  line-height: 32px;
  color: #fff;
  border-radius: 5px;
  margin: 3px;
}
.single_team_details_phone {
  padding-top: 20px;
}
.single_team_details_phone_icon i {
  font-size: 30px;
  color: #0c5adb;
}
.single_team_details_phone .number p {
  font-size: 18px;
}
.single_team_details_overview {
  margin-left: 30px;
}
.single_team_details_overview_content_text .overview_text {
  font-size: 18px;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 5px;
}

.team_style_two {
  position: relative;
  transition: 0.5s;
}
.team_style_two:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 90, 219, 0.85);
  content: "";
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 100%;
}
.team_style_two_thumb img {
  width: 100%;
  border-radius: 100%;
}
.team_style_two_content {
  position: absolute;
  left: 0;
  top: 62%;
  transform: translateY(-50%);
  text-align: center;
  right: 0;
  transition: 0.5s;
  transition-delay: 0.3s;
  opacity: 0;
}
.team_style_two_title h5 {
  color: #fff;
  font-weight: 700;
  display: block;
  padding-bottom: 5px;
}
.team_style_two_title span {
  color: #fff;
}
.team_style_two_icon a {
  display: inline-block;
  color: #fff;
  margin: 0 5px;
  font-size: 14px;
}
.team_style_two:hover .team_style_two_content {
  top: 50%;
  opacity: 1;
}
.team_style_two:hover:before {
  opacity: 1;
}

.single_team_thumb1 {
  position: relative;
  z-index: 1;
}
.single_team_thumb1::before {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  background-image: -webkit-linear-gradient(-9deg, #533eff 0%, #740fd7 97%);
  content: "";
  transition: 0.5s;
  width: 0%;
  opacity: 0.6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.single_team_thumb1:hover:before {
  width: 100%;
  left: 0;
}
.single_team_thumb1 img {
  width: 100%;
}

.single_team_content {
  transition: 0.5s;
  background: #fff;
  padding: 12px 20px 30px;
  text-align: center;
  border-bottom: 2px solid #0c5adb;
}
.single_team_content span {
  color: #0c5adb;
  padding-top: 2px;
  display: inline-block;
}
.single_team_icon1 {
  padding: 11px 0 0;
}
.single_team_icon1 a i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #0056b3;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  margin-right: 10px;
}

.row.vedio-bg {
  background: url(./images/vedio-seo-.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  border-radius: 5px;
  padding: 50px 0 65px;
  position: relative;
  margin-bottom: -175px;
}
.techno-vedio-title h2 {
  font-size: 30px;
  color: #fff;
}
.techno-vedio-title p {
  color: #fff;
  padding: 20px 0 15px;
  line-height: 30px;
}

.faq-thumb {
  position: relative;
  left: -106px;
}
.faq-thumb img {
  width: 100%;
}

ul.accordion {
  height: 510px;
}
.accordion li {
  position: relative;
  list-style: none;
  padding: 2px 0px 15px;
}
.accordion li a {
  width: 100%;
  display: block;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  color: #232323;
}
.accordion li p {
  display: none;
  font-size: 16px;
  color: #828fa0;
  padding-top: 20px;
  margin: 0;
}
.accordion a:before {
  width: 2px;
  height: 12px;
  background: #232323;
  position: absolute;
  right: 43px;
  content: " ";
  top: 12px;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}
.accordion a:after {
  width: 12px;
  height: 2px;
  background: #232323;
  position: absolute;
  right: 38px;
  content: " ";
  top: 17px;
  transition: all 0.2s ease-in-out;
}
a.active:after {
  transform: rotate(0deg);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: #1e54e7 !important;
}
a.active:before {
  display: none;
}
a.active {
  color: #1e54e7 !important;
}

.skill_area {
  padding: 80px 0 70px;
}
.our_skill {
  margin-bottom: 30px;
}
.skill-wrapper h5 {
  font-size: 18px;
  padding: 15px 0 12px;
}

.call_do_thumb img {
  width: 100%;
}

.error_page_area {
  padding: 65px 0 98px;
}
.single_error_inner {
  text-align: center;
}
.single_error_inner h1 {
  font-size: 150px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 30px;
}
.single_error_inner h1 span {
  color: #0c5adb;
}
.single_error_inner h2 {
  padding-bottom: 16px;
  font-size: 28px;
}
.single_error_button {
  margin-top: 30px;
}
.single_error_button a {
  background: #fff;
  color: #0c5adb;
  padding: 11px 26px;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
  border: 2px solid #0c5adb;
}
.single_error_button a:before {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background: #0c5adb;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: -1;
}
.single_error_button a:hover {
  color: #fff;
}
.single_error_button a:hover:before {
  width: 180%;
}
.input-group.error-page form {
  width: 64%;
}
.input-group.error-page input:focus {
  border: 2px solid #0c5adb !important;
  background: transparent;
}
#scrollUp {
  background: #0c5adb none repeat scroll 0 0;
  bottom: 30px;
  color: #fff;
  font-size: 22px;
  height: 40px;
  line-height: 40px;
  right: 100px;
  text-align: center;
  width: 40px;
  border-radius: 50%;
}
.intro__bg,
.intro__bg canvas {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bounce-animate {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
.bounce-animate2 {
  animation-name: float-bob2;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob2;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob2;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob2;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
.bounce-animate3 {
  animation-name: float-bob3;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob3;
  -moz-animation-duration: 4s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob3;
  -ms-animation-duration: 4s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob3;
  -o-animation-duration: 4s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
.bounce-animate4 {
  animation-name: float-bob4;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob4;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob4;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob4;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
.bounce-animate5 {
  animation-name: float-bob5;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob5;
  -moz-animation-duration: 6s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob5;
  -ms-animation-duration: 6s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob5;
  -o-animation-duration: 6s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@-webkit-keyframes float-bob2 {
  0% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
}
@-webkit-keyframes float-bob3 {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  50% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-webkit-keyframes float-bob4 {
  0% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
  50% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
  }
  100% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }
}
@-webkit-keyframes float-bob5 {
  0% {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
  }
  50% {
    -webkit-transform: translateY(-35px);
    transform: translateY(-35px);
  }
  100% {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
  }
}
@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

#spinnerbtn {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0.7;
  position: relative;
  height: 100px;
  width: 100px;
  background: none;
  border-radius: 100px;
  border-top: 10px solid #0c5adb;
  border-bottom: 10px solid #0c5adb;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  -webkit-animation: spin 10s infinite alternate linear,
    glow 5s infinite alternate linear;
  -moz-animation: spin 10s infinite alternate linear,
    glow 5s infinite alternate linear;
  -ms-animation: spin 10s infinite alternate linear,
    glow 5s infinite alternate linear;
  -o-animation: spin 10s infinite alternate linear,
    glow 5s infinite alternate linear;
  animation: spin 10s infinite alternate linear,
    glow 5s infinite alternate linear;
}

#spinnerbtn:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: none;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: -1;
  border-radius: 115px;
  border: 15px solid #0c5adb;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  -webkit-animation: spin2 5s infinite alternate linear,
    glow 3s infinite alternate linear;
  -moz-animation: spin2 5s infinite alternate linear,
    glow 3s infinite alternate linear;
  -ms-animation: spin2 5s infinite alternate linear,
    glow 3s infinite alternate linear;
  -o-animation: spin2 5s infinite alternate linear,
    glow 3s infinite alternate linear;
  animation: spin2 5s infinite alternate linear,
    glow 3s infinite alternate linear;
}
.white-color #spinnerbtn {
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.white-color #spinnerbtn:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: none;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: -1;
  border-radius: 115px;
  border: 15px solid #fff;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
.white-color .data_science_video_inner a i {
  color: #fff;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes spin2 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin2 {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes spin2 {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spin2 {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes glow {
  0% {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  50% {
    -webkit-box-shadow: 10px -10px 30px #0c5adb, -10px 10px 30px #0c5adb;
    box-shadow: 10px -10px 30px #0c5adb, -10px 10px 30px #0c5adb;
  }
  100% {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
@-moz-keyframes glow {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 10px -10px 30px #0c5adb, -10px 10px 30px #0c5adb,
      inset 10px -10px 30px #0c5adb, inset -10px 10px 30px #0c5adb;
  }
  100% {
    box-shadow: none;
  }
}
@-webkit-keyframes glow {
  0% {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  50% {
    -webkit-box-shadow: 10px -10px 30px #0c5adb, -10px 10px 30px #0c5adb,
      inset 10px -10px 30px #0c5adb, inset -10px 10px 30px #0c5adb;
    box-shadow: 10px -10px 30px #0c5adb, -10px 10px 30px #0c5adb,
      inset 10px -10px 30px #0c5adb, inset -10px 10px 30px #0c5adb;
  }
  100% {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
@-ms-keyframes glow {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 10px -10px 30px #0c5adb, -10px 10px 30px #0c5adb,
      inset 10px -10px 30px #0c5adb, inset -10px 10px 30px #0c5adb;
  }
  100% {
    box-shadow: none;
  }
}
@-o-keyframes glow {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 10px -10px 30px #0c5adb, -10px 10px 30px #0c5adb,
      inset 10px -10px 30px #0c5adb, inset -10px 10px 30px #0c5adb;
  }
  100% {
    box-shadow: none;
  }
}

.data_science_video1 {
  position: relative;
  left: 44%;
}
.data_science_video_inner1 a i {
  position: absolute;
  left: 52px;
  font-size: 32px;
  color: #fff;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.white-color1 #spinnerbtn {
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  border-left: 10px solid #00247e;
  border-right: 10px solid #00247e;
}
.white-color1 #spinnerbtn:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: none;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: -1;
  border-radius: 115px;
  border: 15px solid #fff;
  border-top: 15px solid #00247e;
  border-bottom: 15px solid #00247f;
}
.tencompa {
  background-color: white;
  padding: 20px;
}
.blur {
  filter: blur(3px);
  opacity: 0.7;
}
.lock {
  margin-top: -50px;
  font-size: 22px;
  margin-left: 30px;
}

.locktable {
  color: #9e9e9e !important;
}

.dropright .dropdown-toggle::after {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.dropdown-menu.show {
  left: 10px ;
  top: -10px ;
  width: 220px;
  height: 300px;
  overflow-y: scroll;
}
.natio.show {
  height: auto !important;
}
.list-unstyled {
  padding-left: 15px;
}
.drop {
  height: auto;
}
.fundit {
  margin-bottom: 0 !important;
}
/* .modal-header {
  background: #f8f8f8;
  display: block !important;
  border-bottom: 0 !important;
}
.modal-title {
  text-align: center;
} */
.modal-body {
  background-color: #366196;
  border-color: #366196;
}
.modal-content {
  background-color: #366196;
  border-color: #366196;
}

.intro-1 {
  font-size: 20px
}

.close {
  color: #fff
}

.close:hover {
  color: #fff
}

.intro-2 {
  font-size: 13px
}

.btn-primary {
  color: #5165ff;
  background-color: #fffaff;
  border-color: #fffaff;
  padding: 12px;
  font-weight: 700;
  border-radius: 41px;
  padding-right: 20px;
  padding-left: 20px;
}
.titlle {
  font-size: 15px;
  font-weight: 400;
  color: #3c444e;
}
.x {
  background: #f8f8f8;
}
.toregi {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #3c444e;
}
.description {
  font-size: 15px;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 400;
  margin-top: 10px;
}
.modal-body {
  text-align: center;
}
.modali{
  cursor: pointer;
}
.registerbutt {
  width: 150px;
  height: 48px;
  border: none;
  outline: none;
  background: white;
  color: #366196;
  font-weight: 500;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.acount {
  font-weight: 700;
  font-size: 12px;
  margin-top: 20px;
  color: #0056b3;
}
.spani {
  background: white;
  border-radius: 50%;
  margin-top: -10px;
  width: 35px;
}

.dropdown-menu.show::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}
.dropdown-menu.show::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #c6c9d0;
}



.square-holder {
  align-items: center;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom:20px;
  margin-top:20px;

}

.square-holder img {
  max-width:100%;
  filter: grayscale(100%);
  transition: all 0.3s;
}

.square-holder:hover img{
  filter:none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tw-animate-spin {
  animation: spin 1s linear infinite;
}

.btn-search:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.bi-arrow-repeat {
  color: #fff;
}
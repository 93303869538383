.custom-checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
    user-select: none;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: background-color 0.3s, border-color 0.3s;
}

.custom-checkbox.selected {
    background-color: #007bff;
    border-color: #007bff;
}

.custom-checkbox .checkmark {
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.custom-checkbox-label {
    font-size: 16px;
    color: #333;
}

.custom-checkbox-container:hover .custom-checkbox {
    border-color: #007bff;
}

/* Modern Navbar2 Styling */
.header_top_menu {
  background: #366196;
  border-bottom: 1px solid #2e4a71;
  padding: 8px 0;
  height: 40px;
  display: flex;
  align-items: center;
}

.header_top_menu_text {
  display: flex;
  align-items: center;
  gap: 24px;
  height: 100%;
}

.pir {
  color: white !important;
  margin: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.pir i {
  font-size: 16px;
  color: white;
}

.header_top_menu_icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

/* Main Navbar */
.navbar2.navbar {
  background: #2e4a71 !important;
  border-bottom: 2px solid #366196;
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 0;
}

.navbar2 .container {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar2 .navbar-brand {
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar2 .logoja {
  width: 180px;
  height: auto;
}

.navbar2 .nav-link {
  color: white !important;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  transition: color 0.2s ease;
}

.navbar2 .nav-link:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

.navbar2 .nav-link.navlinka {
  position: relative;
}

.navbar2 .nav-link.navlinka i {
  margin-right: 8px;
  font-size: 18px;
}

.navbar2 .navbar-toggler {
  color: white !important;
  background: #366196;
  border: none;
  padding: 8px;
}

.navbar2 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar2 .navbar-toggler:focus {
  box-shadow: none;
}

/* User Menu */
.navbar2 .dropdown-toggle.huh {
  background: white;
  border: none;
  color: #366196;
  padding: 8px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  height: 40px;
  transition: all 0.2s ease;
}

.navbar2 .dropdown-toggle.huh:hover {
  background: #f8fafc;
  color: #2e4a71;
}

.navbar2 .dropdown-menu {
  background: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  padding: 8px 0;
}

.navbar2 .dropdown-item {
  padding: 8px 16px;
  color: #2e4a71;
  transition: all 0.2s ease;
  font-size: 14px;
}

.navbar2 .dropdown-item:hover {
  background: rgba(54, 97, 150, 0.1);
  color: #366196;
}

.navbar2 .dropdown-divider {
  margin: 8px 0;
  border-color: #eee;
}

/* Login Button */
.navbar2 .login-btn {
  background: #366196;
  color: white !important;
  border: none;
  padding: 8px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 40px;
  margin-left: 8px;
}

.navbar2 .login-btn:hover {
  background: #2e4a71;
  transform: translateY(-1px);
}

/* Responsive Styles */
@media (max-width: 767px) {
  .header_top_menu_text {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }

  .header_top_menu_icon {
    justify-content: flex-start;
    margin-top: 8px;
  }

  .navbar2 {
    height: auto;
    padding: 0.5rem 1rem !important;
  }

  .navbar2 .navbar-collapse {
    background: white;
    margin: 0 -1rem;
    padding: 1rem;
    border-top: 1px solid #eee;
  }

  .navbar2 .nav-link.navlinka {
    height: auto;
    padding: 12px 16px !important;
  }

  .navbar2 .login-btn {
    margin: 8px 16px;
  }
}
.navigation {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background: white;
    color: black;
  }
 
  @media (min-width:767px){
nav{
  height: 65px;

}

.navbar-expand-sm .navbar-collapse{
  justify-content: flex-end!important;
  align-items: center;
}
.navbar-expand-sm .navbar-nav .nav-link{
  padding-right: 2.5rem!important;
}
  }

  .nav-link{
    color: rgb(0, 0, 0)!important;
   font-weight: 500;
  }
  .nav-link:hover{
    color: rgba(146, 152, 159, 0.768)!important;
  }


  .navbar-toggler{
    color: rgb(0, 0, 0)!important;
    background: #366196;
  }
  nav{
    background: white!important;
    border-bottom: 2px solid #2e4a71;
    height: 65px;
    display: flex;
    align-items: center;
  }
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  .navigation-menu {
    margin-left: auto;
  }
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }
  .logoja{
      width: 180px;
  }

  .is-light{
    background: transparent;
    color: #2e4a71!important;
    font-size: 16px;
    align-items: center;
    display: flex;
    margin-right: 30px;
font-weight: 500;
    cursor: pointer;
    margin-left: -10px;

  }
  .is-light:hover{
   color: rgba(146, 152, 159, 0.768);

  }
  .log{
      margin-right: 10px;
  }
  .bi-box-arrow-right{
    color: #2e4a71!important;
    font-size: 25px;
  }
  

  .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
  }
   
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 30px!important;
    float: right;
  }
  .per{
    color: #366196!important;
    font-size: 20px;
    margin-right: 5px!important;
  }
  .lista {
    height: 65px !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
  }

  .isi{
    height: 65px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #366196 !important;
    color: white;
    padding: 0 20px;
    font-weight: 500;
    border: none;
    gap: 8px;
    transition: background-color 0.3s ease;
  }
  .isi:hover{
  background-color: #2e4a71 !important;
  color: #fff;
  }

  .list-builder-text {
    font-size: 14px;
    font-weight: 500;
  }

  .icon-equalizer {
    font-size: 18px;
  }

  .user-profile-wrapper {
    margin-left: 15px;
    position: relative;
  }

  .user-profile-button {
    background: transparent;
    border: none;
    color: #2e4a71;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    transition: all 0.3s ease;
  }

  .user-profile-button:hover {
    color: #366196;
    background-color: rgba(54, 97, 150, 0.1);
    border-radius: 4px;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .user-name {
    font-size: 14px;
    font-weight: 500;
  }

  .bi-person.per {
    font-size: 20px;
    color: #366196;
  }

  .dropdown-menu.account {
    min-width: 160px;
    padding: 8px 0;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }

  .dropdown-menu.account .dropdown-item {
    padding: 8px 16px;
    font-size: 14px;
    color: #333;
    transition: background-color 0.2s ease;
  }

  .dropdown-menu.account .dropdown-item:hover {
    background-color: rgba(54, 97, 150, 0.1);
  }

  .logout-button {
    background: transparent;
    border: none;
    color: #dc3545;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    text-align: left;
    font-size: 14px;
  }

  .logout-button:hover {
    color: #c82333;
  }

  .logout-button i {
    font-size: 16px;
  }

  @media (max-width:767px){
    .logoja{
      width: 130px!important;
    }
    .lista{
      height: auto !important;
      margin: 10px 0 !important;
    }
    .isi{
      height: auto !important;
      padding: 12px 15px !important;
      background-color: transparent !important;
      color: #2e4a71 !important;
      justify-content: flex-start;
    }
    .list-builder-text {
      color: #2e4a71;
    }
    .navbar-nav{
      background: #3661960a;
      padding: 10px 0;
    }
    .navbar-toggler-icon{
      width: 1em!important;
      height: 1em!important;
    }
    nav{
      background: white!important;
      height: auto !important;
      min-height: 65px;
    }
    .user-profile-wrapper {
      margin: 10px 0;
    }
    .user-profile-button {
      width: 100%;
      justify-content: flex-start;
    }
    .dropdown-menu.account {
      position: static !important;
      width: 100% !important;
      margin-top: 5px;
    }
  }
  .account.show{
    top: 30px!important;
    left: -20px!important;
    width: 100px!important;
    height: auto!important;
  }
  .huh{
margin-top:5px!important;
  }
  .huh::after{
    transform: rotate(45deg)!important;
    -webkit-transform: rotate(45deg)!important;
    margin-left: 7px;
  }


  /* Shopping Cart Styling */
.shopping-cart-wrapper {
  height: 65px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.shopping-cart-button {
  background-color: #366196 !important;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0;
}

.shopping-cart-button:hover {
  background-color: #2e4a71 !important;
}

.shopping-cart-button svg {
  width: 20px;
  height: 20px;
  color: white !important;
  fill: white !important;
}

@media (max-width: 767px) {
  .shopping-cart-wrapper {
    height: 40px;
    width: auto;
    margin: 0;
  }

  .shopping-cart-button {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    background-color: #366196 !important;
  }

  .shopping-cart-button svg {
    color: white !important;
    fill: white !important;
  }
}

  /* Language Selector Styling */
.language-selector {
  position: relative;
  height: 65px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  z-index: 1000;
}

.lang-button {
  background-color: #366196 !important;
  color: white !important;
  border: none;
  height: 40px;
  padding: 0 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lang-button:hover {
  background-color: #2e4a71 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.lang-button i {
  color: white !important;
}

.lang-button span {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: white !important;
  display: flex;
  align-items: center;
}

.lang-button .bi-globe2 {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.lang-button .arrow {
  transition: transform 0.3s ease;
  margin-left: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.lang-button .arrow.open {
  transform: rotate(180deg);
}

.lang-dropdown {
  position: absolute;
  top: calc(100% - 10px);
  right: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-width: 180px;
  border: 1px solid rgba(54, 97, 150, 0.1);
  animation: slideDown 0.3s ease;
  transform-origin: top right;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.lang-option {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #366196;
  font-weight: 500;
  border-bottom: 1px solid rgba(54, 97, 150, 0.1);
}

.lang-option:last-child {
  border-bottom: none;
}

.lang-option:hover {
  background: rgba(54, 97, 150, 0.1);
  color: #2e4a71;
}

.lang-option .flag {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.bi-globe2 {
  font-size: 18px;
  margin-right: 2px;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .language-selector {
    height: 40px;
    margin: 10px 0;
    width: 100%;
    justify-content: center;
  }

  .lang-button {
    width: 100%;
    max-width: 200px;
    justify-content: center;
  }

  .lang-dropdown {
    width: 100%;
    max-width: 200px;
    top: calc(100% + 5px);
    right: 50%;
    transform: translateX(50%);
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateX(50%) translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(50%) translateY(0);
    }
  }
}

  .navbar {
    background: white !important;
    border-bottom: 2px solid #2e4a71;
    height: 65px;
    padding: 0 1rem !important;
  }

  .navbar-brand {
    padding: 0;
    margin: 0;
    height: 65px;
    display: flex;
    align-items: center;
  }

  .logoja {
    width: 180px;
    height: auto;
  }

  .navbar-collapse {
    height: 65px;
  }

  .navbar-nav {
    height: 65px;
    display: flex;
    align-items: center;
  }

  .nav-link {
    height: 65px;
    display: flex;
    align-items: center;
    padding: 0 1.5rem !important;
    color: rgb(0, 0, 0) !important;
    font-weight: 500;
  }

  .nav-link:hover {
    color: rgba(146, 152, 159, 0.768) !important;
  }

  .lista {
    height: 65px !important;
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
  }

  .isi {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #366196 !important;
    color: white;
    padding: 0 20px;
    font-weight: 500;
    border: none;
    gap: 8px;
    transition: background-color 0.3s ease;
  }

  .isi:hover {
    background-color: #2e4a71 !important;
    color: #fff;
  }

  .user-profile-wrapper {
    height: 65px;
    display: flex;
    align-items: center;
    margin-left: 15px;
  }

  .user-profile-button {
    height: 65px;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #2e4a71;
    padding: 0 15px;
    gap: 8px;
    font-weight: 500;
    transition: all 0.3s ease;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .d-flex.align-items-center {
    height: 65px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .language-selector {
    height: 65px;
    display: flex;
    align-items: center;
  }

  .lang-button {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: transparent;
    border: 1px solid #2e4a71;
    padding: 0 1rem;
    border-radius: 4px;
    color: #2e4a71;
    cursor: pointer;
  }

  .shopping-cart-wrapper {
    height: 65px;
    display: flex;
    align-items: center;
  }

  .shopping-cart-button {
    height: 40px;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #2e4a71;
    cursor: pointer;
    padding: 0.5rem;
  }

  @media (max-width:767px){
    .navbar {
      height: auto;
      min-height: 65px;
      padding: 0.5rem 1rem !important;
    }

    .navbar-brand {
      height: 50px;
    }

    .logoja {
      width: 130px;
    }

    .navbar-collapse {
      height: auto;
      background: #f8f9fa;
      padding: 1rem 0;
    }

    .navbar-nav {
      height: auto;
      flex-direction: column;
      gap: 10px;
    }

    .nav-link {
      height: 40px;
      justify-content: flex-start;
      padding: 0 1rem !important;
    }

    .lista {
      height: 40px !important;
      width: 100%;
    }

    .isi {
      height: 40px;
      width: 100%;
      background-color: transparent !important;
      color: #2e4a71 !important;
      justify-content: flex-start;
    }

    .user-profile-wrapper,
    .user-profile-button {
      height: 40px;
      width: 100%;
    }

    .d-flex.align-items-center {
      height: auto;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      padding: 1rem 0;
    }

    .language-selector,
    .shopping-cart-wrapper {
      height: 40px;
      width: 100%;
    }

    .lang-button,
    .shopping-cart-button {
      width: 100%;
      justify-content: center;
    }
    .shopping-cart-wrapper {
      height: 40px;
      width: auto;
      margin: 0;
    }

    .shopping-cart-button {
      width: 40px;
      height: 40px;
      margin: 0 auto;
    }
  }

@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');


.hero{
  height: 100vh;
}
.login {
  width: 450px;
  padding: 2% 0 0;
  margin: auto;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 450px;
  margin: 100px auto 100px;
  padding: 45px;
  text-align: center;

}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 30px 0 0px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.form input:focus {
  background: #dbdbdb;
}

.form button {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4b6cb7;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-top: 30px;
  font-weight: 600;
}

.form button:active {
  background: #395591;
}

.material-icons {
  font-size: 55px!important;
  color: #4b6cb7;
}
.kseco{
    width: 250px;
}
.logo{
text-align: center;
}
@media only screen and (max-width: 700px) {
    .login {
      padding: 2% 0 0;
      margin: 40px;
      width: 300px;
    }
.form{
    width: 320px!important;
    margin: 0!important;
    padding: 30px!important;
}
.close-icon{
  font-size: 20px!important;
}
}
.close-icon{
  color: #395591!important;
  font-size: 20px!important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply tw-font-normal tw-text-base tw-text-body tw-bg-whiten tw-relative;
  }
}

@layer components {
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply tw-h-[calc(100vh_-_8.125rem)] lg:tw-h-[calc(100vh_-_5.625rem)];
  }
  .inbox-height {
    @apply tw-h-[calc(100vh_-_8.125rem)] lg:tw-h-[calc(100vh_-_5.625rem)];
  }
}

/* third-party libraries CSS */

.tableCheckbox:checked ~ div span {
  @apply tw-opacity-100;
}
.tableCheckbox:checked ~ div {
  @apply tw-bg-primary tw-border-primary;
}

.apexcharts-legend-text {
  @apply !tw-text-body dark:!tw-text-bodydark;
}
.apexcharts-text {
  @apply !tw-fill-body dark:!tw-fill-bodydark;
}
.apexcharts-xcrosshairs {
  @apply !tw-fill-stroke dark:!tw-fill-strokedark;
}
.apexcharts-gridline {
  @apply !tw-stroke-stroke dark:!tw-stroke-strokedark;
}
.apexcharts-series.apexcharts-pie-series path {
  @apply dark:!tw-stroke-transparent;
}
.apexcharts-legend-series {
  @apply !tw-inline-flex tw-gap-1.5;
}
.apexcharts-tooltip.apexcharts-theme-light {
  @apply dark:!tw-border-strokedark dark:!tw-bg-boxdark;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  @apply dark:!tw-border-strokedark dark:!tw-bg-meta-4;
}
.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  @apply dark:!tw-border-meta-4 dark:!tw-bg-meta-4 dark:!tw-text-bodydark1;
}
.apexcharts-xaxistooltip-bottom:after {
  @apply !tw-border-b-gray dark:!tw-border-b-meta-4;
}
.apexcharts-xaxistooltip-bottom:before {
  @apply !tw-border-b-gray dark:!tw-border-b-meta-4;
}
.apexcharts-xaxistooltip-bottom {
  @apply !tw-rounded !tw-border-none !tw-bg-gray !tw-text-xs !tw-font-medium !tw-text-black dark:!tw-text-white;
}
.apexcharts-tooltip-series-group {
  @apply !tw-pl-1.5;
}

.flatpickr-wrapper {
  @apply tw-w-full;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  @apply !tw-fill-primary;
}
.flatpickr-calendar.arrowTop:before {
  @apply dark:!tw-border-b-boxdark;
}
.flatpickr-calendar.arrowTop:after {
  @apply dark:!tw-border-b-boxdark;
}
.flatpickr-calendar {
  @apply !tw-p-6 dark:!tw-bg-boxdark dark:!tw-text-bodydark dark:!tw-shadow-8 2xsm:!tw-w-auto;
}
.flatpickr-day {
  @apply dark:!tw-text-bodydark dark:hover:!tw-border-meta-4 dark:hover:!tw-bg-meta-4;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply !tw-top-7 dark:!tw-fill-white dark:!tw-text-white;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  @apply !tw-left-7;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  @apply !tw-right-7;
}
span.flatpickr-weekday,
.flatpickr-months .flatpickr-month {
  @apply dark:!tw-fill-white dark:!tw-text-white;
}
.flatpickr-day.inRange {
  @apply dark:!tw-shadow-7;
  box-shadow: -5px 0 0 #EFF4FB, 5px 0 0 #EFF4FB;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  @apply tw-border-gray tw-bg-gray dark:!tw-border-meta-4 dark:!tw-bg-meta-4;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.selected,
.flatpickr-day.endRange {
  @apply dark:!tw-text-white;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #3c50e0;
  @apply !tw-border-primary !tw-bg-primary hover:!tw-border-primary hover:!tw-bg-primary;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #3c50e0;
}

.map-btn .jvm-zoom-btn {
  @apply tw-flex tw-items-center tw-justify-center tw-w-7.5 tw-h-7.5 tw-rounded tw-border tw-border-stroke dark:tw-border-strokedark hover:tw-border-primary dark:hover:tw-border-primary tw-bg-white hover:tw-bg-primary tw-text-body hover:tw-text-white dark:tw-text-bodydark dark:hover:tw-text-white tw-text-2xl tw-leading-none tw-px-0 tw-pt-0 tw-pb-0.5;
}
.mapOne .jvm-zoom-btn {
  @apply tw-left-auto tw-top-auto tw-bottom-0;
}
.mapOne .jvm-zoom-btn.jvm-zoomin {
  @apply tw-right-10;
}
.mapOne .jvm-zoom-btn.jvm-zoomout {
  @apply tw-right-0;
}
.mapTwo .jvm-zoom-btn {
  @apply tw-top-auto tw-bottom-0;
}
.mapTwo .jvm-zoom-btn.jvm-zoomin {
  @apply tw-left-0;
}
.mapTwo .jvm-zoom-btn.jvm-zoomout {
  @apply tw-left-10;
}

.taskCheckbox:checked ~ .box span {
  @apply tw-opacity-100;
}
.taskCheckbox:checked ~ p {
  @apply tw-line-through;
}
.taskCheckbox:checked ~ .box {
  @apply tw-bg-primary tw-border-primary dark:tw-border-primary;
}

.custom-input-date::-webkit-calendar-picker-indicator {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}
.custom-input-date-1::-webkit-calendar-picker-indicator {
  background-image: url(../../../../public/images/icon/icon-calendar.svg);
}
.custom-input-date-2::-webkit-calendar-picker-indicator {
  background-image: url(../../../../public/images/icon/icon-arrow-down.svg);
}

[x-cloak] {
  display: none !important;
}
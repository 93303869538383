@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400&display=swap');

body{
    font-family:"Roboto";
    overflow-x: hidden!important;
}


.text-contant{
    margin-top: -200px;
}
.poa{
  font-size: 17px;
}
.geo{
  margin-right: 5px!important;
  color: black;
}
/* .ff{
    margin-top:-100px;
    display:flex;
} */

.ff {
  margin-top: -100px; /* Adjust if needed */
  display: flex;
  flex-wrap: wrap; /* Ensure columns wrap correctly */
  justify-content: center;
  margin-bottom: 0;
}


.text-contant{
    margin-top: -200px;
}
.filn{
    text-align: center;
    margin-top: 30px;
}
.typewrite{
    font-size: 26px;margin-top:20px;
}
.wrap{
    font-size: 30px;
}
.teksti{
    width: 1000px;margin-left: 220px;font-size: 16px;
}
.type{
  position: absolute!important;
}
.tek{
  margin-top: 100px;
}
.hi{
    text-align: center;
}
.section-heading{
    margin-top:20px;
}
.d-print-none{
    height: 500px;
    justify-content: space-between;
}
.one{
    margin-left: 50px;
}
.reklama{
    margin-top: -100px;
    margin-left: 10px;
    margin-right: 10px;
}
.use{
    text-align: center;
    font-weight: 700;
}
.f{
    position:relative;left:50px;
}
.sidebar-brand{
    display: flex;
}
.filt{
    font-size:10px;margin-left:60px;padding-top: 10px;
}
.fa{
    color: #fff;margin-right: 10px;
}
.fa-lock{
  color: #4b6cb7!important;
}
.fas{
    color: #345c8e;margin-right: 10px;
}
.sidebar-title:hover{
    text-decoration: none;
}
.dropdown-toggle{
    justify-content: start;
}
.kl path{
    fill: #355f92;
    filter: drop-shadow( 3px 3px 8px rgb(145, 155, 99));
   
    opacity: 1!important;   
}

.stat{
    margin-top: 150px;
}
.lajm{
    width: 730px;
    margin-top: 20px;
}
.se{
    width:50px;margin-left:300px
}
.owl-stage{
    transform: translate3d(-1527px, 0px, 0px); transition: all 0.25s ease 0s; width: 3334px;
}
.owl-item .cloned{
    width: 128.906px; margin-right: 10px;
}
.item{
    text-align:center;margin:10px;
}

.fun-facts {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 100px 0; /* Adjusted padding */
  color: #fff;
  background: linear-gradient(5deg, #23344c 20%, #366196 90%);
  height: auto; /* Adjusted to auto height */
  margin-bottom: 50px;
}

/* .fun-facts {

	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding: 140px 0px;
	color: #fff;
	background:linear-gradient(5deg, #23344c 20%, #366196 90%);
    height: 600px;

} */

.fun-facts span {
	text-transform: uppercase;
	font-size: 40px;
	color: #fff;
	letter-spacing: 1px;
	margin-bottom: 10px;
	display: block;
  font-weight: 700!important;
}

.fun-facts h2 {
	font-size: 36px;
	font-weight: 600;
	margin-bottom: 35px;
}
.dropdown-item:focus{
	background-color: transparent!important;
border-color: green!important;
}

.btn-primary:focus{
background-color: transparent!important;
border-color: green!important;
}
.btn-primary{
	background-color: transparent!important;
	}

.fun-facts em {
	font-style: normal;
	color: #b6d1a9;
}

.fun-facts p {
	color: #fff;
	margin-bottom: 40px;
}

.fun-facts .left-content {
	margin-right: 45px;
}

.count-area-content {
	text-align: center;

	margin: 15px 0px;
}


.fun-facts .count-area-content {
  text-align: center;
  margin: 5px 0; 
}

.col-md-3.co {
  margin-bottom: 0;
}

/* .count-area-content {
  text-align: center;
  margin: 15px 0px;
} */

.count-digit {
  margin: 5px 0px;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
}

.count-title {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
}

/* Ensure responsiveness */
@media (max-width: 768px) {
  .col-md-3.co {
    margin-bottom: 10px; /* Adjust margin as needed for smaller screens */
  }
}


/* .count-digit {
    margin: 5px 0px;

    font-weight: 700;
    font-size: 40px;
	color: #fff;
}
.count-title {
    font-size: 16px;
    font-weight: 600!important;

    letter-spacing: 0.5px;
	color: #fff;
} */
.btn-search{
	width: 50px;
	height: 40px;
	border-style: none;
	font-size: 20px;
	font-weight: bold;
	outline: none;
	cursor: pointer;
	border-radius: 50%;
	position: absolute;
	right: 20px;
	color:#ffffff ;
	background-color:transparent;
	pointer-events: painted;  
  }
  .hom:hover{
    color: rgba(146, 152, 159, 0.768);
  }
  .input-search:focus{
	width: 550px;
	border-radius: 0px;
	background-color: transparent;
	border-bottom:1px solid rgb(255, 255, 255);
	outline: transparent!important;
	--tw-ring-color: #315684;
  }
  [type='text']:focus{
	--tw-ring-color: #315684!important; 
  }
  .search-box{
	width: fit-content;
	height: fit-content;
	position: relative;
  }
  .input-search{
	height: 50px;
	width: 550px;
	border-style: none;
	padding: 10px;
	font-size: 16px;
	letter-spacing: 2px;
	outline: none;
	transition: all .5s ease-in-out;
	padding-right: 40px;
	color:#fff;
	border-bottom:1px solid rgb(255, 255, 255);
	border-color:#ffffff!important;
  background-color: transparent;
  }
  .hom{
      color: white;
  }
  .h{
    justify-content: center!important;
  }
  .advanc{
    margin-right: 10px!important;
  }
  .input-search::placeholder{
	color:rgba(176, 176, 176, 0.5);
	font-size: 12px;
	font-weight: 300;
  font-family: Roboto;
  }
  .adv{
    background: transparent;
    border: none;
    color: white;
    border-bottom: 1px solid white;
}
.adv:hover{
  color:rgba(146, 152, 159, 0.768);
}
  .section-heading {
	text-align: center;
	margin-bottom: 80px;
}

.section-heading h2 {
	font-size: 36px;
	font-weight: 600;
	color: #1e1e1e;
  margin-top: 50px;
}

.section-heading em {
	font-style: normal;
	color: #315684
}

.section-heading span {
	display: block;
	margin-top: 15px;
	text-transform: uppercase;
	font-size: 15px;
	color: #666;
	letter-spacing: 1px;
}
@media (min-width:767px){
.homeTable{
  width: 120%!important;
}
.homeTable .head .text-left{
  font-size: 15px!important;
}
.dta{
  width: 200px;
}
.citc{
  width: 150px;
}
}
@media (max-width:767px){
  .se{
    margin-left: 180px!important;
    margin-top: -100px;
    width: 30px;
}
#root{
  overflow-x: hidden!important;
}
.nh{
  padding: 0.4em!important;
}
.nhome{
  margin-top: 200px;
}
.barch{
  margin-top: -170px;
  margin-bottom: 50px;
}
.tek{
  margin-top: 140px;
}
.fun-facts span{
  font-size: 35px;
}
.type{
  margin-top: 20px;
}
.container1 p{
  font-size: 14px!important;
}
.fac{
  height: auto!important;
}
.lajm{
  width: 200px!important;
  margin-top: -20px!important;
  margin-left: 100px!important;
  text-align: center;
}
.one{
  margin-top: -230px!important;
  margin-left: 70px!important;
}
  .reklama{
    display: none;
  }
  .fun-facts{
    height: 1100px;
  }
  .section-heading h2{
    font-size: 28px!important;
    margin-top: 30px!important;
  }
  .section-heading{
    margin-bottom: 30px!important;
  }
    h2{
        margin-left:0px!important;
    }
    .navbar-nav{
      text-align: center;
      align-items: center;
    }
    .is-light{
      margin-top: 10px!important;
      width: 120px;
      margin-right: 0!important;

    }
    .nav-link{
      border-bottom: 2px solid white;
      width: 350px;
    }
    .search-box{
        width: 200px!important;
    }
    .input-search{
      width:190px!important;
      padding: 5px!important;
    }
    .adv{
      font-size: 13px;
    }
    .input-search::placeholder{
      font-size: 10px!important;
    }
    .ff{
      display: block!important;
    }
    .col-md-8{
        display:block!important;
        margin-top:-20px!important
    }
  
    .teksti{
      width: 330px!important;
      margin-left: 16px!important;
    }
    .d-print-none{
        left:0!important;
        margin-left: 0px!important;
        overflow-y: unset!important;
        overflow-x: unset!important;
        width: 150px;
        display: block!important;
        height: 380px!important;
    }
    .sidebar-item{
      font-size: 0.8em!important;
    }
    .sidebar-nav{
      padding-left: 5px!important;
    }
  
    .contact-form{
      padding-left: 0!important;
    }
    .stat{
      margin-top: 0!important;
    }
    .kl{
      transform: scale(0.38)!important;
      margin-left: -340px!important;
      margin-top: -100px!important;
  }
  .what{
    display: block!important;
    padding: 50px!important;
  }
  .stats-container{
    height: 180px!important;
    margin-top: 60px!important;
  }
  .react-multi-carousel-item{
    width: 350px!important;
  }
  .use{
    margin-left: 100px;
    width: 200px;
  }
  .graph{
    display:block!important;
}
    }

canvas{
  height: 250px!important;
}


    .one{
    margin-top: 50px;
    }
    
    .graph{
        display:flex;
        align-items: center;
    }
    .harta{
        min-width:100%!important;
    }
    #chartdiv {
        width: 100%!important;
        height: 219px!important;
        margin-left: 0px!important;
    }
    .navbar-collapse{
        margin-right: 0px!important;
    }
    .fil{
        margin-left:50px!important;
        font-size: 30px!important;
    }
    .teksti{
      font-size: 14px!important;
    }
    


    #contact {
      width: 100%;
      background:#284060;
    }
    
    .section-header {
      text-align: center;
      margin: 0 auto;
      padding: 40px 0;
      font: 300 30px 'Oswald', sans-serif;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 6px;
    }
    
    .contact-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
      position: relative;
      max-width: 1200px;
    }
    

    .form-horizontal {
  
      max-width: 500px;
      font-family: 'Lato';
      font-weight: 400;
      margin-left:50px;
    }
    .btn-primary{
        border-color:#fff!important;
    }
    .owl-item {width: 128.906px; margin-right: 10px; background:#ececec; }
    @media screen and (max-width: 410px) {
        .send-button {
          width: 99%;
        }
      }
    
            .map{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              flex-direction: column;   
              margin-top:-100px;
          }
            .city-sec{
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              width: 550px;
              margin: 50px 0;
              align-items: flex-start;
            }
            .first-path::before {
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: rgb(81, 81, 238);
            }
            .second-path::before {
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: rgb(0, 102, 255);
            }
            .third-path::before {
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: rgb(0, 81, 255);
            }
            .four-path::before {
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #023e8a;
            }
            .five-path::before {
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #0077b6;
            }
            .six-path::before {
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #0096c7;
            }
            .seven-path::before {
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #00b4d8;
            }
            .eight-path::before {
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #48cae4;
            }
            .nine-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #90e0ef;
            }
            .ten-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #219ebc;
            }
            .eleven-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #3a86ff;
            }
            .twelve-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #013a63;
            }
            .thirteen-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #01497c;
            }
            .fourteen-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #2a6f97;
            }
            .fiveteen-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #92e6a7;
            }
            .sixteen-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #4ad66d;
            }
            .seventeen-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #25a244;
            }
            .eighteen-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #1a7431;
            }
            .nineteen-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #4a7c59;
            }
            .twenteen-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #06d6a0;
            }
            .twenteone-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #197278;
            }
            .twentetwo-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #00509d;
            }
            .twentethree-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #adfc92;
            }
            .twentefour-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #90f1ef;
            }
            .twentefive-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #adfc92;
            }
            .twentesix-path::before{
              content: "•";
              font-size: 30px;
              position: relative;
              bottom: -6px;
              padding-right: 3px;
              color: #07beb8;
            }
            .div{
              margin: 0 3px;
              font-size: 12px;
              color: rgb(65, 65, 65);
            }


.what{
    display: flex;
    justify-content: center;
    padding: 70px;
    background: linear-gradient(5deg, #23344c 20%, #366196 90%);
}
            .stats-container {
                margin: 20px;
                min-width: 200px;
                height: 150px;
                border-radius: 12px 12px 0 0;
                position: relative;
                padding: 20px;
                box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
              }
              .stats-container.orange {
                border-bottom: 3px solid #fff;
              }
              .stats-container.blue {
                border-bottom: 3px solid #fff;
              }
              .stats-container.red {
                border-bottom: 3px solid #fff
              }
              .stats-container.orange i {
                color: #fff;
                font-size: 50px!important;
              }
              .stats-container.red i {
                color: #fff;
                font-size: 50px;
              }
              .stats-container.blue i {
                color: #fff;
                font-size: 50px;
              }
        .do{
            color: white;
        }
              .stats-container h4 {
                position: absolute;
                bottom: 1px;
                font-weight: 400;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
                color: #d3d3d3;
              }
        
              .icon {
                position: absolute;
                top: -50px;
                left: 50%;
                transform: translateX(-50%);
              }


.contact-form {
    list-style-type: none;
}
.contact-form li div{
        margin-bottom: 2.5em;  
    }

   .inp{
    width: 100%;
    margin: 0;
    padding-bottom: 1.4em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 3px solid white;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: 700;
    font-size: 18px;
    transition: ease-in-out 0.25s;
   }
    textarea,
    select {
      width: 100%;
      margin: 0;
      padding-bottom: 1.4em;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      border-bottom: 3px solid white;
      background: transparent;
      font-family: 'Roboto', sans-serif;
      color: white;
      font-weight: 700;
      font-size: 18px;
      transition: ease-in-out 0.25s;
    }
    textarea {
      min-height: 50px;
      max-height: 500px;
      white-space: pre-wrap;
      word-wrap: break-word;
      resize: vertical;
      overflow: hidden;
    }
    ::-webkit-input-placeholder {
      font-size: 12px;
      color: slategrey;
      font-weight: 400;
    }
   
    input:focus,
    select:focus,
    textarea:focus
   {
      outline: none;
      resize: none;
    }
    .send:focus{
      outline: none;
      resize: none;
      border-bottom: 3px solid #01a9ac;
    }
    #slider-range-min {
      outline: 0;
      background: slategrey;
      height: 3.6em;
      border: 0;
      border-radius: 0;
    }
      .ui-slider-range {
        background: #01a9ac;
        border-radius: 0;
      }
      .ui-slider-handle {
        outline: 0;
        top: 1.1em;
        margin-left: -.6em;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 0;
        background: white;
        cursor: pointer;
      }
    
    #amount {
      border: 0;
      color: lightgrey;
      font-weight: 400;
      font-size: 28px;
      padding-top: 1.1em;
      letter-spacing: 3px;
    }
    .send {
      color: lightgrey;
      background: rgba(34, 34, 34, 0.0);
      border: 3px solid #fff;
      height: 40px;
      width: 100px;
      float: right;
      font-weight: 700;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: ease-in-out 0.25s;
    }
     .send span {
        transition: ease-in-out 0.25s;
      }
    
    .send:hover {
      color: lightgrey;
    }
     .send span:hover {
        padding-left: 10px;
        color: white;
      }
.uu{
    margin-top: 50px;
}
.contact{
    background:#23344c;
    padding: 20px;
}
.for{
    color: white!important;
}
.cont{
    color: white!important;
}
.emri{
    display: flex;
}
.slider__item{
  text-align: center;
  font-weight: 500;
  background-color: #f1f3f7;
  padding: 8px;
  margin-right: 10px;
}
.kl{
  transform: scale(0.7);
  margin-left: -150px;
}
.kl path:hover{
  fill: #09537a!important;
}
.graph{
  margin-top: -100px;
}
.fac{
  width: 90%;
  height: 200px;
  margin-bottom: 20px;
}
.rekl{
  text-align: center;
}
table .spin{
  position: absolute;
  left: 50%;
  text-align: center;
}
.bg-footer {
  background-color:#1a283d;
  padding: 50px 0 30px;
}
.footer-heading {
  letter-spacing: 2px;
  margin-top: 10px;
}

.footer-link a {
  color: #acacac;
  line-height: 40px;
  font-size: 14px;
  transition: all 0.5s;
}

.footer-link a:hover {
  color: grey;
}

.contact-info {
  color: #acacac;
  font-size: 14px;
}

.footer-social-icon {
  font-size: 15px;
padding: 5px;
  width: 34px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
}

.facebook {
  background-color: #4e71a8;
  color: #ffffff;
}

.twitter {
  background-color: #55acee;
  color: #ffffff;
}

.google {
  background-color: #d6492f;
  color: #ffffff;
}



.footer-alt {
  color: #acacac;
}

.footer-heading {
  position: relative;
  padding-bottom: 12px;
}

.footer-heading:after {
  content: '';
  width: 25px;
  border-bottom: 1px solid #FFF;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #2e4a71;
  }
a:hover{
  text-decoration: none;
}
.seri{
  margin-top: 50px!important;
}
.neHoe{
  font-size: 16px!important;
}
.emri{
  font-weight: 500;
}
.results{
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 0.5rem!important;
}
.reg{
font-weight: 500;
padding:8px;
color:#046396;
background: rgb(242 242 242);
}
.Typewriter__cursor{
  display: none!important;
}
.drop label{
  width: auto!important;
}


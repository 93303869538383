/* Modern Table Styles */
.detaj {
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: none;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1rem;
  border: none;
  overflow: hidden;
}

/* Table Header */
.detaj thead {
  background: linear-gradient(to right, #2e4a71, #366196);
  width: 100%;
}

.detaj thead tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

/* Column Widths */
.detaj th.in-column,
.detaj td.in-column,
.detaj th[scope="col"]:first-child,
.detaj td:first-child {
  width: 60px;
  min-width: 60px;
}

.detaj th[scope="col"]:first-child {
  color: #ffffff;
}

.detaj td:first-child,
.detaj th[scope="row"] {
  color: #1a202c;
  font-weight: 500;
}

.detaj th.remarks-column,
.detaj td.remarks-column {
  width: calc(100% - 60px);
}

/* Table Headers */
.detaj th {
  padding: 12px 16px;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: none;
  white-space: nowrap;
  position: relative;
}

/* Make all icons in table headers white */
.detaj th i.bi {
  color: #ffffff;
}

.detaj th i.deti {
  color: #ffffff;
}

/* Table Body */
.detaj tbody tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.detaj td {
  padding: 12px 16px;
  font-size: 14px;
  line-height: 1.5;
  color: #1a202c;
  border-bottom: 1px solid #e5e7eb;
  transition: all 0.2s ease;
}

/* Row Styles */
.detaj tbody tr {
  transition: all 0.2s ease;
}

.detaj tbody tr:hover {
  background-color: rgba(46, 74, 113, 0.05);
}

.detaj tbody tr:last-child td {
  border-bottom: none;
}

/* Active/Inactive States with Subtle Indicators */
.active-row {
  background-color: #f0fff4;
  border-left: 3px solid #48bb78;
}

.active-row:hover {
  background-color: #e6ffed;
}

.inactive-row {
  background-color: #fff5f5;
  border-left: 3px solid #f56565;
}

.inactive-row:hover {
  background-color: #ffe6e6;
}

/* Status Text Styles */
.stike {
  text-decoration: line-through;
  color: #a0aec0;
  font-style: italic;
}

/* Table Container Card */
.card .table {
  margin-bottom: 0;
}

.card-body {
  padding: 0;
}

/* Empty State Styling */
.detaj tbody tr td[colspan] {
  text-align: center;
  padding: 1.5rem;
  color: #718096;
  font-style: italic;
}

/* Modern Card Styles */
.card {
  background: #ffffff;
  border: none;
  margin-bottom: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.header-card {
  background: linear-gradient(135deg, #2e4a71, #366196);
  color: white;
  padding: 1.5rem;
}

.header-card .card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.header-card .card-title i {
  font-size: 1.8rem;
  margin-right: 0.5rem;
  color: white;
}

.header-card .card-text {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  opacity: 0.9;
}

.header-card .card-text b {
  font-weight: 600;
  margin-right: 0.5rem;
}

.pdf-button-card {
  background: white;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.pdf-button-card .pdf {
  background: #2e4a71;
  color: white;
  border: none;
  padding: 1.2rem 2rem;
  border-radius: 12px;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.pdf-button-card .pdf:hover {
  background: #366196;
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);
}

.pdf-button-card .pdf i {
  font-size: 2rem;
}

.pdf-button-card .pdf::after {
  content: "Export PDF";
  font-size: 1.2rem;
  font-weight: 500;
}

.company-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.company-info p {
  margin: 0;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  backdrop-filter: blur(5px);
}

/* Clean Typography */
.detaj td, .detaj th {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

/* Icon Enhancements */
.deti {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  font-size: 16px;
  color: inherit;
  opacity: 0.8;
}

/* Link Styling */
.pageid {
  color: #366196;
  text-decoration: none;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.pageid:hover {
  background-color: rgba(54, 97, 150, 0.1);
  color: #2e4a71;
}

/* Data Cell Formatting */
.detaj td[data-type="number"] {
  font-family: 'Roboto Mono', monospace;
  text-align: right;
}

.detaj td[data-type="date"] {
  white-space: nowrap;
  color: #4a5568;
}

/* Column Specific Styles */
.detaj td:first-child {
  font-weight: 500;
}

/* Responsive Design */
@media (max-width: 768px) {
  .detaj {
    margin: 0.5rem 0;
  }

  .detaj th,
  .detaj td {
    padding: 10px 12px;
  }

  .detaj th {
    font-size: 12px;
  }

  .detaj td {
    font-size: 13px;
  }
}

/* Print Optimization */
@media print {
  .detaj {
    box-shadow: none;
    border: 1px solid #e2e8f0;
  }

  .detaj th {
    background: #f7fafc !important;
    color: #1a202c !important;
    border-bottom: 2px solid #cbd5e0;
  }

  .active-row,
  .inactive-row {
    background-color: transparent !important;
    border-left-width: 2px;
  }

  .no-printme {
    display: none !important;
  }
}

/* Layout Grid */
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.col-sm-12, .col-sm-9, .col-sm-6, .col-sm-4, .col-sm-3 {
  padding: 0.5rem;
}

.col-sm-12 { width: 100%; }
.col-sm-9 { width: 75%; }
.col-sm-6 { width: 50%; }
.col-sm-4 { width: 33.333333%; }
.col-sm-3 { width: 25%; }

/* Container */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Utility Classes */
.text-right { text-align: right; }
.text-center { text-align: center; }
.font-bold { font-weight: 600; }
.text-sm { font-size: 0.875rem; }
.text-xs { font-size: 0.75rem; }

/* Icon Styles */
.detail-table-icon {
  margin-right: 0.5rem;
  color: #9ca3af;
}

/* Link Styles */
.detail-table-link {
  color: #2563eb;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.2s;
}

.detail-table-link:hover {
  color: #1d4ed8;
}

/* Responsive Styles */
@media (max-width: 640px) {
  .detail-table-container {
    border-radius: 0;
  }
  
  .detail-table-header th,
  .detail-table-body td {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
}

/* Print Styles */
@media print {
  .detail-table-container {
    box-shadow: none;
    break-inside: avoid;
  }
  
  .detail-table-header th {
    background-color: white;
    color: black;
  }
  
  .detail-table-row.active,
  .detail-table-row.inactive {
    background-color: white;
  }
}

/* Table Container Styles */
.detail-table-container {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 1rem;
}

/* Table Header Styles */
.detail-table-header {
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

.detail-table-header th {
  padding: 0.75rem 1rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Table Body Styles */
.detail-table-body td {
  padding: 0.75rem 1rem;
  white-space: nowrap;
  font-size: 0.875rem;
  color: #111827;
}

/* Row Styles */
.detail-table-row {
  border-bottom: 1px solid #e5e7eb;
}

.detail-table-row:hover {
  background-color: #f9fafb;
  transition: background-color 0.2s;
}

/* Active/Inactive Row Styles */
.detail-table-row.active {
  background-color: #ecfdf5;
}

.detail-table-row.inactive {
  background-color: #fef2f2;
}

.card-title{
  text-align: left;
}
.card-body{
  background:transparent!important;;
  border: none!important;
}
.card{
  border: none!important;
  background-color: transparent;
}
p{
  margin-bottom: 0!important;
  font-weight: 500;
}
.table td:hover{
  color: rgb(53, 49, 131);
  cursor: pointer;
}
.detaj{
  background-color: #fff!important;
}
.card-body{
  padding: 0.6em !important; 
}
*:focus {
  outline: 0 !important;
}
.table th, .table td {
  padding: 5px!important;
}
  @media (min-width: 992px) {

.id{
  margin-left: 0;
}
#content-wrapper{
  margin-left: 0;
  margin-top: 20px;
  width: 100%;
}
.table tbody th{
  font-weight: 500!important;
  font-size: 14px!important;
}
.pageid{
  font-weight: 600!important;
}
.uid{
  margin-top: -20px;
  margin-bottom: 20px;
}
.all{
  margin-top: 30px;
}

.table td{
  font-size: 14px;
  font-weight: 500;
}

  }
  @media (max-width: 767px) {
      .col-sm-3{
          margin-left:10px;
      }
     tr{
        font-size: 10px;
     }
     
     .pdf{
         margin-left: -50px!important;
         font-size: 14px!important;
         width: 150px!important;
     }
     .but{
         margin-top: 0px!important;
         padding: 0px!important;
     }
     td{
         height: auto!important;
     }
   
      .all{
        font-size: 12px;
      }
      .nice{
        text-align: center;
      }
      h5{
        font-size: 16px;
      }
      h6{
        font-size: 14px;
      }
      .search-input{
        font-size: 12px;
        width: 50%;
      }
  }
tr{
  border-bottom: 1px solid #dee2e6;
}
.search-box{
  display: flex;
}
.button{
  border:none; background-color: transparent;margin-top: -2px;
}
.card-text{
  margin-right: 30px;
}
.deti{
  color: black;
  margin-right: 0px!important;
  font-size: 20px;
}
.de{
  font-size: 50px;
  opacity: 0.6;
}

.pdf{
  border: none;
  background: transparent;
  width: 200px;
  font-size: 20px;
  margin-right: 0px!important;
  color: red;
}

.but{
  margin-top: 30px;
}

.water{
  background: url('../../images/KSECO.png');
  z-index: 2;
  background-size: 0px;
}

@media print {
  .no-printme  {
    display: none!important;
  }
  .printme  {
    display: block;
  }
  .water{
    background: url('../../images/KSECO.png')!important;
    z-index: 2;
    background-size: contain!important;
    print-color-adjust:exact ;
    -webkit-print-color-adjust: exact;
  }
  .card{
    background-color: transparent!important;
  }
  .card-body{
    background: transparent!important;
  }
  table{
    background: transparent!important;
  }
  body{
    background-color: transparent!important;
    background: transparent!important;
  }
}

.stike{
  text-decoration:line-through;
  color: #767676;
}
.node text{
  transform: translate(30px, 50px);
}

/* Network Graph Container */
.network-container {
  width: 100%;
  height: 80vh; /* 80% of viewport height */
  min-height: 600px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Network Graph Canvas */
.network-container canvas {
  width: 100% !important;
  height: 100% !important;
}

/* Network Controls */
.network-controls {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Network Visualization Styles */
.network-container {
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 20px 0;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.network-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #2e4a71, #4CAF50);
  border-radius: 8px 8px 0 0;
}

.network-legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #424242;
}

.legend-icon {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
}

.legend-person {
  color: #4CAF50;
}

.legend-company {
  color: #2e4a71;
}

/* Node hover effect */
.vis-network:hover {
  cursor: grab;
}

.vis-network:active {
  cursor: grabbing;
}

/* Custom node styling */
.vis-network .vis-node {
  transition: all 0.2s ease;
  z-index: 1;
}

.vis-network .vis-node:hover,
.vis-network .vis-node.vis-selected {
  z-index: 2;
}

.vis-network .vis-node.vis-dragging {
  z-index: 3;
}

/* Edge styling */
.vis-network .vis-edge {
  stroke-width: 2px !important;
  transition: stroke-width 0.2s ease;
}

.vis-network .vis-edge:hover,
.vis-network .vis-edge.vis-selected {
  stroke-width: 4px !important;
}

.vis-network .vis-edge .vis-arrow {
  stroke-width: 2px !important;
  fill: #2e4a71;
}

.vis-network .vis-edge:hover .vis-arrow,
.vis-network .vis-edge.vis-selected .vis-arrow {
  stroke-width: 3px !important;
  fill: #2e4a71;
}

/* Improve canvas rendering */
.vis-network canvas {
  image-rendering: high-quality;
  image-rendering: -webkit-optimize-contrast;
}

/* Print styles */
@media print {
  .no-print {
    display: none !important;
  }
  
  .print-only {
    display: block !important;
  }
  
  body {
    background: white;
  }
  
  .card {
    break-inside: avoid;
  }
}

/* Node label styling */
.vis-network .vis-node .vis-label {
  font-family: 'Roboto', sans-serif;
  text-transform: none;
  background: none;
  text-shadow: 
    -1px -1px 2px #fff,
    1px -1px 2px #fff,
    -1px 1px 2px #fff,
    1px 1px 2px #fff;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.vis-network .vis-node.person-node .vis-label {
  font-size: 10px;
  letter-spacing: 0.2px;
  opacity: 0.95;
  line-height: 1.2;
  padding: 2px 4px;
  margin-top: -4px;
}

.vis-network .vis-node.company-node .vis-label {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.3;
}

/* Show full name on hover */
.vis-network .vis-node:hover .vis-label {
  opacity: 1;
  overflow: visible;
  white-space: normal;
  max-width: none;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

/* Node scaling on hover */
.vis-network .vis-node {
  transition: all 0.2s ease;
  z-index: 1;
}

.vis-network .vis-node:hover {
  transform: scale(1.1);
  z-index: 2;
}

.vis-network .vis-node.vis-selected {
  z-index: 3;
}

/* Details Page Layout and Tables */

.details-container {
  padding: 20px;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.company-details-section {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Ensure navbar takes full width */
nav {
  width: 100%;
  max-width: 100%;
}

.company-details-section {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
}

.company-header {
  margin-bottom: 24px;
}

.company-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-title h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.info-section {
  margin-bottom: 24px;
}

.section-header {
  margin-bottom: 16px;
}

.section-header h2 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.info-table {
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  overflow: hidden;
}

.table {
  width: 100%;
  margin-bottom: 0;
}

.table td.label {
  font-weight: 500;
  width: 200px;
  background-color: #f8fafc;
}

.table td {
  padding: 12px 16px;
  border-bottom: 1px solid #e2e8f0;
}

.table tr:last-child td {
  border-bottom: none;
}

.pdf-preview-section {
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #e2e8f0;
}

.btn-primary {
  background-color: #3b82f6;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-primary:hover {
  background-color: #2563eb;
}

.btn-primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-x: hidden;
}

/* Adding styles for the details wrapper to ensure full width */
.details-wrapper {
  width: 100%;
  min-width: 100%;
}

.details-wrapper > .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

  
  .content {
    padding: 7rem 0; }
  
    .order-md-2{
        background-color: #366196;
    }
  
  /* .half, .half .container > .row {
    height: 100vh;
   } */
   .regis{
    position: relative!important;
    background-color: transparent;
    width: 150px;
    font-size: 20px;
    height: auto;
    top: auto;
    left: auto;
   }
  .radio{
    display: flex;
    border: none;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    background: #fff;
    padding-top: 10px;
    padding-left: 5px;
  }
  .mx-1{
    color: #6d77ad;
    font-size: 20px;
  }
  .kartat{
    display: flex;
  }
  .visa{
    display: flex;
    align-items: center;
  }
  .d-flex .align-items-center label{
width: auto!important;
  }
  .rad{
    border: none;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    background: #fff;
    padding-top: 10px;
    padding-left: 10px;
  }
  .radio label{
    width: 80px!important;
  }
  /* @media (max-width: 991.98px) {
    .half .bg {
      height: 200px; } } */
  
  .half .contents {
    background: #f1f3f7; }
  
  .half .contents {
    width: 80%; }
  
  .half .bg {
    width: 20%; }
  
  @media (max-width: 991.98px) {
    .half .contents, .half .bg {
      width: 100%; }
    .circle{
      width: auto!important;
    } }
  
  .half .contents .form-control, .half .bg .form-control {
    border: none;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 54px;
    background: #fff; }
    .half .contents .form-control:active, .half .contents .form-control:focus, .half .bg .form-control:active, .half .bg .form-control:focus {
      outline: none;
      -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); }
  
  .half .bg {
    background-size: cover;
    background-position: center; }
  
  .half a {
    color: #888;
    text-decoration: underline; }
  
  .half .btn-primary {
    height: 54px;
    padding-left: 30px;
    padding-right: 30px; }
  
  .half .forgot-pass {
    position: relative;
    top: 2px;
    font-size: 14px; }
  
  .control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px; }
    .control .caption {
      position: relative;
      top: .2rem;
      color: #888; }
  
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  
  .control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border-radius: 4px; }
  
  .control--radio .control__indicator {
    border-radius: 50%; }
  
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: #ccc; }
  
  .control input:checked ~ .control__indicator {
    background: #fb771a; }
  
  .control:hover input:not([disabled]):checked ~ .control__indicator,
  .control input:checked:focus ~ .control__indicator {
    background: #fb8633; }
  
  .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.9;
    pointer-events: none; }
  
  .control__indicator:after {
    content: "\F633";
    font-family: "bootstrap-icons";
    position: absolute;
    display: none;
    font-size: 16px;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
  
  .control input:checked ~ .control__indicator:after {
    display: block;
    color: #fff; }
  
  .control--checkbox .control__indicator:after {
    top: 50%;
    left: 50%;
    margin-top: -1px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b; }
  
  .control--checkbox input:disabled:checked ~ .control__indicator {
    background-color: #7e0cf5;
    opacity: .2; }
    #wrapper{
      padding: 60px 0px;
    }

    .circle{
      background: #ffffff;
      padding: 35px;
      text-align: center;
      height: 250px;
      width: 250px;
        
        transition: all 0.5s;
      -moz-transition: all 0.5s; /* Firefox 4 */
      -webkit-transition: all 0.5s; /* Safari and Chrome */
      -o-transition: all 0.5s; /* Opera */
    }
    .circle h4{
      margin: 0;
      padding: 0;
    }
   .choos{
    position: relative;
    height: auto;
    width: auto;
    top: 10px;
    left: auto;
    background-color: transparent;
    color: #366196;
   }
   .fa-eur{
    color: #366196;
   }
    .circle span.icona i{
      font-size: 48px;
    }
    .circle span.price-large{
      font-size: 68px
    }
    .price-small{
      font-size: 24px 
    }
    
    .c1:hover{
      background: #39b3d7;
      color: #ffffff;
    }
    .c1 .blue{
      color: #366196;
    }
    .c1:hover .blue{
      color: #ffffff;
    }
    .c2{
      margin-top: 50px;
    }
    .c3{
      margin-top: 50px;
    }
    .c2:hover{
      background: #39b3d7;
      color: #ffffff;
    }
    .c2 .yellow{
      color: #366196;
    }
    .c2:hover .yellow{
      color: #ffffff;
    }
    
    .c3:hover{
      background: #39b3d7;
      color: #ffffff;
    }
    .c3 .green{
      color: #366196;
    }
    .c3:hover .green{
      color: #ffffff;
    }
    
    .c4:hover{
      background: #39b3d7;
      color: #ffffff;
    }
    .c4 .red{
      color: #39b3d7;
    }
    .c4:hover .red{
      color: #ffffff;
    }

    
/* Custom styles for the CustomMultiSelectCheckbox component */
.customMultiSelectCheckboxWrapper {
    position: relative;
    width: 250px;
    font-family: 'Arial', sans-serif;
}

.dropdownToggle {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.dropdownToggle:hover {
    background-color: #f9f9f9;
}

.dropdownArrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
    transition: transform 0.3s ease;
}

.dropdownArrow.open {
    transform: rotate(180deg);
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    margin-top: 5px;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    margin: 5px 0;
    cursor: pointer;
}

.checkboxInput {
    margin-right: 10px;
    cursor: pointer;
}

.checkboxText {
    font-size: 14px;
    color: #333;
}

.checkboxInput:checked + .checkboxText {
    color: #007bff; /* Highlight text for selected options */
}

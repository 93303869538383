.container {
    margin-top: 20px;
    text-align: center; /* Center align container items */
}

/* Sidebar item styling */
.sidebarItem {
    width: 100%;
    padding: .6em 1em;
    text-decoration: none;
    display: block;
    background-color: #fff;
    border-radius: 4px;
    transition: background-color .3s, box-shadow .3s;
    margin: 10px auto; /* Center horizontally */
}

.sidebarItem:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}

.sidebarItem.open {
    background-color: #e9ecef;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Sidebar title styling */
.sidebarTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    cursor: pointer;
    color: #333; /* Consistent text color */
}

.sidebarTitle i {
    margin-right: 10px;
}

.sidebarTitle span {
    flex: 1;
}

/* Dropdown menu styling */
.dropdownMenu {
    display: none;
    padding-top: 10px;
    overflow: hidden;
}

.dropdownMenu.open {
    display: block;
}

/* Checkbox styles */
.checkbox {
    appearance: none;
    outline: 0;
    background: #fff;
    width: 16px;
    height: 16px;
    border: 2px solid #406fab;
    border-radius: 3px;
    margin-right: 8px;
    vertical-align: middle;
    position: relative;
}

.checkbox:checked::before {
    content: "\F633";
    font-family: "bootstrap-icons";
    font-size: 16px;
    color: #406fab;
    position: absolute;
    top: -2px;
    left: 2px;
}

/* List styling */
.list {
    padding-left: 0;
    margin: 0;
    list-style: none;
    text-align: left; /* Align list items to the left */
}

.listItem {
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
}

.listItem:last-child {
    border-bottom: none;
}

/* Input styles */
.inputSearch {
    min-width: 180px;
    height: 30px;
    border: 1px solid #e4e6ef;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 0 10px;
}